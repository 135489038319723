.useSiteInfoModal {
  &.modal {
    background-color: rgba(#000000, 0.7);
  }
  .modal-dialog {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
  }
  .modal-content {
    background-color: transparent !important;
    border: none;
  }
  img {
    width: 100%;
    padding: 0px 50px;
  }
  .modal-header {
    border: none;
    button {
      position: fixed;
      border: none !important;
      box-shadow: none !important;

      right: 60px;
      &:focus,
      :active {
        outline: none;
        box-shadow: none;
        border: none;
      }
      span {
        font-size: 48px;
        color: #ffffff;
      }
    }
  }
}

.ReservationBaseInfo {
  height: 450px;
  margin-bottom: 40px;

  .header {
    height: 53px;
    margin-bottom: 43px;
    .logo {
      width: auto;
      height: 48px;
      margin-right: 17px;
      vertical-align: top !important;
    }
    #singlexLogo {
      height: 40px;
    }
    .title {
      display: inline-block;
      font-size: 32px;
      line-height: 48px;
      strong {
        font-size: inherit;
      }
    }
    .useSiteInfoButton {
      float: right;
      border-radius: 25.5px;
      background-color: #ffffff !important;
      border: none;
      height: 53px;
      align-content: center;
      align-items: center;
      justify-content: center;
      color: #439099 !important;
      box-shadow: none !important;
      .questionMarkIcon {
        width: 20px;
        height: 20px;
        margin: 0px;
        margin-right: 5px;
      }
    }
    .isVisitor {
      float: right;
      width: 366px;
      border-radius: 25.5px;
      background-color: #ffffff;
      height: 53px;
      align-content: center;
      align-items: center;
      justify-content: center;
      .swapIcon {
        width: 21px;
        height: 21px;
      }
      span {
        padding: 5px;
      }
      .isVisitorButton {
        background-color: transparent !important;
        color: #439099 !important;
        border: none !important;
        font-weight: bold;
        line-height: 24px;
        box-shadow: none !important;
        opacity: 0.5;
        color: #000000 !important;
      }
      .isVisitorButton.active {
        color: #439099 !important;
        opacity: 1;
      }
    }
  }

  .validationMessage {
    position: absolute;
    bottom: -30px;
    left: 15px;
  }
  .card-deck {
    margin-bottom: 30px;
    margin-right: 0px;
    margin-left: 0px;
    align-items: flex-start;
    display: flex !important;
    flex-flow: row wrap;
    .card {
      border-radius: 30px;
      z-index: 10;
      margin-right: 10px;
      margin-left: 10px;
      border: none;
      flex: 1 0 !important;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    }
    .card:first-child {
      margin-left: 0px;
    }
    .card:last-child {
      margin-right: 0px;
    }
  }
  .card-body {
    padding: 30px;
    min-width: 410px;
    min-height: 300px;
    .card-title {
      padding-top: 15px;
      padding-left: 15px;
      margin-bottom: 0px;
      span {
        display: inline-block;

        vertical-align: top;
        line-height: 32px;
        img {
          width: 32px;
          height: 32px;
        }
      }
      p {
        font-size: 28px;
        line-height: 20px;
        white-space: pre-line;
        display: inline-block;
        margin-left: 18px;
        margin-bottom: 0px;
        line-height: 36px;
        strong {
          font-size: inherit;
        }
      }
    }
    .input-group {
      height: 70px;
      border-radius: 15px;
      background-color: #ffffff;
      margin-top: 83px;
      align-items: center;
      input.form-control {
        border: none;
        background-color: transparent;
        font-size: 18px;
        color: #000000;

        &::placeholder {
          color: rgba(#000000, 0.5);
        }
      }
      input.form-control.hide {
        display: none;
        visibility: hidden;
      }
      input.form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none;
      }

      .searchIcon {
        width: 24px;
        height: 24px;
        margin-left: 20px;
      }
      .calendarIcon {
        width: 24px;
        height: 24px;
        margin-left: 20px;
      }
    }
    .VisitPeriodCalendar {
      margin-left: 10px;
    }
    .input-group.disabled {
      background-color: rgba(#ffffff, 0.25);
      margin-top: 83px !important;
      cursor: not-allowed !important;
      input {
        cursor: not-allowed !important;
      }
      .visitToName {
        border-right: 1px solid rgba(#111111, 0.25) !important;
      }
    }

    .visitToName.visible {
      border-right: 1px solid rgba(#111111, 0.25) !important;
    }
    .visitToPhone.visible {
      font-size: 18px;
    }
    .input-group.visitDates,
    .input-group.visitToKeyword,
    .input-group.visitWorkplaceMyWorkplace {
      margin-top: 11px;
    }

    .material-icons.md-32 {
      font-size: 32px !important;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 45px;
      margin-right: 45px;
    }
    .material-icons.md-76 {
      font-size: 76px !important;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 22px;
      margin-right: 24px;
    }
    .dropdown-item {
      margin: 8px 8px 0px 0px;
      padding: 5px 20px 5px 8px;
      color: #ffffff;
      height: auto;
      width: auto;
      width: fit-content;
      border-radius: 24px;
      .material-icons {
        margin-right: 8px;
      }
      img {
        margin-right: 8px;
        width: 24px;
        height: 24px;
        padding: 4px;
      }
      p {
        margin: 0px;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: normal;
        overflow: hidden;
        max-width: 300px;
      }
    }
    .dropDown {
      margin-top: 10px;
    }
    .dropdown-item:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .dropdown-item:focus {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .multiLine.dropdown-item {
      background-color: rgba(0, 0, 0, 0.25);
      margin-top: 0px;
      margin-bottom: 10px;

      .material-icons {
        vertical-align: super;
      }
      p {
        vertical-align: bottom;
      }
    }

    .oneLine.dropdown-item {
      width: 100%;
      margin: 0px;
      margin-bottom: 0px;
      padding: 5px 20px 5px 8px;
      img {
        vertical-align: top;
      }
      .material-icons {
        vertical-align: bottom;
      }
      p {
        vertical-align: top;
      }
    }
    .oneLine.workplace.dropdown-item:hover {
      border-radius: 15px;
    }
    .oneLine.workplace.dropdown-item:focus {
      border-radius: 15px;
    }
    .oneLine.recommendWorkplace.dropdown-item {
      width: auto;
      width: fit-content;
      margin-bottom: 5px;
      margin-right: 5px;
      background-color: rgba(transparent, 0.05);
    }
    .oneLine.recommendWorkplace.dropdown-item:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .oneLine.recommendWorkplace.dropdown-item:focus {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .oneLine.visitTo.dropdown-item:hover {
      border-radius: 4px;
    }
    .oneLine.visitTo.dropdown-item:focus {
      border-radius: 4px;
    }
    .noResult.dropdown-item {
      text-align: center;
      padding: 0px;
      margin: 20px 0px 0px 0px;
      width: 100%;
      img {
        vertical-align: middle;
        padding: 0;
        width: 14px;
        height: 16px;
      }
      p {
        vertical-align: middle;
      }
    }
    .noResult.dropdown-item:hover {
      background-color: transparent;
    }
    .noResult.dropdown-item:focus {
      background-color: transparent;
    }
    .text-right {
      height: 32px;
      color: rgba(#ffffff, 0.4);
      font-size: 16px;
      line-height: 32px;
      text-align: right;
      margin-top: 35px;
      margin-bottom: 16px;
      span {
        border-bottom: 1px solid;
        margin-left: 20px;
        &:hover {
          color: rgba(#ffffff, 1);
        }
      }
    }
    .text-right:hover {
      opacity: 1;
    }
    .text-right.disabled {
      opacity: 0.4;
      cursor: not-allowed !important;
      input {
        cursor: not-allowed !important;
      }
    }
  }

  .card-body.overlay {
    padding-top: 50px;
    padding-left: 50px;
    height: 300px;
    .card-title {
      padding: 0px;
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 0px;
      span {
        vertical-align: baseline;
        font-size: 18px;
        line-height: 20px;
        strong {
          padding-left: 18px;
          font-size: inherit;
        }
      }
    }
    .card-text:not(.overlayText) {
      height: 96px;
      font-size: 16px;
      margin-top: 87px;
      line-height: 32px;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: normal;
      overflow: hidden;
      &.checkedWorkplaceName,
      &.checkedVisitToName {
        width: 77%;
      }
      &:not(.checkedWorkplaceName) {
        margin-top: 120px;
      }
      strong {
        font-size: 24px !important;
      }
    }
    .overlayText {
      height: 32px;
      font-size: 20px;
      line-height: 36px;
      position: absolute;
      bottom: 40px;
      right: 40px;
      visibility: hidden;
    }
  }

  .card-body.overlay:hover {
    .overlayText {
      visibility: visible;
      border-bottom: 1px solid;
    }
  }
}

.input-group .link {
  display: none;
}

.d-inline-block.enter-search-cond2 {
  margin-right: 8px;
}

@media only screen and (max-width: 850px) {
  .ReservationBaseInfo {
    height: auto;
    .header {
      display: none;
    }
    .card-deck {
      .card {
        flex: 1 1 auto !important;
        width: 100%;
        margin: 0;
        box-shadow: none;
        border-radius: 0;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        margin-bottom: -30px;
        overflow: hidden;
        z-index: unset;
        max-height: 110px;
        transition: max-height 0.4s;
        &.on {
          height: auto;
          max-height: 800px;
          overflow: auto;
        }
        .card-body {
          position: relative;
          .text-right {
            position: absolute;
            top: 78px;
            right: 32px;
            height: 28px;
            line-height: 28px;
            margin: 0;
            color: #ffffff;
          }
        }
        .card-body {
          padding: 24px 16px 50px;
          min-width: auto;
          min-height: auto;
          height: auto;
          .card-title {
            padding: 0;
            span {
              width: 25px;
              text-align: center;
              font-size: 20px;
              line-height: 24px;

              img {
                width: 27px;
                height: 27px;
                position: absolute;
                /* top: 0; */
                top: 22px;
                left: 15px;
              }
            }
            p {
              margin-left: 12px;
              font-weight: lighter;
              font-size: 14px;
              white-space: nowrap;
              line-height: 24px;

              strong {
                font-size: 24px;
                font-weight: bold;
              }
            }
            i.material-icons {
              margin: 0;
              font-size: 30px !important;
              top: 22px;
              right: 25px;
            }
          }
          .input-group {
            height: 60px;
            margin-top: 70px;

            .link {
              position: absolute;
              top: -42px;
              right: 0;
              color: #ffffff;
              font-size: 14px;
              height: 28px;
              border-bottom: 1px solid #ffffff;
            }
            input.form-control {
              font-size: 18px;
            }
            .searchIcon {
              width: 16px;
              height: 16px;
              margin-left: 20px;
            }
          }
          .dropDown {
            margin-top: 16px;
            p {
              line-height: 24px;
            }
          }
        }
        #checkedWorkplace,
        #checkedVisitTo,
        #checkedDates {
          i.material-icons.md-76 {
            font-size: 22px !important;
            top: 26px;
            left: 20px;
            right: auto;
            margin: 0;
          }
          .card-title {
            display: none;
          }
          .card-text {
            height: auto;
            margin: 0;
            padding-left: 40px;
            font-size: 16px;

            strong {
              font-size: 20px !important;
              line-height: 20px !important;
              &:nth-child(2) {
                margin-left: 8px;
              }
            }
          }
          .card-text:not(.overlayText) {
            width: calc(100% - 65px);
          }
          .overlayText {
            visibility: visible;
            top: 24px;
            right: 26px;
            height: 25px;
            line-height: 28px;
            padding: 0;
            font-size: 18px;
            border-bottom: 1px solid #ffffff;
          }
        }
        #checkedWorkplace {
          .card-text {
            strong {
              &:first-child + br {
                display: none;
              }
            }
          }
        }

        &:first-child {
          .input-group.visitWorkplace {
            margin-top: 38px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .ReservationBaseInfo {
    .card-deck {
      .card {
        .card-body {
          .input-group {
            .visitToName {
              width: 120px;
              flex: 0 0 auto;
            }
          }
        }
      }
    }
  }
}
