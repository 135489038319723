#KioskContainer {
  height: 100% !important;
  width: 100%;
  min-width: 375px;
  padding-left: 0px;
  display: inherit;
  overflow: hidden;
  .container-fluid {
    margin-left: 0px;
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 100vh;
    padding: 0;
  }
  .row {
    width: inherit;
    margin: 0px
  }
}