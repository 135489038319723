.drawer__none {
  display: flex;
  flex-flow: column;
  height: 300px;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 35px;
  }
  p {
    font-weight: bolder;
    font-size: 16px;
    color: #000000;
  }
  & + .drawer__list {
    display: none;
  }
}
.drawer__list {
  list-style: none;
  padding-inline-start: 0;
  border-top: 1px solid;
  border-top-color: rgba(151, 151, 151, 0.4);
  & > li {
    padding: 25px 28px;
    border-bottom: 1px solid;
    border-bottom-color: rgba(151, 151, 151, 0.4);
    &.drawer__status--ready {
      background-color: #f2fbfd;
    }
    .drawer__list--header {
      display: flex;
      flex-flow: row;
      margin-bottom: 8px;
      z-index: 1;
      span {
        flex: 1 1 auto;
        font-size: 18px;
        color: #333333;
        em {
          font-style: normal;
          font-size: 18px;
          font-weight: bolder;
        }
        & + span {
          flex: 0 0 auto;
          font-size: 16px;
          color: #000000;
        }
      }
    }
    .drawer__list--info,
    .drawer__list--item {
      margin-bottom: 8px;
      font-weight: bolder;
      font-size: 15px;
      color: #000000;
    }
    .drawer__list--button {
      display: flex;
      flex-flow: row;
      margin-top: 16px;
      button {
        display: flex;
        flex-flow: row;
        align-items: center;
        height: 40px;
        border-radius: 4px;
        & + button {
          margin-left: 8px;
        }
      }
      .drawer__button--cancel {
        border-color: #303232;
        background-color: #303232;
        &:hover {
          border-color: #000;
          background-color: #000;
        }
      }
      .drawer__button--confirm {
        border-color: #439099;
        background-color: #439099;
        &:hover {
          border-color: #225a61;
          background-color: #225a61;
        }
        i {
          font-size: 21px;
          & + span {
            margin-left: 8px;
          }
        }
      }
    }
    .drawer__msg--alert {
      margin-top: 16px;
      margin-bottom: 0;
      font-size: 16px;
      color: #ff0000;
    }
    .drawer__msg--default {
      margin-top: 16px;
      margin-bottom: 0;
      font-size: 16px;
      color: #000000;
      opacity: 0.5;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .drawer__chip {
      display: flex;
      flex-flow: row;
    }
    .drawer__chip--confirm,
    .drawer__chip--cancel,
    .drawer__chip--request {
      display: flex;
      flex-flow: row;
      align-items: center;
      border-radius: 4px;
      height: 24px;
      padding: 0 5px;
      border: 1px solid;
      i {
        font-size: 16px;
      }
      span {
        font-style: normal;
        font-weight: bolder;
        font-size: 14px;
      }
    }
    .drawer__chip--request {
      border-color: #439099;
      background-color: #439099;
      color: #ffffff;
      i {
        & + span {
          margin-left: 3px;
        }
      }
    }
    .drawer__chip--confirm {
      border-color: #7f7f7f;
      background-color: #7f7f7f;
      color: #ffffff;
      i {
        & + span {
          margin-left: 3px;
        }
      }
    }
    .drawer__chip--cancel {
      border-color: #a50034;
      background-color: #ffffff;
      color: #a50034;
      i {
        & + span {
          margin-left: 3px;
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .drawer__list {
    & > li {
      padding: 20px 24px;

      .drawer__list--header {
        span {
          font-size: 16px;
          line-height: 21px;
          em {
            font-style: normal;
            font-size: 16px;
            font-weight: bolder;
          }
          & + span {
            flex: 0 0 auto;
            font-size: 14px;
            color: #000000;
          }
        }
      }

      .drawer__list--info {
        font-weight: lighter;
        font-size: 14px;
      }
      .drawer__list--item {
        margin-top: 0px;
      }
      .drawer__chip--confirm,
      .drawer__chip--cancel,
      .drawer__chip--request,
      .drawer__chip--me {
        i {
          font-size: 12px;
        }
        span {
          font-weight: lighter;
          font-size: 12px;
        }
      }
    }
  }
}
#reservationDrawer {
  .error-detail-content {
    white-space: pre-line;
    background-color: rgba(151, 151, 151, 0.4);
    word-break: break-all;
    border-radius: 3px;
    margin-top: 15px;
    padding: 10px;
  }
}
