#LoginFooter {
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 630px;

  .row {
    margin: 0px;
  }

  .footer-links {
    padding: 0px;
    min-width: fit-content;
    height: 28px;
    margin-bottom: 5px;
    display: inline-flex;
    width: 100%;
  }

  .splitter {
    width: 25px;
    padding: 0px;
    text-align: center;
  }

  .bar-splitter {
    padding: 0 12px;
    width: 10px;
    max-width: 10px;
    text-align: center;
    color: #d2d2d2;
  }

  .col-sm-6 {
    padding: 0px;
    font-weight: bold;
    color: #a50034;
  }
  .col-sm-5 {
    padding: 0px;
    max-width: none;
    width: auto;
    flex: none;
    max-width: max-content;
  }
  .col-sm-7 {
    padding: 0px;
  }
  .contact-info {
    display: inline-flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
    width: 400px;
    font-size: 13px;
    color: #b5b5b5;
    b,
    strong {
      font-size: 15px;
    }
    & > div {
      flex: 0 0 auto;
    }
  }

  .cns-logo-image {
    margin-top: 30px;
    width: 110px;
    height: 26px;
  }

  .lgLogo {
    display: none;
  }

  a {
    cursor: pointer;
  }
  .languageCol {
    padding-left: 0px;
  }
  .dropdown {
    width: 262px;
    .dropdown-toggle {
      font-weight: bold;
      border: none;
      color: #000000;
      padding-left: 0;
      margin-bottom: 15px;
      text-align: left;
      background: url('../../../../icons/arrow-select.svg') no-repeat 95% 50%;
      background-size: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: normal;
      overflow: hidden;
      &:focus {
        box-shadow: none;
      }
      &:after {
        border: none;
      }
      &:active {
        color: #000000;
        background-color: transparent;
        border: none;
        box-shadow: none !important;
      }
      &::disabled {
        opacity: 0.5;
      }
    }
    .dropdown-item {
      padding: 7px 14px;
    }
    .dropdown-item:focus,
    .dropdown-item:hover {
      color: #16181b;
      text-decoration: none;
      background-color: rgba(#000000, 0.05);
    }
    & + .form-row {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 850px) {
  #LoginFooter {
    .splitter {
      text-align: center;
      padding: 0px 10px 0px 10px;
    }
    .col-sm-6 {
      width: auto;
    }
    .col-sm-5 {
      width: auto;
      flex: none;
    }
    .footer-links {
      font-size: 5px;
      margin-top: 20px; // To-do 임시 조정.(삭제)
    }
    .contact-info {
      margin-bottom: 20px;
      b,
      strong {
        font-size: 12px;
      }
    }
    .col {
      padding-left: 0;
    }
    button {
      margin-top: 15px;
      margin-bottom: 5px !important;
    }
    .lgLogo {
      display: inline;
    }
  }
}
