#MainSection {
  h1 {
    &.step1 {
      font-size: 36px;
      font-weight: bold;
      padding-left: 35px;
      padding-right: 35px;
    }
    &.step2 {
      font-size: 36px;
      font-weight: bold;
    }
    &.step3 {
      font-size: 36px;
    }
    &.step4 {
      font-size: 36px;
    }
  }
  h2 {
    &.step1 {
      font-size: 36px;
      padding-left: 35px;
      padding-right: 35px;
    }
    &.step2 {
      font-size: 36px;
    }
    &.step3 {
      font-size: 36px;
      font-weight: bold;
    }
    &.step4 {
      font-size: 36px;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 850px) {
  #MainSection {
    padding: 50px 24px 55px;
    & > .container {
      & > .row {
        flex-wrap: wrap;
      }
    }
    h1 {
      &.step1,
      &.step2,
      &.step3,
      &.step4 {
        padding: 0;
        letter-spacing: -1px;
        font-size: 28px;
      }
    }
    h2 {
      &.step1,
      &.step2,
      &.step3,
      &.step4 {
        padding: 0;
        font-size: 28px;
        letter-spacing: -1px;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  #MainSection {
    h2 {
      &.step1,
      &.step2,
      &.step3,
      &.step4 {
        letter-spacing: -2px;
      }
    }
  }
}
