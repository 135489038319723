.ReservationBottomRight {
  padding-left: 50px;
  border-left: 1px solid rgba(#979797, 0.3);
  .title {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .notice {
    margin-bottom: 50px;
  }
  p {
    font-size: 16px;
    line-height: 28px;
    white-space: pre-line;
    strong {
      display: block;
      font-size: 20px;
      width: 350px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  a {
    font-size: 18px;
    color: #439099;
  }
  .info {
    margin-bottom: 30px;
    .workplaceNotice {
      white-space: pre-line;
    }
    .showMoreWorkplaceNoticeMobileButton {
      display: none;
    }
  }
  #next-button {
    display: none;
  }
}

@media only screen and (max-width: 850px) {
  .ReservationBottomRight {
    padding: 0;
    border: none;
    .notice {
      margin-bottom: 20px;
      p {
        font-size: 16px;
        margin-bottom: 8px;
        line-height: 20px;
        strong {
          font-size: 16px;
          line-height: 20px;
          width: auto;
        }
      }
      a {
        font-size: 14px;
      }
    }
    .info {
      .showMoreWorkplaceNoticeMobileButton {
        display: inline-block;
        font-size: 14px;
        color: #000000;
        opacity: 0.4;
        font-weight: lighter;
        float: right;
      }

      .workplaceNotice {
        display: none;
      }
      #showMoreWorkplaceNoticeButton,
      #showMoreSystemNoticeButton {
        display: none;
      }
    }
    .title {
      font-size: 16px;
      margin-bottom: 8px;
    }
    p {
      font-size: 16px;
    }
    #next-button {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 56px;
      padding: 0;
      margin-top: 30px;
      margin-bottom: 50px;
      &:disabled {
        opacity: 0.1;
      }
      span {
        font-size: 18px;
        em {
          font-style: normal;
          font-size: 18px;
        }
      }
      i {
        position: absolute;
        right: 15px;
        font-size: 28px;
      }
    }
  }
}
