#MainInformationForm {
  margin-top: 50px;
  .form-row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .form-label {
    height: 32px;
    color: #000000;
    font-size: 20px;
    line-height: 32px;
  }
  .form-group {
    width: 430px;
  }
  .form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #979797;
    border-radius: 0;
    color: black;
    background: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    box-sizing: border-box;
  }
  .formik-field {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #979797;
    border-radius: 0;
    color: black;
    background: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    box-sizing: border-box;
    width: 430px;
  }
  input {
    height: 60px;
    font-size: 18px;
    line-height: 32px;
  }
  .top-empty-space {
    margin-top: 50px;
  }
  .submit-button {
    height: 49px;
    width: 430px;
  }
  .phone-input-div {
    width: 430px;
  }
  .col-md-8 {
    padding: 0px;
  }
  .no-label-form-control {
    margin-top: -7px;
  }
  .component-display-none {
    display: none;
  }
  .comonent-display {
    display: inline;
  }
  .input-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .checked-area {
    float: right;
    margin-top: -40px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
  }
  .certificationReRequest {
    cursor: pointer;
    height: 32px;
    opacity: 0.5;
    color: black;
    font-size: 16px;
    line-height: 32px;
    text-decoration: underline;
  }

  .btn.disabled,
  .btn:disabled {
    opacity: 0.1;
  }
}

@media only screen and (max-width: 850px) {
  #MainInformationForm {
    width: 100%;
    margin-top: 40px;
    .form-row {
      & > .form-group {
        width: 100%;
        margin: 0;
      }
    }
    .form-label {
      margin-bottom: 4px;
      font-size: 16px;
    }
    input {
      height: 46px !important;
      font-size: 14px;
    }
    .form-control {
      & + .form-control {
        margin-top: 10px;
      }
    }
    .formik-field {
      width: 100%;
    }
    .invalid-feedback {
      margin-top: 0.25rem;
      font-size: 80%;
    }
    .submit-button {
      width: 100%;
      height: 64px;
      border-radius: 4px;
      font-size: 18px;
    }
    .no-label-form-control {
      margin-top: -6px;
    }
  }
}
