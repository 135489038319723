.delete-member-account-modal {
  .modal-dialog {
    max-width: 553px;
  }
  .modal-content {
    border-radius: 0;
    border: none;
  }
  .modal-header {
    padding: 40px 64px;
    border: 1px solid rgba(151, 151, 151, 0.2);
    background: rgba(216, 216, 216, 0.2);
    .modal-title {
      .container {
        padding: 0;
      }
      .header {
        &.bold {
          line-height: 36px;
          color: #000000;
          font-weight: bolder;
          font-size: 24px;
        }
        &.light {
          margin-top: 4px;
          line-height: 28px;
          color: #000000;
          font-weight: normal;
          font-size: 16px;
          opacity: 0.5;
        }
      }
    }
    .close {
      position: absolute;
      top: 27px;
      right: 27px;
      height: 17px;
      line-height: 17px;
      padding: 0;
      margin: 0;
      text-shadow: none;
      outline: none;
      span {
        font-size: 28px;
      }
    }
  }
  .modal-body {
    padding: 50px 64px;
    .form-row {
      margin: 0;
      & + .form-row {
        margin-top: 50px;
      }
    }
    .form-group {
      width: 100%;
      margin: 0;
    }
    .form-label {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 32px;
      color: #000000;
    }
    .form-control {
      height: 40px;
      padding: 0;
      padding-bottom: 8px;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #979797;
      font-size: 18px;
      line-height: 32px;
      &:focus {
        box-shadow: none;
      }
      &.is-invalid {
        border-color: #ff0000;
        background: none;
      }
    }
    .invalid-feedback {
      font-size: 16px;
      line-height: 24px;
      color: #ff0000;
    }

    .bottom-empty-space {
      margin-bottom: 50px;
    }
  }

  p {
    font-size: 16px;
    color: red;
  }

  .submit-button {
    width: 100px;
    height: 50px;
    span {
      font-weight: bolder;
      font-size: 20px;
      line-height: 28px;
    }
    &.disabled,
    &:disabled {
      opacity: 0.1;
      cursor: default;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #a50034;
    border-color: #a50034 !important;
    background-color: #a50034;
    padding: 0px;
  }
}

@media only screen and (max-width: 850px) {
  .modal.delete-member-account-modal {
    .modal-content {
      width: 100vw;
      min-height: 100vh;
    }
    .modal-dialog {
      max-width: none;
      margin: 0;
      .modal-header {
        padding: 36px 24px;
        .modal-title {
          .header.bold {
            font-size: 20px;
          }
          .header.light {
            font-size: 16px;
          }
        }
      }
      .modal-body {
        * {
          font-size: 14px;
        }
        padding: 34px 24px;
        #deleteMemberAccountButton {
          width: 100%;
          height: 56px;
          font-size: 18px;
        }
        .form-label {
          font-size: 16px;
        }
      }
    }
  }
}
