#PhoneCertification {
  width: 100%;
  margin-top: 50px;
  .form-row {
    margin-left: 0px;
  }
  .form-label {
    height: 32px;
    color: #000000;
    font-size: 20px;
    line-height: 32px;
  }
  .form-group {
    // width: 430px;
    width: 100%;
  }
  .form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #979797;
    border-radius: 0;
    color: black;
    background: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    height: inherit;

    .PhoneInputInput {
      border: none;
      outline: none;
    }
  }
  ::-webkit-input-placeholder {
    /* Edge */
    font-weight: normal;
    color: #7b7b7b;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: normal;
    color: #7b7b7b;
  }
  ::placeholder {
    font-weight: normal;
    color: #7b7b7b;
  }
  .formik-field {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #979797;
    border-radius: 0;
    color: black;
    background: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    box-sizing: border-box;
    width: 430px;
  }
  input {
    height: inherit;
    font-size: 18px;
    line-height: 32px;
  }

  .submit-button {
    height: 49px;
    width: 430px;
  }

  .certification-timer {
    height: 32px;
    width: 30px;
    opacity: 0.5;
    color: black;
    font-size: 16px;
    line-height: 32px;
    position: relative;
    top: 3px;
    right: 6px;
  }
  .certification-area {
    float: right;
    margin-top: -48px;
  }
  .col-md-8 {
    padding: 0px;
  }
  .no-label-form-control {
    margin-top: -7px;
  }
  .component-display-none {
    display: none;
  }
  .comonent-display {
    display: inline;
  }
  .input-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .checked-area {
    float: right;
    margin-top: -40px;
  }
  .certification-area > i {
    vertical-align: middle;
    margin-top: 5px;
  }
  i {
    margin-right: 5px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
  }
  .certificationReRequest {
    cursor: pointer;
    height: 32px;
    opacity: 0.5;
    color: black;
    font-size: 16px;
    line-height: 32px;
    text-decoration: underline;
  }

  .btn.disabled,
  .btn:disabled {
    opacity: 0.1;
  }

  .btn {
    position: relative;
  }

  #reRequestButtonArea {
    width: 100%;
  }

  #certificationReRequestButton {
    top: -20px;
    float: right;
    width: 150px;
    padding: 0;
    justify-content: center;
    margin-top: 10px;
    height: 60px;
    line-height: 60px;
    margin-right: 0px;
  }

  .keyboard:not(.QrConfirm) {
    width: 100%;
    .hg-row {
      margin-bottom: -12px;
    }
    .hg-button {
      height: 100px;
      span {
        font-size: 25px;
        margin: 0px;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  #PhoneCertification {
    .btn > span {
      font-size: 14px;
    }
    .form-label {
      font-size: 16px !important;
    }
    .certification-timer {
      top: 2px;
      font-size: 14px;
    }
    #certificationCheckButton {
      margin-top: 0px;
    }
    #certificationKeyRow {
      margin-top: 0px;
      .component-display {
        margin-top: 30px !important;
      }
      .component-display-none {
        margin-top: 0px !important;
      }
    }
    .certification-area {
      margin-top: -40px !important;
    }
    .certification-area > i {
      font-size: 24px !important;
    }
    input {
      font-size: 14px;
    }
    .certificationReRequest {
      font-size: 14px !important;
    }
  }
}

