#TermsAcception {
  padding-right: 35px;
  padding-left: 35px;
  width: 100%;

  form {
    padding: 0px;
    margin-top: 52px;
    width: 100%;

    .custom-control-input:checked ~ .custom-control-label::before {
      color: white;
      border-color: #439099 !important;
      background-color: #439099;
      padding: 0px;
    }
    .custom-control-input:active ~ .custom-control-label::before {
      color: white;
      border-color: #439099 !important;
      background-color: #439099;
      padding: 0px;
    }
    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(#439099, 0.25);
    }
    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #439099 !important;
    }

    .custom-control-input:valid ~ .custom-control-label::before {
      border-color: silver;
    }

    .custom-control-input:valid ~ .custom-control-label {
      color: black;
    }

    label {
      font-size: 18px;
    }

    .term-desc {
      height: 19px;
      color: black;
      font-size: 16px;
      line-height: 19px;
      opacity: 0.6;
    }

    .left-empty-space {
      padding-left: 25px;
    }

    .bold {
      font-weight: bold;
    }

    .agree-button {
      height: 49px;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 30px;
    }

    .row {
      margin-left: 0px;
      margin-right: 0px;

      margin: 20px 0px;
    }

    .top-empty-space {
      margin-top: 0px;
    }

    .bottom-empty-space {
      margin-bottom: 0px;
    }

    .col-sm-1 {
      padding: 0px;
    }

    .col-sm-11 {
      padding: 0px;
    }

    .term-detail-icon {
      cursor: pointer;
      font-size: 25.33px;
      color: #979797;
    }

    .line-empty-space {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
    }

    .seperation-line {
      background-color: #979797;
      margin-top: 50px;
      height: 1px;
      margin-bottom: 20px;
    }

    .btn.disabled,
    .btn:disabled {
      opacity: 0.1;
    }

    .agree-btn-row {
      margin-top: 50px;
    }

    .nse-agree-btn-row {
      margin-top: 160px;
      margin-bottom: 130px;
    }
  }
  #noticeBox {
    margin-top: 40px;
    background-color: #f2f2f2;
    height: 135px;
    border-radius: 5px;
    padding: 20px;
    .notice-header-label {
      color: #439099;
      font-weight: bold;
      width: 100%;
    }
    .notice-content-label {
      width: 100%;
      font-size: 14px;
      line-height: 21px;
    }
    .join-redirect-button {
      width: 90px;
      font-weight: bold;
      float: right;
    }
    .row {
      margin: 0px;
    }
    .col-2 {
      margin-top: 23px;
      padding-right: 5px;
    }
    .col-10 {
      padding: 0px;
    }
  }
}

@media (max-width: 850px) {
  #TermsAcception {
    padding: 0;
    form {
      margin-top: 38px;
      .row {
        height: auto;
      }
      & > div {
        & + div {
          margin-top: 12px;
        }
        & > .row {
          display: flex;
          flex-flow: row;
          flex-wrap: nowrap;
          height: auto;
          min-height: 40px;
          & > .col-sm-11 {
            flex: 1 1 auto;
            width: auto;
            .custom-control-label {
              padding-left: 15px;
              font-size: 16px;
              &:before,
              &::before,
              &:after,
              &::after {
                top: 0.22rem;
                width: 1.3rem;
                height: 1.3rem;
              }
            }
          }
          & > .col-sm-1 {
            flex: 0 0 auto;
            width: 25px;
            height: 25px;
            .term-detail-icon {
              width: 25px;
              height: 25px;
              margin-left: 6px;
            }
          }
          &.seperation-line {
            height: 1px;
            min-height: auto;
            margin-top: 20px;
            opacity: 0.3;
          }

          .term-desc {
            font-size: 14px;
            padding-top: -15px;
          }
        }
      }
      .left-empty-space {
        padding-left: 38px;
      }
      .agree-button {
        height: 64px;
        border-radius: 4px;
        font-size: 18px;
      }
      .agree-btn-row {
        margin-bottom: -20px;
      }
      .nse-agree-btn-row {
        margin-top: 160px;
        margin-bottom: -20px;
      }
    }
    #noticeBox {
      margin-top: 30px;
      .col-2 {
        margin-left: 0px;
        margin-top: 28px;
      }
    }
  }
}
