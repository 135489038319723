#ExternalMemberInfoForm {
  margin: 0px;
  width: 435px;
  padding-top: 60px;
  .form-row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .col {
    padding: 0px;
  }
  .form-group {
    width: 100%;
    margin: 0px;
  }
  .form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #979797;
    border-radius: 0;
    color: black;
    background: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    padding-bottom: 8px;
    box-sizing: border-box;
    margin-top: 24px;
    font-size: 20px;
    height: 40px;

    :placeholder {
      color: #979797;
    }
  }
  .top-empty-space {
    margin-top: 50px;
  }
  .phone-certificate-form {
    margin-top: -40px;
    margin-bottom: -5px;
  }
  .toggle-button {
    margin: 0px;
    padding: 0px;
    color: #439099 !important;
    text-decoration: underline;
  }
  .verical-align-bottom {
    position: relative;
    bottom: -35px;
  }
  .submit-button {
    font-size: 16px;
    font-weight: bold;
  }
  .btn.disabled,
  .btn:disabled {
    opacity: 0.1;
  }

  .seperation-line {
    background-color: black;
    opacity: 0.1;
    margin-top: 40px;
    height: 1px;
    margin-bottom: 40px;
  }

  .info-item-title {
    font-size: 16px;
  }
  .info-item-desc {
    font-size: 20px;
    margin-top: 10px;
  }

  .col-sm-10 {
    padding: 0px;
  }

  .col-sm-2 {
    padding: 0px;
    text-align: right;
  }

  .col-sm-7 {
    padding: 0px;
    padding-right: 5px;
  }
  .col-sm-5 {
    padding: 0px;
  }
  .card {
    border: none;
  }

  .card-header {
    background-color: white;
    border-bottom: none;
    padding: 0px;
  }

  .card-body {
    .element {
      all: revert;
    }
    padding: 0px;
    margin: 0px;
  }

  .card-body:collapsing {
    .element {
      all: revert;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: white;
    border-color: #439099 !important;
    background-color: #439099 !important;
    padding: 0px;
  }
  .custom-control-input:active ~ .custom-control-label::before {
    color: white;
    border-color: #439099 !important;
    background-color: #439099 !important;
    padding: 0px;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(#439099, 0.25);
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #439099 !important;
  }

  .custom-control-input:valid ~ .custom-control-label::before {
    border-color: silver;
  }

  .custom-control-input:valid ~ .custom-control-label {
    color: black;
  }

  .col-sm-1 {
    padding: 0px;
    text-align: right;
  }

  .col-sm-11 {
    padding: 0px;
  }
  .term-detail-icon {
    cursor: pointer;
    font-size: 25.33px;
    color: #979797;
  }

  .term-third-title {
    font-size: 18px;
    margin-top: 30px;
    margin-left: 13px;
  }
  .term-third-change-date {
    margin-top: -5px;
    margin-left: 37px;
    width: auto;
    font-size: 16px;
    opacity: 0.6;
  }
  .term-third-desc {
    margin-left: 37px;
    margin-top: 15px;
    width: auto;
    font-size: 16px;
    opacity: 0.6;
  }
  .certification-area {
    height: 40px;
    margin-top: -43px;
  }

  .toast {
    max-width: 580px;
  }
  #changeSuccessInformToast {
    position: fixed;
    bottom: 50px;
    z-index: 999;
    margin: 10px;
    border-radius: 4px;

    text-align: center;

    background-color: #000000;
    color: white;

    .toast-body {
      font-size: 20px;
      padding: 0px;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-left: 60px;
      margin-right: 60px;
      line-height: 30px;
    }

    .material-icons {
      vertical-align: middle;
    }
    .toast-contents {
      padding-left: 60px;
      padding-right: 60px;
    }
    .toast-message {
      margin-left: 12px;

      white-space: nowrap;
    }
  }

  #changeFailInformToast {
    position: fixed;
    bottom: 50px;

    z-index: 999;
    margin: 10px;
    border-radius: 4px;

    text-align: center;

    background-color: #000000;
    color: red;

    .toast-body {
      font-size: 20px;
      padding: 0px;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-left: 60px;
      margin-right: 60px;
      line-height: 30px;
    }

    .material-icons {
      vertical-align: middle;
    }
    .toast-contents {
      padding-left: 60px;
      padding-right: 60px;
    }
    .toast-message {
      margin-left: 12px;

      white-space: nowrap;
    }
  }
  .password-toast {
    left: calc(50% - 192px);
  }
  .phone-toast {
    left: calc(50% - 206px);
  }
  .name-toast {
    left: calc(50% - 178px);
  }
  .company-toast {
    left: calc(50% - 192px);
  }
  .notebook-toast {
    left: calc(50% - 239px);
  }
  .term-toast {
    left: calc(50% - 194px);
  }
  .agree-toast {
    left: calc(50% - 260px);
  }
  .disagree-toast {
    left: calc(50% - 285px);
  }
  .resign-button {
    width: 100%;
    text-align: left;
    color: black;
    opacity: 0.6;
    padding: 0px;
    margin: 0px;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    font-weight: normal;
    color: #7b7b7b;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: normal;
    color: #7b7b7b;
  }
}

@media only screen and (max-width: 850px) {
  #ExternalMemberInfoForm {
    width: 100%;
    padding-bottom: 45px;
    padding-top: 50px;
    * {
      font-size: 14px;
    }
    .form-row {
      & > .form-group {
        width: 100%;
        margin: 0;
      }
    }
    .form-label {
      margin-bottom: 4px;
      font-size: 16px;
    }
    .form-control {
      height: 40px;
      margin-top: 12px;
    }
    .form-control {
      font-size: 16px;
      & + .form-control {
        margin-top: 10px;
      }
    }
    .formik-field {
      width: 100%;
    }
    .invalid-feedback {
      margin-top: 0.25rem;
      font-size: 80%;
    }
    .submit-button {
      width: 100%;
      height: 64px;
      border-radius: 4px;
      font-size: 18px;
    }
    .card-header {
      .row {
        .col-10 {
          font-size: 16px;
        }
      }
    }
    .toast {
      width: calc(100% - 48px);
      max-width: none;
      left: 0px;
    }

    #changeFailInformToast {
      margin-left: 24px;
      .toast-body {
        margin-left: 0px;
        margin-right: 0px;
        .material-icons {
          font-size: 18px;
        }
      }
      .toast-message {
        margin-left: 8px;
        white-space: normal;
      }
    }

    #changeSuccessInformToast {
      margin-left: 24px;
      .toast-body {
        margin-left: 0px;
        margin-right: 0px;
        .material-icons {
          font-size: 18px;
        }
      }
      .toast-message {
        margin-left: 8px;
        font-size: 14px;
        white-space: normal;
      }
    }
    .toggle-button {
      font-size: 14px;
    }
    .info-item-desc {
      font-size: 20px;
      margin-top: 13px;
    }
    .info-item-title {
      font-weight: bold;
    }
    .term-third-change-date {
      font-size: 14px;
    }
    .term-third-desc {
      font-size: 14px;
    }
    .custom-control-label {
      line-height: 1.7;
    }
  }
  #agreeChangeModal {
    width: calc(100% - 48px) !important;
    margin: 0;
    margin-left: 24px;
    .modal-content {
      max-height: none;
    }
    #willYouDisagreeComment {
      font-size: 14px;
    }
    #willYouAgreeComment {
      font-size: 14px;
    }
    .term-agree-change-content {
      padding-bottom: 30px !important;
    }
  }
  .modal-dialog {
    margin: 0;
  }
}

@media only screen and (max-width: 850px) {
  #ExternalMemberInfoForm {
    width: 100%;
    padding-bottom: 45px;
    padding-top: 50px;
    * {
      font-size: 16px;
    }
    .form-row {
      & > .form-group {
        width: 100%;
        margin: 0;
      }
    }
    .form-label {
      margin-bottom: 4px;
      font-size: 16px;
    }
    .form-control {
      height: 40px;
      margin-top: 12px;
    }
    .form-control {
      font-size: 16px;
      & + .form-control {
        margin-top: 10px;
      }
    }
    .formik-field {
      width: 100%;
    }
    .invalid-feedback {
      margin-top: 0.25rem;
      font-size: 80%;
    }
    .submit-button {
      width: 100%;
      height: 64px;
      border-radius: 4px;
      font-size: 18px;
    }
    .card-header {
      .row {
        .col-10 {
          font-size: 16px;
        }
      }
    }
    .toast {
      width: calc(100% - 48px);
      max-width: none;
      left: 0px;
    }

    #changeFailInformToast {
      margin-left: 24px;
      .toast-body {
        margin-left: 0px;
        margin-right: 0px;
        .material-icons {
          font-size: 18px;
        }
      }
      .toast-message {
        margin-left: 8px;
        white-space: normal;
      }
    }

    #changeSuccessInformToast {
      margin-left: 24px;
      .toast-body {
        margin-left: 0px;
        margin-right: 0px;
        .material-icons {
          font-size: 18px;
        }
      }
      .toast-message {
        margin-left: 8px;
        font-size: 14px;
        white-space: normal;
      }
    }
    .toggle-button {
      font-size: 14px;
    }
    .info-item-desc {
      font-size: 20px;
      margin-top: 13px;
    }
    .info-item-title {
      font-weight: bold;
    }
    .term-third-change-date {
      font-size: 14px;
    }
    .term-third-desc {
      font-size: 14px;
    }
    .custom-control-label {
      line-height: 1.7;
    }
  }
  #agreeChangeModal {
    width: calc(100% - 48px) !important;
    margin: 0;
    margin-left: 24px;
    .modal-content {
      max-height: none;
    }
    #willYouDisagreeComment {
      font-size: 14px;
    }
    #willYouAgreeComment {
      font-size: 14px;
    }
    .term-agree-change-content {
      padding-bottom: 30px !important;
    }
  }
  .modal-dialog {
    margin: 0;
  }
}
