.excel-upload-modal {
  .modal-dialog {
    max-width: 560px;
    .bold {
      font-weight: 900;
    }
    .divUpload {
      height: 100%;
      overflow-y: auto;
      padding: 40px 64px 0 64px;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: none;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #d8d8d8;
      }
      .title {
        margin-bottom: 20px;
        span {
          font-weight: bold;
          font-size: 16px;
          line-height: 1.31;
        }
      }
      input[type='file'] {
        display: none;
      }
    }
    .modal-content {
      width: 560px;
      border-radius: 0;
      border: none;
      .modal-header {
        display: block;
        position: relative;
        padding: 40px 64px;
        font-size: 30px;
        border: 1px solid rgba(151, 151, 151, 0.2);
        border-radius: 0;
        background: rgba(216, 216, 216, 0.2);
        .title {
          line-height: 36px;
          font-weight: bolder;
          font-size: 24px;
          color: #000000;
          text-align: left;
        }
        button.download {
          margin-top: 20px;
          .material-icons {
            font-size: 20px;
            width: 24px;
            height: 24px;
            vertical-align: middle;
            margin-right: 5px;
            margin-top: 2px;
          }
        }
        button.close {
          position: absolute;
          top: 27px;
          right: 27px;
          height: 18px;
          line-height: 18px;
          opacity: 1;
          margin: 0;
          padding: 0;
          span {
            font-size: 28px;
            color: #000000;
          }
        }
        .modal__header--info {
          margin-top: 6px;
          line-height: 28px;
          font-size: 18px;
          color: #000;
          opacity: 0.5;
        }
      }
      .modal-body {
        height: 180px;
        padding: 0 2px;
        .container {
          padding: 0;
          height: 100%;
          .divUpload {

            .file-label {
              width: 400px;
              border-bottom: 1px solid #979797;
              cursor: pointer;
              .button-label {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.12);
                margin-bottom: 10px;
                padding: 2px 5px 2px 5px;
                width: 120px;
                opacity: 0.7;
                cursor: pointer;
                .material-icons {
                  font-size: 20px;
                  width: 24px;
                  height: 24px;
                  vertical-align: middle;
                  margin: 6px 5px 5px 5px;
                  opacity: 0.5;
                }
                span {
                  position: absolute;
                  margin-top: 5px;
                  margin-left: 5px;
                  font-weight: bold;
                  opacity: 0.8;
                }
              }
              p {                 
                display: inline-block;
                margin-block-start: 0;
                margin-block-end: 0;
              }
              .fileName {
                margin-left: 18px;
                position: absolute;
                margin-top: 5px;
                font-weight: bold;
                font-size: 20px;
                opacity: 1;
              }
              .placeholder {
                font-weight: normal;
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
    .modal-footer {
      justify-content: flex-start !important;
      button {
        margin-left: 55px;
        font-weight: bold;
        height: 50px;
        width: 82px;
        font-size: 18px;
        &:disabled {
          opacity: 0.1;
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .excel-upload-modal {
    .modal-dialog {
      max-width: none;
      width: 100%;
      height: 100%;
      margin: 0;
      .modal-content {
        width: 100%;
        height: 100%;
        .modal-header {
          flex: 0 0 auto;
          padding: 40px 30px 20px;
          .title {
            font-size: 20px;
          }
          .modal__header--info {
            font-size: 16px;
          }
        }
        .modal-body {
          .container {
            .terms-content {
              padding: 30px;
            }
          }
        }
      }
    }
  }
}
