.system-notice-modal {
  .card:not(:hover) {
    .button-for-admin {
      visibility: hidden;
    }
  }
  .card:hover {
    .button-for-admin {
      i {
        &.edit {
          position: relative;
          top: 6px;
          left: 17px;
        }
        &.star {
          position: relative;
          top: 6px;
          left: 21px;
        }
        &.eye {
          position: relative;
          top: 6px;
          left: 25px;
        }
        &.pin {
          position: relative;
          top: 6px;
          left: 29px;
        }
        &.filled {
          color: #439099;
        }
        &.gray {
          color: #5f5f5f;
        }
      }
    }
  }

  .modal-dialog {
    min-width: 760px;
    .col-sm-1 {
      padding: 0px;
      text-align: right;
    }
    .col.list {
      padding: 0px;
      i {
        &.arrow {
          float: right;
        }
      }
    }
    .col-md-auto {
      padding: 0px;
      padding-right: 5px;
      font-size: 18px;
      width: auto;
    }
    .col {
      padding: 0px;
    }

    a {
      color: black;
    }
    .no-notice {
      padding: 50px;
      text-align: center;
    }
    .modal-content {
      border-radius: 0px;
      border: none;
    }

    .modal-header {
      height: 110px;

      background-color: rgba(216, 216, 216, 0.2);
      border: none;
      .title {
        font-weight: bold;
        font-size: 24px;
        padding-top: 25px;
        padding-left: 30px;
      }
      .close {
        padding: 0px;
        margin: 0px;

        span {
          font-size: 28px;
        }
      }
      button {
        position: absolute;
        top: 27px;
        right: 27px;
        height: 18px;
        line-height: 18px;
        opacity: 1;
        margin: 0;
        padding: 0;
        span {
          font-size: 28px;
          color: #000000;
        }
      }
    }
    .modal-body {
      padding: 50px;
      padding-top: 0px;
      padding-bottom: 40px;
      .new-item-button {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .card {
        border: none;
        border-radius: 0px;
        .card-header {
          cursor: pointer;
          background-color: white;
          font-weight: bolder;
          padding-top: 15px;
          padding-bottom: 15px;
          border: none;
          .importance {
            color: #439099;
          }
          .registerDate {
            font-size: 14px;
            color: #000;
            opacity: 0.5;
            margin-left: 8px;
          }
        }
        .card-body {
          background-color: rgba(196, 196, 196, 0.2);

          .row {
            margin: 0px;
          }
        }
      }
    }
    .line {
      height: 1px;
      background-color: rgb(196, 196, 196);
    }
    .btn-arrow {
      width: 29px;
      height: 29px;
      margin-top: 10px;
      margin-right: 5px;
      padding: 0px;
      background-color: white;
      border-color: rgba(0, 0, 0, 0.5);
      color: rgba(0, 0, 0, 0.5);
      .material-icons {
        font-size: 28px;
      }
    }
    .btn-ok {
      font-weight: bold;
      width: 70px;
      height: 40px;
      margin-top: 25px;
    }
    .btn-create {
      margin-left: 10px;
      font-weight: bold;
      width: 90px;
      height: 40px;
      margin-top: 25px;
    }
  }
  .notice-input-control {
    width: 90%;
    height: 32px;
    padding: 0px 5px 0px 5px;
    // margin: 0px !important;
    
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid;
    :focus {
      box-shadow: none;
      border-color: none;
    }
  }
  .notice-input-label {
    height: 32px;
    max-width: 50px;
  }
  .notice-filter-area {
    padding: 15px 10px 15px 10px;
    .row {
      display: flex;
      justify-content: space-between;
    }
    .col {
      display: flex;
      justify-content: center;
    }
  }
  .select-search {
    button {
      max-width: 90px;
      height: 32px;
      text-align: center;
    }
    .dropdown-menu {
      width: 65px;
    }
  }
  .notice-search-button {
    height: 32px;
    background-color: white;
    border-color: #439099;
    color: #439099;
    font-weight: bold;
    line-height: 20px;
    i {
      vertical-align: middle;
      margin-top: -2px;
    }
    span {
      vertical-align: middle;
    }
  }
}
@media only screen and (max-width: 850px) {
  .system-notice-modal {
    .modal-dialog {
      width: 100%;
      margin: 0px;
      min-width: 100% !important;
      .modal-content {
        height: 100%;
        border: none;
        border-radius: 0;
        height: 100%;
        min-height: 100vh;
        .modal-header {
          border-bottom: 0px;
          height: auto;
          padding: 80px 24px 24px 24px;
          .title {
            font-size: 20px !important;
            line-height: 24px !important;
            padding-left: 0px;
          }
        }
        .modal-body {
          padding: 35px 24px 20px !important;
          width: 100%;
          min-height: auto;
          div {
            font-size: 14px;
            line-height: 28px;
            .registerDate {
              display: none;
            }
          }
        }
        .modal-footer {
          height: auto;
          padding: 16px 24px;
          button {
            padding: 0px;

            width: 100%;
            height: 56px;
            font-size: 18px;
          }
        }
      }
    }
    .btn-ok {
      width: 100% !important;
      height: 56px !important;
      font-size: 18px;
    }
  }
}
