// Override default variables before the import

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$primary: #a50034 !default;
$secondary: #439099 !default;
$dark: #333333 !default;

@font-face {
  font-family: 'LGSmHa';
  font-weight: normal;
  src: local('Awesome Font LG'), url('/fonts/LGSmHaL.eot') format('eot'), url('/fonts/LGSmHaL.woff') format('woff'),
    url('/fonts/LGSmHaL.ttf') format('ttf');
}

@font-face {
  font-family: 'LGSmHa';
  font-weight: bold;
  src: local('Awesome Font LG'), url('/fonts/LGSmHaSB.eot') format('eot'), url('/fonts/LGSmHaSB.woff') format('woff'),
    url('/fonts/LGSmHaSB.ttf') format('ttf');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url('/fonts/MaterialIcons-Regular.woff2') format('woff2'), url('/fonts/MaterialIcons-Regular.woff') format('woff'),
    url('/fonts/MaterialIcons-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	src: url("/font/NotoSans/NotoSansKR-Regular.woff2") format('woff2'),
		 url("/font/NotoSans/NotoSansKR-Regular.woff")  format('woff'),
     url("/font/NotoSans/NotoSansKR-Regular.otf")  format('opentype'); 
}
@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	src: url("/font/NotoSans/NotoSansKR-Medium.woff2") format('woff2'),
		 url("/font/NotoSans/NotoSansKR-Medium.woff")  format('woff'),
     url("/font/NotoSans/NotoSansKR-Medium.otf")  format('opentype'); 
}
@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 700;
	src: url("/font/NotoSans/NotoSansKR-Bold.woff2") format('woff2'),
		 url("/font/NotoSans/NotoSansKR-Bold.woff")  format('woff'),
     url("/font/NotoSans/NotoSansKR-Bold.otf")  format('opentype'); 
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
