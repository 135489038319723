.ReservationContainer {
  margin-top: 184px;
}

@media only screen and (max-width: 850px) {
  .ReservationContainer {
    &.container {
      .col {
        padding: 0;
      }
    }
  }
}