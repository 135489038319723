.ReservationBottomContainer {
  margin-top: 40px;
  margin-bottom: 130px;
  .col-md-8 {
    width: 860px;
    max-width: 860px;
  }
}

@media only screen and (max-width: 850px) {
  .ReservationBottomContainer {
    background: #fff;
    padding: 28px 32px 30px;
    border-radius: 30px 30px 0 0;
    margin: 0;
    margin-top: -40px;
    .covisitor {
      position: fixed;
      display: flex;
      top: 0;
      bottom: 0;
      left: 100%;
      height: 100vh;
      margin: 0;
      padding: 0;
      background: #f5f5f5;
      transition: 0.2s;
      &.on {
        left: 0;
        z-index: 11;
      }
    }
  }
}