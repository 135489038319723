.VisitPeriodCalendar {
  .calendar {
    margin-top: 8px;
    position: absolute;
    z-index: 10;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.03), 0 6px 10px 2px rgba(0, 0, 0, 0.08);
    .calendarHeaderText {
      text-align: left;
      margin-left: 12px;
      margin-top: 8px;
      .yearText {
        font-size: 20px;
        color: #439099 !important;
        margin-right: 10px;
      }
      .monthText {
        font-size: 20px;
        color: #000000 !important;
      }
    }
    .DayPicker {
      width: 370px !important;
    }
    .DayPickerNavigation {
      color: #000000;
    }
    .DayPicker__hidden {
      visibility: visible;
    }

    .DayPickerNavigation_button {
      width: 100%;
      .prevButton {
        position: absolute;
        right: 58px;
      }
      .nextButton {
        position: absolute;
        right: 8px;
      }
      .navButton {
        top: 20px;
        .material-icons {
          width: 40px;
          height: 40px;
          padding: 8px;
        }
      }
      .navButton:hover {
        border-radius: 4px;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.02), 0 4px 6px 1px rgba(0, 0, 0, 0.06);
      }
      border: none;
    }
    .DayPicker_weekHeaders__horizontal {
      margin: 0px;
    }
    .DayPicker_weekHeader {
      margin-left: 4px;
      height: 30px;
      margin-top: 10px;
    }
    .DayPicker_weekHeader_ul {
      width: 370px;
    }

    .CalendarMonthGrid__horizontal {
      left: 0px;
    }

    .CalendarDay__blocked_out_of_range,
    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__blocked_out_of_range:hover {
      background: #fff;
      border: none;
      color: #cacccd;
    }
    .CalendarDay__default {
      border: none;
    }
    .CalendarMonth_table {
      border-collapse: separate;
      border-spacing: 4px 4px;
      margin-top: 5px;
      font-weight: bold;
    }
    .CalendarMonth {
      padding: 0px 16px !important;
    }
    .DayPicker_weekHeader_li {
      margin: 2px;
    }
    .DayPicker_weekHeader_li:first-child {
      color: #a50034;
    }
    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      border: 1px double #439099 !important;
      color: #fff !important;
      border-radius: 12px !important;
      background-color: #439099 !important;
    }

    .CalendarDay__default:hover {
      background: #439099 !important;
      border: 1px solid #439099 !important;
      border-radius: 12px !important;
      color: inherit !important;
    }
    .CalendarDay__hovered_span,
    .CalendarDay__hovered_span:hover {
      border: none !important;
      border-radius: 12px !important;
      background-color: rgba(#439099, 0.25) !important;
    }
    .CalendarDay__selected_span {
      background-color: rgba(#439099, 0.25) !important;
      border: none !important;
      border-radius: 12px !important;
      color: #fff !important;
    }
    .CalendarDay__highlighted_calendar[aria-disabled='false'] {
      background: transparent;
      color: #a50034;
    }

    div.DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2 {
      width: 370px !important;
      height: 392px !important;
    }
  }

  .calendarType {
    margin-top: 8px;
    border: none;
    box-shadow: none;
    .btn-group.btn-group-toggle {
      label {
        border-radius: 0.25rem;
        font-size: 14px;
        line-height: 20px;
        margin-right: 10px;
        padding: 6px 16px;
        background-color: transparent;
      }
      label.focus {
        box-shadow: none;
      }
      label.active {
        box-shadow: none;
      }
    }
    .btn-group.btn-group-toggle.outline-white {
      label {
        color: #ffffff;
        border-color: #ffffff;
      }
      label.active {
        background-color: #ffffff;
        color: #439099 !important;
        border-color: #ffffff;
      }
    }
    .btn-group.btn-group-toggle.outline-secondary {
      label {
        color: #439099 !important;
        border-color: #439099;
      }
      label.active {
        background-color: #439099;
        color: #ffffff !important;
        border-color: #439099;
      }
    }
    .btn-group.btn-group-toggle.outline-gray {
      label {
        color: #808080 !important;
        border-color: #808080;
      }
      label.active {
        background-color: #ffffff;
        color: #439099 !important;
        border-color: #439099;
      }
    }
    #rangeButton-hidden {
      visibility: hidden;
    }
  }
}

@media only screen and (max-width: 850px) {
  .VisitPeriodCalendar {
    .calendarType {
      position: fixed;
      bottom: 390px;
      left: 0;
      width: 100%;

      margin: 0;
      background-color: #ffffff;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      z-index: 12;
      .btn-group {
        margin-top: 20px;
        margin-left: 24px;

        #todayButton,
        #tomorrowButton {
          width: 57px;
          height: 32px;
          line-height: 32px;
          padding: 0;
        }

        &.btn-group-toggle.outline-white {
          label {
            background-color: #ffffff;
            color: #439099;
            border-color: #439099;
            &.active {
              background-color: #439099;
              color: #ffffff !important;
              border-color: #439099;
            }
          }
        }
      }
    }
    .calendar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      box-shadow: none;
      background: rgba(0, 0, 0, 0.2);
      z-index: 11;
      bottom: 0px;
      .DayPicker {
        width: 100% !important;
        height: 392px;
        position: fixed;
        bottom: 0px;
        & > div {
          text-align: center;
          & > div {
            display: inline-block;
          }
        }
        .DayPicker_weekHeader {
          padding: 0 !important;
        }
        .CalendarMonth {
          padding: 0 !important;
        }
      }
      .CalendarDay__default:hover {
        background: #fff !important;
        border: none !important;
        color: #484848 !important;
      }
      .CalendarDay__selected:hover {
        border: 1px double #439099 !important;
        color: #fff !important;
        border-radius: 12px !important;
        background-color: #439099 !important;
      }
    }
  }
}
