.TopContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  .col {
    padding-left: 0;
    padding-right: 0;
  }
  &.on {
    .layouts .navbarTop {
      width: calc(100% - 400px);
    }

    #reservationDrawer {
      width: 400px;
      height: auto;
      .drawer__wrapper {
        right: 0;
        &.loading {
          overflow-y: hidden;
        }
        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #fcfcfc;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: #ccc;
          &:hover {
            background: #555;
          }
        }
      }
    }
  }
}

.ReservationPage {
  width: 100%;
  background-color: #fafafa;
  .jumbotron {
    min-width: 1300px !important;
    width: 100%;
    height: 500px;
    position: absolute;
    border-radius: 0px !important;
    background-color: #f1f1f1;
    &:after {
      content: '';
      // background: url(../../icons/W-logo-BW-big.svg) no-repeat;
      background-position-x: calc(50% - 500px);
      background-position-y: -100px;
      transform: scaleX(-1);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 0;
      opacity: 0.1;
    }
  }

  .container {
    max-width: 1300px;
    width: 1300px;
    min-width: 1300px;
    margin-left: auto;
    margin-right: auto;

    .col {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .modal-z-index {
    z-index: 1040;
  }

  .padding-zero {
    padding: 0px;
  }
  .title {
    font-size: 24px;
  }

  .pointer {
    cursor: pointer;
  }
  .inline {
    display: inline;
  }
  .fontGreen {
    color: #439099 !important;
  }
  .underline {
    text-decoration: underline;
  }
  .bold {
    font-weight: bold;
  }
  .bgGray {
    background-color: rgba(#000000, 0.05);
  }
  .bgPink {
    background-color: rgba(#f87077, 0.25);
  }
  .fontRed {
    color: #ff0000 !important;
  }
  .borderRed {
    border-color: #ff0000;
  } 
}

@media only screen and (max-width: 850px) {
  #ReservationPage {
    padding-top: 80px;
    background-color: #ffffff;

    .jumbotron {
      display: none;
    }
    .container {
      width: 100%;
      min-width: auto;
      padding: 0;
      margin: 0;
      .row {
        margin: 0;
        & > div {
          width: 100%;
        }
      }
    }
  }
}
