#MemberInfoContainer {
  padding: 0px;
  margin: 0 auto;
  width: 435px;

  .col {
    padding-right: 0px;
    padding-left: 0px;
  }
  .member-info-title {
    width: 100%;
    padding: 0px;
    margin: 0px;
    font-weight: bold;
  }

  .title-label {
    font-size: 36px;
    height: 48px;
    position: absolute;
    left: 0.23%;
    right: 6.44%;
    top: calc(50% - 48px / 2 - 453.5px);
    letter-spacing: -1px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 850px) {
  #MemberInfoContainer {
    width: 100%;
    min-width: auto;
    padding-top: 0;
    margin-top: 32px;
    .member-info-title {
      font-size: 28px;
    }
  }
}
