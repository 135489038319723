.QrConfirm {
	width: 100%;
	display: flex;
  flex-direction: column;
  justify-content: flex-start;
	align-items: center;
	
	.QrDiv {
		width: 638px;
	}
	
	h3 {
		font-size: 40px;
		width: 465px;
		text-align: center;
		margin: 0 0 50px 86.5px;
	}

	.kiosk-timer {
		position: relative;
		bottom: 0;
		margin-top: 50px;
	}

	.sendMobileAuth {
		width: 900px;
		padding: unset;
		//height: 30%;
		
		.row {
			border: none;
		}

		.form-low {
			width: 900px;
		}

		#PhoneCertification {
			margin-top: 0;
			.form-label {
				display: none;
			}

			div, div > span {
				font-size: 40px;
				margin-bottom: 50px;
				letter-spacing: -0.2px;
				max-width: 900px;
				height: 43px;
			}

			div > span {
				margin-right: 200px;
				font-size: 30px;
				color: #ffffff;
				top: -40px;
				font-weight: bold;
				left: 650px;
			}

			.form-control {
				width: 729px;
				height: 120px;
				padding: 42px 31px 39px 36px;
				border-radius: 10px;
				border: solid 2px #bec9d4;
				background-color: #56606b;
				font-size: 36px;
				color: #bec9d4;

				&:focus {
					border: solid 3px #a50034 !important;
					background-color: #424952 !important;
				}
			}
			input::placeholder {
				font-weight: bold;
			}
			.certification-area {
				margin-top: -48px;
			}

			Button {
				height: 60px;
				border-radius: 10px;
				width: 150px;
				top: -30px;
				margin-bottom: 0px;
				margin-right: 0px;
				background-color: #a50034;
				color: #fff;
			}

			#phoneNumber {
				top:30px;
				display: none;
			}

			#certificationCheckButton {
				width: 900px;
				height: 120px;
				padding: 0;
				line-height: 40px;
				padding-left: 20px;
				top: 50px;
				opacity: 1;
				border-radius: 10px;
				background-color: #E9EFF5;
				color: #424952;
				font-size: 50px;
				font-weight: bold;
				line-height: 1.6;
				justify-content: center;
				align-content: center;
				margin-top: 50px;
			}

			.invalid {
				opacity: 0.2 !important;
			}

			#certificationReRequestButton {
				top: -190px;
				height: 120px;
				font-size: 30px;
				font-weight: bold;
			}

			#certificationKeyRequestButton {
				font-size: 17px;
				font-weight: bold;
				padding: 0 0 0 25px;
			}

			.invalid-feedback {
				width: 80%;
				font-size: 30px;
				float: left;
				margin-top: 20px;
				color: #E0205C;
				position: absolute;
			}
		}
	}
}