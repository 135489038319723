#KioskLogin {
  width: 100%;
	
	h3 {
		font-size: 40px;
    margin-bottom: 50px;
  }
  
  .form-group {
    width: 100%;
    margin-bottom: 8px;
  }

  .form-row {
    margin-left: 0;
    margin-right: 0;

    & + .form-row {
      margin-top: 30px;
    }

    & > .form-row {
      margin-top: 12px;
    }
    
    & > .col {
      height: 100px;
    }
  }

  a {
    cursor: pointer;
  }

  .form-control {
    border: 2px solid #BEC9D4;
    border-radius: 10px;
    background-color: #56606B;
    background-image: none;
    outline: none;
    box-shadow: none;
    padding-left: 36px;
    width: 100%;
    height: 120px;
    font-weight: bold;
    font-size: 40px;
    color: #ffffff;

    &:focus {
      border: solid 3px #a50034;
      background-color: #424952;
    }
  }

  input::placeholder {
    font-weight: normal;
    color: #BEC9D4 !important;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    font-weight: normal;
    color: #BEC9D4 !important;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: normal;
    color: #BEC9D4 !important;
  }

  ::placeholder {
    font-weight: normal;
    color: #BEC9D4 !important;
  }

  .form-email {
    position: relative;
  }

  #password {
    margin-top: 30px;
  }

  .formik-field {
    padding-right: 80px;
  }

  .submit-area {
    position: relative;
    width: 100%;
    margin-top: 52px;
    vertical-align: middle;
    
    span {
      font-size: 25px;
    }
  }

  .submit-row {
    display: flex;
    margin: 0;
  }

  .submit-button {
    width: 900px;
    height: 120px;
    padding: 0;
    line-height: 40px;
    padding-left: 20px;
    top: 50px;
    opacity: 1;
    border-radius: 10px;
    background-color: #E9EFF5;
    color: #424952;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.6;
    justify-content: center;
    align-content: center;
    margin-top: 50px;
  }

  .invalid {
    opacity: 0.2 !important;
  }

  .invalid-feedback {
    font-size: 25px;
  }

  .submit-feedback {
    color: #dc3545;
		font-size: 25px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
  }

  input:-internal-autofill-selected {
		background-color: #ffffff !important;
  }

  .login-form-toast {
    position: absolute;
    z-index: 999;
    top: 800px;
    left: -200px;
    margin: 10px;
    width: 461px;
    border-radius: 4px;
    background-color: #000000;
    color: #ffffff;
    text-align: center;

    .material-icons {
      vertical-align: middle;
    }
    .toastMessage {
      margin-left: 12px;
    }
  }
  .keyboard {
    width: 100%;
    margin-top: 10%;
    .hg-button {
      height: 100px;
      span {
        font-size: 25px;
      }
    }
  }
}
