#Test {
  .form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    border-radius: 0;
    color: white;
    background: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    width: 300px;
  }
}

#MemberContainer {
  width: 50%;
  min-width: 1200px;
  margin: 0 auto;
  padding-top: 120px;
  .container-fluid {
    min-width: 1200px;
    .row {
      .col {
        padding: 0px;
      }
    }
    .main-side {
      -ms-flex: 0 0 430px;
      flex: 0 0 430px;
      height: 100%;
    }
    .left-side {
      .left-status-area {
      }
    }
    .right-side {
      .right-area {
        float: right;
        align-items: center;
        display: flex;
        color: black;
        span {
          height: 19px;
          opacity: 0.6;
          font-size: 16px;
          text-align: right;
          line-height: 19px;
          margin-left: 3px;
        }
        span:hover {
          text-decoration: underline;
        }
      }
    }
    #Footer {
      margin-top: 150px;
    }
  }
}

@media only screen and (max-width: 850px) {
  #MemberContainer {
    width: 100%;
    min-width: auto;
    padding-top: 0;
    & > .container-fluid {
      min-width: auto;
      padding: 0;
      & > .row {
        flex-flow: column;
        margin: 0;
      }
      .main-side {
        flex: 0 0 auto;
      }
      .right-side {
        display: none;
      }
      #Footer {
        margin: 0;
      }
    }
  }
}
