.workplaceNoticeModal {
  .modal-dialog {
    width: 850px;
    max-width: 850px;
    min-height: 650px;
    box-sizing: border-box;
    border: none;
    #closeIcon {
      position: absolute;
      top: 27px;
      right: 27px;
      cursor: pointer;
    }
    .modal-content {
      border: none;
      border-radius: 4px;

      .modal-header {
        height: 155px;
        padding: 44.5px 59.5px 40.5px 63.5px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(151, 151, 151, 0.2);
        background-color: rgba(216, 216, 216, 0.2);
        button {
          opacity: 1;
          position: absolute;
          top: 27px;
          right: 27px;
          span {
            font-size: 28px;
            color: #000000;
          }
        }
        .modal-title {
          width: 430px;
          p {
            height: 32px;
            margin-bottom: 10px;
            display: inline-block;
            strong {
              color: #000000;
              font-size: 24px;
              line-height: 32px;
            }
            & + p {
              margin-bottom: 0;
              color: #000000;
              font-size: 20px;
              line-height: 28px;
              opacity: 0.5;
              white-space: nowrap;
              text-overflow: ellipsis;
              word-wrap: normal;
              overflow: hidden;
            }
          }
        }
      }
    }

    .modal-body {
      padding: 44.5px 66px 29px 62px;
      width: 850px;
      min-height: 381.5px;
      div {
        color: #000000;
        font-size: 16px;
        line-height: 28px;
        white-space: pre-line;
      }
    }

    .modal-footer {
      justify-content: flex-start;
      height: 123px;
      padding: 0 62px 50px;
      border-top: none;
      button {
        width: 100px;
        height: 50px;
        margin: 0;
        font-size: 20px;
        font-weight: bolder;
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .workplaceNoticeModal {
    .modal-dialog {
      width: 100%;
      margin: 0px;
      .modal-content {
        height: 100%;
        border: none;
        border-radius: 0;
        height: 100%;
        min-height: 100vh;
        .modal-header {
          border-bottom: 0px;
          height: auto;
          padding: 96px 24px 24px 24px;
          p {
            strong {
              font-size: 20px !important;
              line-height: 24px !important;
            }
            font-size: 16px !important;
            line-height: 20px !important;
          }
        }
        .modal-body {
          padding: 40px 24px !important;
          width: 100%;
          min-height: auto;
          div {
            font-size: 14px;
            line-height: 28px;
          }
        }
        .modal-footer {
          height: auto;
          padding: 16px 24px;
          button {
            padding: 0px;

            width: 100%;
            height: 56px;
            font-size: 18px;
          }
        }
      }
    }
  }
}
