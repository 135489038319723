#KioskGuide {
	width: 100%;
	margin: 0 auto;
	text-align: center;
	
	h3 {
		font-size: 38px;
		height: 106px;
		text-align: center;
		letter-spacing: -0.2px;
		margin-bottom: 0px;

		.siteInfo {
			font-size: 40px;
			color: #e0205c;
			font-weight: bold;
		}
	}

	.qrGuide {
		font-size: 30px;
		margin-top: 35px;
		color: #bec9d4;
	}

	.logo {
		margin-top: 22px;
		width: 647.51px;
		height: 428.52px;
	}

	.inviteQr {
		margin-top: 51.48px;
	}
}