.approval-info-container {
  background-color: #f1f1f1;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: auto;
  .container {
    padding: 0px;
    margin: 0px;
    max-width: 100%;
    min-height: 100%;
    .approval-notice-container {
      .row {
        margin: 0px;
        .col {
          padding: 0px;
          margin-top: 21px;
          margin-bottom: 24px;
          margin-left: 40px;
          span {
            padding: 0px;
            margin: 0px;
          }
        }
      }
    }
  }
  .top-row {
    width: 100%;
    padding: 0;
    padding-top: 10px;
    .top-col {
      width: 100%;
      height: 64px;
      padding: 0;
      p.top-title {
        text-align: center;
        width: 100%;
        height: 48px;
        left: 30.83%;
        right: 30.83%;
        top: calc(50% - 48px / 2);

        font-size: 20px;
        line-height: 48px;

        letter-spacing: -1px;

        color: #000000;
        strong {
          font-size: 20px;
          color: #000000;
          opacity: 1;
        }
        .logoImage {
          vertical-align: text-top;
          width: 34px;
          height: 18px;
        }
      }
    }
  }
  .middle-row {
    width: 100%;
    margin: 0;
    padding: 0;
    .middle-col {
      margin: 0;
      padding: 0;
      height: fit-content;
    }
    .content-row {
      .content-col {
        margin: 21px 32px 90px 32px !important;
      }
      .notice-content {
        padding: 0px;
      }
    }
  }
  .bottom-row {
    width: 100%;
    margin: 0;
    padding: 0;
    .bottom-col {
      width: 100%;
      align-items: center;
      padding: 0;
      div.bottom {
        padding: 30px 32px;
        height: fit-content;
        left: 0%;
        right: 0%;
        top: calc(50% - 56px / 2 - 20px);
        div {
          font-style: normal;
          font-size: 12px;
          line-height: 28px;
          white-space: pre-line;
          color: #000000;
          mix-blend-mode: normal;
          opacity: 0.6;
          p {
            display: inline-block;
            position: relative;
            margin: 0;
          }
          strong {
            font-size: 12px;
          }
        }
        strong.name-strong {
          font-size: 12px;
          color: #000000;
          opacity: 1;
        }
      }
    }
  }
}
