.ImportedGoodsButtonGroup {
  .btn-toolbar {
    .importedGoodsButton {
      margin-right: 10px;
    }

    .btn-group {
      width: 100%;
      display: inline-block;
      label,
      button {
        height: 36px;
        padding: 6px 16px;
        margin: 0 10px 10px 0px;
        border-radius: 4px !important;
        border-color: #439099;
        font-weight: bolder;
        font-size: 16px;
        line-height: 24px;
        &:focus,
        &.focus {
          box-shadow: none !important;
        }
      }
      .btn-outline-secondary.disabled {
        opacity: 0.4;
      }
      .moreShowImportedGoodsButton {
        padding-right: 8px;
        i {
          font-size: 16px;
          vertical-align: text-top;
          margin-left: 8px;
        }
        &:hover {
          i {
            border-top-color: #ffffff;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  #ImportedGoodsButtonGroup {
    margin: 0 -5px;
    .btn-group {
      button {
        font-size: 14px;
      }
      label {
        font-size: 14px;
      }
    }
  }
}
