.ReservationBottomButton {
  button {
    height: 50px;
    span {
      font-size: 20px;
      line-height: 28px;
    }
  }
  .tempSave {
    // width: 106px;
  }
  .save {
    width: 140px;
    margin-right: 10px;
    span {
      vertical-align: middle;
    }
    .material-icons {
      font-size: 24px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .btn.disabled,
  .btn:disabled {
    opacity: 0.3;
  }
}

@media only screen and (max-width: 850px) {
  .ReservationBottomButton {
    margin-bottom: 60px;
    .btn-toolbar {
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
    }
    .tempSave {
      display: inline-block;
      height: 56px;
      flex: 1 1 132px;
      background: rgba(0, 0, 0, 0.8);
      border: none;
      border-radius: 4px;

      span {
        font-size: 18px;
      }
    }
    .save {
      flex: 1 1 222px;
      height: 56px;
      margin: 0;
      border-radius: 4px;
      margin-right: 10px;
      i {
        font-size: 20px !important;
        margin-right: 8px !important;
      }
      span {
        font-size: 18px;
      }
    }
  }
  #requiredEducation {
    .material-icons {
      font-size: 21px !important;
    }
  }
}
