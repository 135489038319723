
.reservation.modal-open{
  .tooltip-inner {
    max-width: 100% !important;
  }
}
.excel-visitor-modal {
  .modal-dialog {
    max-width: 1000px;
    .bold {
      font-weight: 900;
    }
    .pointer {
      cursor: pointer;
    }
    .modal-content {
      width: 1000px;
      border-radius: 0;
      border: none;
      .modal-header {
        display: block;
        position: relative;
        padding: 40px 64px;
        font-size: 30px;
        border: 1px solid rgba(151, 151, 151, 0.2);
        border-radius: 0;
        background: rgba(216, 216, 216, 0.2);
        .title {
          line-height: 36px;
          font-weight: bolder;
          font-size: 24px;
          color: #000000;
          text-align: left;
        }
        button.download {
          margin-top: 20px;
          .material-icons {
            font-size: 20px;
            width: 24px;
            height: 24px;
            vertical-align: middle;
            margin-right: 5px;
            margin-top: 2px;
          }
        }
        button.close {
          position: absolute;
          top: 27px;
          right: 27px;
          height: 18px;
          line-height: 18px;
          opacity: 1;
          margin: 0;
          padding: 0;
          span {
            font-size: 28px;
            color: #000000;
          }
        }
        .modal__header--info {
          margin-top: 6px;
          line-height: 28px;
          font-size: 18px;
          color: #000;
          opacity: 0.4;
          font-weight: bold;
        }
        .invalid-visitor-count {
          margin-top: 5px;
          span {
            font-weight: bold;
            color: #ff0000;
            margin-right: 5px;
            font-size: 18px;
          }
          .material-icons {
            font-size: 20px;
            width: 24px;
            height: 24px;
            vertical-align: middle;
            margin-right: 5px;
            margin-top: 2px;
            color: #ff0000;
          }
        }
      }
      .modal-body {
        padding: 0 2px;
        .container {
          padding: 0;
          height: 100%;
          .visitorList {
            .table {
              width: 900px;
              margin: 40px 40px 30px 40px;
              border: 1px solid rgba(0, 0, 0, 0.2);
              .visitorName {
                span {
                  font-weight: bold;
                }
              }
              .visitPeriod {
                width: 210px;
                .material-icons {
                  font-size: 20px;
                  width: 24px;
                  vertical-align: middle;
                  margin-left: 5px;
                  margin-top: -1px;
                }
              }
              .visitPeriod.disabled {
                span {
                  opacity: 0.2;
                }
              }
              .mobile {
                width: 150px;
              }
              .invalidReason {
                width: 200px;
              }
              .selection-cell-header {
                width: 50px;
              }
              .selection-cell {
                width: 50px;
              }
              thead {
                display: block;
                width: 100%;
                tr {
                  border-bottom: 1px solid rgba(0,0,0,0.3);
                  background-color: rgba(196, 196, 196, 0.25);
                  opacity: 0.6;
                }
                th {
                  width: 12%;
                  border: none;
                }
              }
              tbody {
                height: 400px;
                overflow-y: auto;
                overflow-x: hidden;
                display: block;
                td {
                  width: 12%;
                  border: none;
                  border-top: 1px solid rgba(0, 0, 0, 0.2);
                  .invalidReason {
                    span {
                      color: #ff0000;
                    }
                  }
                }
                span {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
    .modal-footer {
      justify-content: flex-start !important;
      border-top: none;
      button {
        font-weight: bold;
        height: 50px;
        font-size: 18px;
        &:disabled {
          opacity: 0.1;
        }
      }
      .cancelButton {
        margin-left: 55px;
        width: 82px;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .excel-upload-modal {
    .modal-dialog {
      max-width: none;
      width: 100%;
      height: 100%;
      margin: 0;
      .modal-content {
        width: 100%;
        height: 100%;
        .modal-header {
          flex: 0 0 auto;
          padding: 40px 30px 20px;
          .title {
            font-size: 20px;
          }
          .modal__header--info {
            font-size: 16px;
          }
        }
        .modal-body {
          .container {
            .terms-content {
              padding: 30px;
            }
          }
        }
      }
    }
  }
}
