.reservation-modal {
  .modal-dialog {
    .bold {
      font-weight: 900;
    }
    .title {
      font-size: 22px;
      color: #000000;
    }
    .RMB15 {
      margin-bottom: 15px;
    }
    min-width: 844px;
    .modal-content {
      width: 844px;
      .modal-header {
        border-bottom: 0px;
        padding: 14px 30px 19px 30px;
        button {
          opacity: 1;
          span {
            font-size: 28px;
            color: #000000;
          }
        }
      }
      .modal-body {
        padding: 0px !important;
        .opinionContainer {
          padding: 31px 63px 0px 63px;
          .row {
            margin: 0;
            padding: 0;
            .col {
              margin: 0;
              padding: 0;
              .span {
                display: inline-block;
                font-size: 16px;
                vertical-align: top;
                line-height: 24px;
                padding: 0;
              }
              .input-group {
                height: 28px;
                border-radius: 15px;
                background-color: #ffffff;
                input.form-control {
                  padding: 0;
                  border: none;
                  background-color: transparent;
                  font-size: 20px;
                  color: #000000;
                  &:focus {
                    box-shadow: none;
                  }
                }
                input.form-control::placeholder {
                  padding: 0;
                  color: #000000;
                  opacity: 0.5;
                }
              }
              .hr {
                background-color: #000000;
                opacity: 0.25;
                height: 1px;
                width: 716px;
              }
            }
          }
        }
        .btn-toolbar {
          padding: 25px 63px 25px 63px;
          button {
            margin-right: 10px !important;
            &.approveConformButton {
              height: 50px;
              width: 100px;

              color: #ffffff;

              span {
                font-size: 20px;
                font-weight: bold;
                line-height: 28px;
                text-align: center;
              }
            }
          }

          .material-icons {
            font-size: 20px;
            vertical-align: middle;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .drawer__msg--alert {
    position: absolute;
    margin-top: 0;
    margin-bottom: 0;
    right: 63px;
    align-self: center;
    font-size: 14px;
    color: #ff0000;
  }
}

@media only screen and (max-width: 850px) {
  .reservation-modal {
    .modal-dialog {
      .modal-content {
        width: 844px;
        height: 100%;
        border: none;
        border-radius: 0;
        height: 100%;
        min-height: 100vh;
        .modal-header {
          border-bottom: 0px;
          padding: 14px 30px 19px 30px;
          button {
            opacity: 1;
            span {
              font-size: 28px;
              color: #000000;
            }
          }
        }
        .modal-body {
          padding: 0px !important;

          .opinionContainer {
            padding: 31px 24px 20px;
            .row {
              margin: 0;
              padding: 0;
              .col {
                margin: 0;
                padding: 0;
                .span {
                  display: inline-block;
                  font-size: 16px;
                  vertical-align: top;
                  line-height: 24px;
                  padding: 0;
                }
                .input-group {
                  input.form-control {
                    font-size: 16px;
                  }
                }
                hr {
                  margin-top: 5px;
                }
              }
            }
          }
          .btn-toolbar {
            padding: 25px 63px 25px 63px;
            margin-bottom: 40px;
            button {
              height: 56px !important;
              span {
                font-size: 18px !important;
              }
              &.reject-button {
                margin-right: 10px !important;
                width: 30%;
              }
              &.approve-button {
                margin-right: 0px !important;
                width: calc(70% - 10px);
              }
              &.approveConformButton {
                margin-right: 0px !important;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .drawer__msg--alert {
    position: relative !important;
    margin: 10px 0px !important;
    right: 0px !important;
  }
}
