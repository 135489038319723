#StatusPage {
  .status-step-line {
    height: 40px;
  }
  .circle-badge-icon {
    color: white;
    height: 16px;
    width: 16px;
    background-color: #16a085;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    line-height: 16px;
    vertical-align: middle;
    margin-top: -6px;
  }
  .circle-badge-icon-disable {
    @extend .circle-badge-icon;
    opacity: 0.2;
  }
  .status {
    height: 21px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    margin-left: 10px;
  }
  .status-disable {
    @extend .status;
    opacity: 0.2;
  }
  .visible-in-mobile {
    visibility: hidden;
  }
}

@media only screen and (max-width: 850px) {
  #StatusPage {
    position: relative;
    height: 64px;
    background-color: #f1f1f1;
    & > div {
      display: inline-flex;
      position: relative;
      width: 100%;
      height: 100%;
    }
    .status-step-line {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      .circle-badge-icon,
      .circle-badge-icon-disable {
        position: absolute;
        top: 24px;
        margin-top: 0;
      }
      &:first-child {
        .circle-badge-icon {
          right: 79px;
        }
      }
      &:nth-child(2) {
        .circle-badge-icon {
          right: 55px;
        }
      }
      &:nth-child(3) {
        .circle-badge-icon {
          right: 31px;
        }
      }
      .status {
        position: absolute;
        width: calc(100% - 140px);
        height: 40px;
        line-height: 40px;
        margin-left: 30px;
        background-color: #f1f1f1;
      }
      .status-disable {
        display: none;
      }
    }
    .visible-in-mobile {
      visibility: visible;
    }
  }
}
