.ReservationBottomForms {
  padding-right: 20px;
  .disabled {
    .title * {
      cursor: not-allowed !important;
      opacity: 0.5;
    }
  }
  .title-main {
    margin-bottom: 16px;
    span {
      font-size: 20px !important;
      font-weight: bold;
    }
    .upload-div {
      display: inline;
    }
    button.upload {
      margin-left: 20px;
      height: 26px;
      padding: 5px 10px 0 10px;
      background-color: rgba(#000000, 0.05);
      border: none;
      position: absolute;
      top: 2px;
      img {
        vertical-align: top;
        margin-top: 3px;
      }
      span {
        margin-left: 5px;
        font-size: 12px !important;
        font-weight: normal;
        vertical-align: top;
      }
      &:hover {
        color: #000000;
      }
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
  .title {
    font-weight: bold;
    margin-bottom: 6px;
    span {
      height: 24px;
      margin-left: 21px;
      margin-right: 21px;
      color: rgba(#000000, 0.8);
      font-size: 16px;
      line-height: 24px;
    }
    .material-icons {
      vertical-align: text-top;
    }
  }
  .bottom-line {
    box-sizing: border-box;
    height: 1px;
    border: 1px solid rgba(#979797, 0.2);
    margin-left: 17px;
    margin-right: 17px;
    margin-bottom: 12px;
  }
  .state {
    background-color: #ffffff;
    box-sizing: border-box;
    border: 1px solid rgba(#439099, 0.5);
    border-radius: 4px;
    padding-top: 4px;
    padding-bottom: 7px;
    margin-bottom: 60px;
    position: relative;
  }

  .state.noneMember {
    border: 1px solid rgba(#ff0000, 0.5);

    .visitorAdd {
      .form-item:last-child {
        input {
          padding-right: 22px;
        }
      }
    }
  }
  .clearNoneVisitor {
    display: none;
  }
  .underline {
    height: 24px;
    opacity: 0.4;
    color: #000000;
    font-size: 14px;
    line-height: 24px;
  }

  .visitorAdd {
    position: relative;
    .input-group {
      margin: 0px 10px;
      width: auto;
      flex-wrap: nowrap;
      border-radius: 4px;
      padding: 8px 15px;
      color: rgba(#000000, 0.5);

      select::-ms-expand {
        display: none;
      }
      select {
        font-size: 14px;
        line-height: 20px;
        width: 102px;
        border: none;
        margin: 6px 8px;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        background: url('../../../icons/arrow-select.svg') no-repeat 95% 50%;
        background-size: 8px;
        option {
          font-size: 14px;
          line-height: 20px;
        }
      }
      select:focus {
        outline-color: transparent;
      }
      .searchIcon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        cursor: pointer;
      }
      .searchIcon.material-icons {
        margin-right: 26px;
        font-size: 5px;
        vertical-align: middle;
        cursor: default;
        &:after {
          content: '비회원';
          position: absolute;
          top: 11px;
          left: 7px;
          display: inline-block;
          width: 42px;
          height: 21px;
          line-height: 20px;
          background: #ffffff;
          border: 1px solid #ff0000;
          border-radius: 4px;
          text-align: center;
          font-family: 'LGSmHa';
          font-size: 14px;
          color: #ff0000;
        }
      }
      input {
        font-family: 'LGSmHa';
        border: none;
        height: 28px;
        color: #000000;
        font-size: 20px;
        line-height: 28px;
        padding-left: 4px;
        margin-left: 10px;
        flex: 1 1 auto;
        padding: 0px;
        &::placeholder {
          opacity: 0.3;
        }
        &::-webkit-input-placeholder {
          /* Edge */
          opacity: 0.3;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          opacity: 0.3;
        }
      }
      input:focus {
        box-shadow: none;
      }
      .form-control:disabled,
      .form-control[readonly] {
        background-color: #ffffff;
        opacity: 0.5;
      }
    }
    .form-item {
      position: relative;
      display: flex;
      flex-flow: row;
      & > i {
        position: relative;
        top: 0;
        left: 0;
        width: 4px;
        height: 4px;
        background-color: #ea4c89;
        border-radius: 50%;
        flex: 0 0 auto;
        margin-left: 12px;
        margin-right: -6px;
      }
      & + .form-item {
        border-left: 1px solid rgba(151, 151, 151, 0.3);
      }
    }
    .input-group.disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
      * {
        cursor: not-allowed !important;
      }
    }
    .visitorAddBtn {
      display: none;
    }
    .searchUserListArea {
      margin-left: 42px;
    }
    .searchUserList {
      position: absolute;
      top: 50px;
      left: 50px;
      border-radius: 4px;
      background: #ffffff;
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.02), 0 4px 6px 1px rgba(0, 0, 0, 0.06);
      z-index: 2;
      .dropdown-item {
        display: block;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0 0.8rem;
        color: #000000;
        border-radius: 4px;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
          color: #000000;
        }
      }
    }
    span.text-danger {
      position: absolute;
      bottom: -48px;
      left: 0;
      color: #ff0000 !important;
    }

    &.search--success {
      &:after {
        content: '';
        position: absolute;
        top: 6px;
        right: 15px;
        width: 30px;
        height: 30px;
        background: url('../../../icons/subdirectory_arrow_left-24px.svg') no-repeat center;
        background-size: 70%;
        opacity: 0.5;
      }
    }
    .clear-search-cond {
      position: absolute;
      top: 6px;
      opacity: 0.5;
      right: 12px;
      width: 30px;
      height: 30px;
      padding: 3px;
      cursor: pointer;
    }
    .enter-search-cond {
      position: absolute;
      top: 6px;
      opacity: 0.5;
      right: 12px;
      width: 30px;
      height: 30px;
      padding: 3px;
      cursor: pointer;
    }
    input[type='tel']::-ms-clear {
      display: none;
    }
  }
  .visitPurpose, .workplaceEntryArea {
    margin-bottom: 60px;

    .btn {
      color: #000000;
      border-color: rgba(0, 0, 0, 0.25);
      box-shadow: none !important;
      margin-bottom: 8px;
      border-radius: 4px !important;
    }
    .btn:hover {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.25);
    }
    .btn:focus {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.25);
    }
    .btn-outline-dark:not(:disabled):not(.disabled) {
      color: black;
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.25);
    }
    .btn-outline-dark:not(:disabled):not(.disabled):focus {
      box-shadow: none;
    }
    .btn-outline-dark:not(:disabled):not(.disabled).active {
      background-color: rgba(#439099, 0.05);

      color: #439099;
      font-weight: bold;
      box-sizing: border-box;

      border: 1px solid #439099;

      &:focus {
        box-shadow: none;
      }
    }
    a {
      opacity: 0.3;
      color: #000000;
    }
    input:focus {
      box-shadow: none;
      border-color: #000000;
    }
  }
  .checkGroup {
    margin-bottom: 60px;
    .custom-checkbox {
      margin-top: 10px;
      label {
        font-size: 20px;
        font-weight: 28px;
        span {
          font-size: inherit;
        }
      }
      .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: #439099;
        background-color: #439099;
      }
      .custom-control-input:active ~ .custom-control-label::before {
        color: #fff;
        border-color: #439099;
        background-color: #439099;
      }
      .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 0.2rem rgba(#439099, 0.25);
      }
      .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #439099;
      }
    }
    a {
      vertical-align: sub;
      color: #000000;
      margin-left: 8px;
      font-size: 20px;
    }
  }
  .ReservationBottomVisitor:hover {
    cursor: pointer;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.02), 0 4px 6px 1px rgba(0, 0, 0, 0.06);
  }
  #back-button {
    display: none;
  }
  .button {
    margin-top: 40px;
    margin-bottom: 40px;
    button {
      padding: 8px 8px 8px 16px;
      span {
        margin-right: 8px;
      }
      .material-icons {
        vertical-align: bottom;
      }
    }
    .btn-outline-danger {
      color: #ff0000;
      border-color: #ff0000;
    }
    .btn-outline-danger:hover {
      color: #ffffff;
      border-color: #ff0000;
    }
    .educationState {
      font-size: 14px;
      line-height: 20px;
      margin-left: 12px;
      .material-icons {
        font-size: 20px;
        width: 24px;
        height: 24px;
        vertical-align: middle;
      }
    }
  }

  .PhoneInput {
    width: 100%;
  }

  .PhoneInputInput {
    width: 100%;
    outline: none;
    // margin-right: 24px !important;
  }

  .PhoneInputCountrySelect {
    width: 100% !important;
  }
}

@media only screen and (max-width: 850px) {
  .ReservationBottomForms {
    display: flex;
    flex-flow: column;
    position: relative;
    overflow-y: auto;
    // height: 100%;
    padding: 92px 24px 16px;
    #back-button {
      display: inline-block;
      width: 40px;
      height: 40px;
      border: none;
      background-size: unset;
      position: absolute;
      top: 30px;
      left: 24px;
      padding: 0;
      background: url('../../../icons/reservation/arrow_left.svg') no-repeat center;
    }
    .title-main {
      margin-bottom: 15px;
      .upload-div {
        display: none;
      }
    }
    .state {
      &:not(.noneMember) {
        position: relative;
        padding: 0;
        margin: 0;
        margin-bottom: 60px;
        border: none;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 0 3px 0 rgba(0, 0, 0, 0.05);
        .title {
          width: 100%;

          & > span {
            font-size: 14px;
          }
          & > #maxVisitor {
            position: absolute;
            top: 30px;
            left: 0;
          }
          .underline {
            position: absolute;
            top: 110px;
            right: 0;
            width: auto;
          }
        }
        .bottom-line {
          display: none;
        }
        .visitorAdd {
          padding: 16px 30px 16px 14px;
          padding-right: 0px;
          .searchUserBar {
            & > .input-group {
              padding: 0;
              margin: 0;
              .input-group-prepend {
                & + .form-item {
                  input {
                    width: 100px;
                  }
                }
              }
              .form-item {
                & > i {
                  margin-left: 8px;
                }
              }
              .searchIcon {
                margin-right: 0px;
              }
              input {
                font-size: 16px;
                margin-left: 8px;
                padding: 0 4px;
                height: 24px;
              }
            }
          }
          .clear-search-cond {
            top: -3px;
            right: 5px;
          }
          .enter-search-cond {
            top: -3px;
            right: 5px;
          }

          &.search--success,
          &.search--fail {
            &:after {
              top: 14px;
              right: 5px;
            }
          }
          span.text-danger {
            bottom: -40px;
            font-size: 16px;
          }
          .searchUserList {
            left: 0;
            width: 100%;
            .dropdown-item {
              &.noResult {
                white-space: normal;
                height: auto;
                line-height: 24px;
                padding: 9px 15px;
                p {
                  margin-bottom: 0;
                  strong {
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
        .visitorAddBtn {
          display: none;
        }
      }
      &.noneMember {
        position: relative;
        padding-bottom: 20px;
        border: none;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 0 3px 0 rgba(0, 0, 0, 0.05);
        .title {
          span {
            font-size: 14px;
            letter-spacing: 0px;
            margin: 0 14px;
          }
          .clearNoneVisitor {
            display: inline-block;
            position: absolute;
            top: 12px;
            right: 10px;
            width: 24px;
            height: 24px;
            margin: 0;
            background: url(../../../icons/close-24px.svg) no-repeat center;
            opacity: 0.5;
            text-indent: -9999px;
          }
          .underline {
            position: absolute;
            top: 265px;
            right: 0;
          }
        }
        .bottom-line {
          border: none;
          background-color: #979797;
          opacity: 0.2;
        }
        .visitorAdd {
          .clear-search-cond {
            top: 0px;
            right: 5px;
          }
          .enter-search-cond {
            top: 0px;
            right: 5px;
          }
          .enter-search-cond.N {
            display: none !important;
          }
          &.search--success,
          &.search--fail {
            &:after {
              top: -51px;
              right: 6px;
            }
          }
          .searchUserBar {
            .input-group {
              flex-flow: wrap;
              padding: 8px 0;
              .input-group-prepend {
                display: none !important;
                & + .form-item {
                  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
                  padding-bottom: 10px;
                  margin-bottom: -1px;
                  flex: 0 0 122px;
                  i {
                    margin-left: 9px;
                  }
                  input {
                    margin-left: 10px;
                  }
                  & + .form-item {
                    margin-bottom: 10px;
                    i {
                      margin-left: 9px;
                    }
                    input {
                      margin-left: 10px;
                    }
                    & + .form-item {
                      border-left: none;
                      border-right: 1px solid rgba(151, 151, 151, 0.3);
                      margin-top: 10px;
                      padding-left: 11px;
                      flex: 0 0 123px;
                      input {
                        margin-left: 5px;
                      }
                      & + .form-item {
                        border-top: 1px solid rgba(151, 151, 151, 0.2);
                        border-left: none;
                        padding-top: 10px;
                        padding-left: 10px;
                        margin-left: -1px;
                        input {
                          margin-left: 8px;
                        }
                      }
                    }
                  }
                }
              }
              .form-item {
                width: 50%;
                flex: 1 1 auto;
                input {
                  font-size: 16px;
                  padding: 0 4px;
                }
              }
            }
          }
        }
        .visitorAddBtn {
          display: block;
          width: calc(100% - 40px);
          height: 57px;
          margin-top: 20px;
          margin-left: 20px;
          border: 1px solid #000000;
          border-radius: 4px;
          background-color: #ffffff;
          font-weight: bold;
          font-size: 16px;
          &:disabled {
            opacity: 0.1;
          }
        }
      }
    }
    .checkGroup {
      margin-bottom: 0px;
      .custom-checkbox {
        label {
          font-size: 16px;
        }
        &:last-child {
          margin-bottom: 60px;
        }
      }
    }
    .ReservationBottomVisitor {
      & + .state {
        margin-top: 50px;
      }
    }
    .button {
      margin-bottom: 20px;
      &#requiredEducation {
        margin-top: 0px;
      }
      .bold {
        width: 100%;
      }
      .btn-outline-danger {
        span {
          // display: inline-block;
          margin: 0;
        }
      }
    }
    .educationState {
      display: none;
    }
  }
}
