@import 'lg-bootstrap-custom.scss';
* {
  font-size: 16px;
  font-family: 'LGSmHa';
}
@media only screen and (max-width: 850px) {
  * {
    font-size: 14px;
  }
  .modal {
    padding: 0px !important;
  }

  input[type='text']:focus,
  input[type='number']:focus,
  textarea:focus {
    font-size: 16px;
  }

  /* disable auto-zoom on iphone input field focus */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 16px;
    }
  }
}
html {
  height: 100%;
}

body {
  height: 100%;
  background-color: white !important;
  font-family: 'LGSmHa';
  overflow: visible;
}
body.reservation {
  background-color: #fafafa !important;
}
.root {
  height: 100%;
  background-color: transparent;
}
input {
  font-family: 'LGSmHa' !important;
}
input::-ms-clear {
  display: none;
}

.modal-backdrop {
  width: 100%;
  &.show {
    opacity: 0.33;
  }
}

.mT10 {
  margin-top: 10px;
}
.mT20 {
  margin-top: 20px;
}
.mT30 {
  margin-top: 30px;
}
.mT40 {
  margin-top: 40px;
}
.mT50 {
  margin-top: 50px;
}
.w460 {
  width: 460px;
}
.w600 {
  width: 600px;
}
.w900 {
  width: 900px;
}
.h200 {
  height: 200px;
}
.h240 {
  height: 240px;
}
.h372 {
  height: 372px;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

::-webkit-input-placeholder {
  /* Edge */
  color: #7b7b7b !important;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #7b7b7b !important;
}
::placeholder {
  color: #7b7b7b !important;
}
