#Footer {
  border-top: 1px solid rgba(#979797, 0.5);
  padding-top: 45px;
  padding-bottom: 50px;

  .row {
    margin: 0px;
  }

  .footer-links {
    padding: 0px;
    width: 100%;
    height: 28px;
    margin-bottom: 5px;
    a {
      color: #000000;
      text-decoration: none;
    }
    .danger {
      font-weight: bold;
      color: #a50034;
    }
    div {
      padding: 0px;
    }
  }
  .col-sm-7 {
    padding-left: 0;
  }

  .col-sm-6 {
    padding: 0px;
  }
  .col {
    width: 100%;
  }
  .footer-links {
    a {
      font-size: 20px;
    }
  }
  .col-sm-5 {
    padding: 0px;
  }
  .splitter {
    margin: 0px 5px;
    padding: 0px;
    width: 10px;
    text-align: center;
  }
  .cns-logo-image {
    margin-top: 30px;
    width: auto;
    height: 34px;
  }
  #singlexLogo {
    height: 30px;
  }
  button {
    cursor: pointer;
    &.btn {
      float: right;
      height: 40px;

      background-color: transparent;
      box-sizing: border-box;
      border: 1px solid rgba(#000000, 0.5);
      border-radius: 4px;
      color: #000000;
      font-weight: bold;
    }
  }

  .logoRow {
    align-items: flex-end;
  }
  .languageCol {
    button {
      &:focus {
        box-shadow: none;
      }
    }
    #select-language,
    .select-language {
      background: transparent;
      border: none;
      font-size: 16px;
      width: auto;
      height: 30px;
      line-height: 28px;
      margin-top: 2px;
      padding: 0;
      & + .dropdown-menu {
        min-width: auto;
        a {
          padding: 0 20px;
        }
      }
    }
  }
  .socialCol, .bannerCol {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    #select-channel {
      width: 194px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .dropdown-menu {
      width: 100%;
      font-size: 16px;
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
    padding-right: 0px;
    a {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
      white-space: normal;

      .singlexBanner {
        width: 194px;
        margin-left: 10px;
      }
    }
  }
  .row.siteManageInfo {
    span {
      height: 28px;
      color: rgba(#000000, 0.7);
      font-size: 14px;
      line-height: 28px;
      strong {
        font-size: 14px;
        line-height: 28px;
      }
      &.center {
        * {
          height: 28px;
          color: rgba(#000000, 0.7);
          font-size: 14px;
          line-height: 28px;
        }
        // margin: 0px 5px;
        // padding: 0px 5px;
      }
    }
  }

  .dropdown-language {
    display: none;
  }
}

@media only screen and (max-width: 850px) {
  #Footer {
    margin: 0 -15px;
    padding: 40px 30px 100px;
    border: none;
    background-color: rgba(0, 0, 0, 0.05);
    footer {
      .col-sm-7 {
        margin-top: 80px;
        .footer-links {
          height: auto;
          .d-inline-block {
            a {
              font-size: 16px;
              display: block;
              height: 60px;
              line-height: 60px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.05);
              &.splitter {
                display: none;
              }
            }
          }
        }
      }
      .languageCol {
        position: absolute;
        width: auto !important;
        padding-left: 0;
        #select-language,
        .select-language {
          height: 40px;
          display: inline-block;
          margin: 0px;
        }
      }
      .socialCol {
        position: absolute;
        top: 40px;
        width: auto !important;
        right: 32px;
        // left: 30px;
        // width: calc(100% - 60px) !important;

        .dropdown-menu {
          width: 100%;
        }
        .dropdown-channel {
          color: rgba(0, 0, 0, 0.5);
          position: relative;
          padding: 0 30px 0 14px;
          float: left;
          font-size: 14px;
          &:after {
            content: '' !important;
            position: absolute !important;

            top: 0px !important;
            right: 0px !important;
            width: 38px !important;
            height: 38px !important;
            display: inline-block !important;
            background: url('../icons/reservation/angle_right.png') no-repeat center !important;
            background-size: 24px 24px !important;
          }
        }
      }
      .bannerCol {
        .singlexBanner {
          margin-top: 10px;
          width: auto;
          height: 26px;
        }
      }
      .row.siteManageInfo {
        margin-top: 24px;
        span {
          font-size: 12px;
          strong,
          &.center * {
            font-size: 12px;
          }
        }
      }
      .cns-logo-image {
        margin-top: 10px;
        width: auto;
        height: 26px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  #Footer {
    footer {
      .row.siteManageInfo {
        span {
          font-size: 12px;
          strong,
          &.center * {
            font-size: 12px;
          }
        }
      }
      .col {
        padding: 0px;
      }
    }
  }
}