.ConfirmModal {
  * {
    font-size: 20px;
    line-height: 28px;
  }
  .modal-content {
    width: 500px;
    border-radius: 4px;
    border: none;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.03), 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  }
  .modal-dialog-centered {
    justify-content: center;
  }
  .modal-body {
    white-space: pre-line;
    padding: 34px 40px 40px 40px;
    p {
      margin-bottom: 0px;
    }
  }
  .modal-footer {
    border: none;
    margin: 0px;
    padding: 30px 40px 40px 40px;
    button {
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      margin: 0px;
      margin-left: 8px;
      height: 50px;
      padding: 11px 15px;
      .material-icons.md-24 {
        font-size: 24px;
        line-height: 1;
        margin-right: 11px;
        vertical-align: sub;
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .ConfirmModal {
    * {
      font-size: 18px !important;
      line-height: 24px !important;
    }
    .modal-footer {
      button {
        font-size: 18px !important;

        .material-icons.md-24 {
          font-size: 24px;
        }
      }
    }
  }
}
