/* 띠배너 css style*/
  /* 띠배너 seset */
  .band-banner-wrap div,
  .band-banner-wrap span,
  .band-banner-wrap ul,
  .band-banner-wrap li,
  .band-banner-wrap i,
  .band-banner-wrap a{margin: 0; padding: 0;}
  .band-banner-wrap ul, .band-banner-wrap li{list-style: none;}
  .band-banner-wrap img{border: 0;}
  .band-banner-wrap a{text-decoration: none;}
  /* 띠배너 - contents */
  // font-family: Note Sans KR,맑은 고딕, Malgun Gothic, Dotum, 굴림, arial, sans-serif;
  .band-banner-wrap
  {
    margin: 0; padding: 0; width: 100%; height: 35px; position: absolute; top: 0; left: 0;z-index: -1;
    * {
      font-family: Noto Sans KR,맑은 고딕,Malgun Gothic,Dotum,굴림,arial,sans-serif;
    }
  }
  .band-banner-wrap.bg{background: linear-gradient(90deg, #117581 8.37%, #6DCD93 96.62%);}
  .band-banner-wrap > .inner{max-width: 480px; margin: 0 auto; text-align: center;}
  .band-banner-wrap .menu-item{position: relative; display: inline-block; height: 100%; padding: 0 15px;}
  .band-banner-wrap .menu-item:after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    display: inline-block;
    width: 1px;
    height: 12px;
    background-color: #ffffff;
    opacity: 0.35;
  }
  .band-banner-wrap .menu-item:last-child:after{width: 0; height: 0;}
  .band-banner-wrap .d-day{
    display: inline-block; width: 48px; height: 19px;
    font-weight: 700; font-size: 12px; line-height: 19px;
    margin-right: 8px;
    background: #fff; border-radius: 9px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  }
  .band-banner-wrap .menu-item i,
  .band-banner-wrap .menu-item span,
  .band-banner-wrap .menu-item a{display: inline-block;}
  .band-banner-wrap .menu-item{height: 100%; line-height: 35px;}
  .band-banner-wrap .menu-item a{height: 100%;} 
  .band-banner-wrap .txt{color: #fff; font-weight: 700; font-size: 12px;}
  .band-banner-wrap .icon{margin-left: 5px;}
  .band-banner-wrap .icon > img{vertical-align: middle; margin-top:  -3px;} 