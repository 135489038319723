#KioskMain {
  position: relative;
  padding: 150px 90px 540px 90px;
  //background-repeat: no-repeat;
  //background-size: cover;
  background-color: #2c3238;
  min-width: 100%;
  overflow: hidden;
  height: 1920px;
  color: white;

  .col {
    padding: 0;
  }

  .KioskMain-top-row {
    //border-bottom-left-radius: 120px;
    background-color: #2c3238;
    height: 100%;
    //padding: 12% 5% 0 5%;
  }

  .KioskMain-title {
    width: 900px;
    height: 384.2px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 65px;
    margin: 0 0px 155.8px 0px;
    padding: 0;
    h2 {
      line-height: 105px;
      .KioskMain-title-workplace {
        font-size: 84px;
        font-weight: bold;
        letter-spacing: -1.68px;
      }
      .KioskMain-title-message {
        font-size: 74px;
        letter-spacing: -1.48px;
        //font-weight: bold;
      }
    }
    .material-icons {
        font-size: 80px;
        float: right;
    }
  }

  .KioskMain-form {
    width: 900px;
    position: relative;
    z-index: 1;
    margin-top: 155.8px;

    & > .row {
      margin: 0;
    }

    .KioskButton-Component {
      width: 100%;
    }

    .Kiosk-button-form {
      width: 100%;
      display: inline-block;
    }
  }

  Button {
    width: 280px;
    height: 320px;
    padding: 48px 24px 29px 25px;
    border-radius: 30px;
    border: 0;
    box-shadow: 8px 10px 40px 0 rgba(0, 0, 0, 0.08);
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;

    .button-text-qr {
      width: 262px;
      height: 170px;
      margin: 25px 257px 25.8px 0;
      font-size: 60px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: -0.6px;
    }

    .material-icons.md-48 {
      font-size: 90px;
      margin-top: 11px;
      position: relative;
    }
    .button-text-align {
      font-size: 40px;
      height: 106px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.2px;
      text-align: left;
    }
    #buttonTextName {
      width: 181px;
    }
    #buttonTextLogin {
      width: 197px;
    }
    #buttonTextInfo {
      width: 145px;
    }
  }

  .ConfirmByQr {
    width: 100%;
    margin: 0 0px 50px 0;
    padding: 50px 75.2px 49.2px 85px;
    box-shadow: 8px 15px 35px 0 rgba(239, 12, 84, 0.25);
    background-color: #a50034;
  }

  .ConfirmByLogin, .ConfirmByName {
    background-color: #ffffff;
    color: #424952;
    margin-right: 30px;
  }

  .ReservationGuide {
    background-color: #56606b;
  }

  .HomeButton {
    margin-left: 375px;
    margin-right: 375px;
    margin-bottom: 63px;
    position: absolute;
    bottom: 0px;
  }

  .MoveToHomeIcon {
    cursor: pointer;
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 100px;
    box-shadow: 4px 8px 35px 0 rgba(0, 0, 0, 0.6);
    background-color: #e9eff5;
  }

  .qrIcon {
    width: 220.8px;
    height: 220.8px;
  }

  .phoneIcon, .loginIcon, .infoIcon{
    width: 100px;
    height: 100px;
    margin: 37px 0 0 131px;
    object-fit: contain;
  }

  .lgLogo {
    width: 240px;
    height: 105.2px;
    flex-grow: 0;
    margin-bottom: 65px;
  }
}
.kiosk-timer {
  width: 100%;
  position: absolute;
  bottom: 230px;
  left: 0px;
  text-align: center;
  div {
    font-size: 40px;
    color: #bec9d4;
    padding-bottom: 20px;
  }
  .timer {
    font-size: 50px;
    color: #e0205c;
  }
}

// @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
//   Button {
//     width: 208px;
//     height: 208px;
//     text-align: center;
//     line-height: 300px;
//     margin-right: 19px;
//     border-radius: 24px;
//     font-size: 24px;
//     font-weight: bold;
//     // display: inline-block !important;
//     display: inline-block;
//     justify-content: center;
//     flex-wrap: wrap;
//     .material-icons.md-48 {
//       font-size: 65px;
//       position: relative !important;
//       margin-top: 50px;
//       margin-left: 0px !important;
//     }
//     .button-text-align {
//       margin-top: 20px !important;
//       margin-bottom: 0px !important;
//     }
//   }
// }