#MainInformationForm {
  .password-description {
    height: 60px;
    opacity: 0.7;
    color: black;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 2px;
  }
}
