#ModifyPasswordModal {
  // margin: 0px;
  width: 500px;
  padding-top: 100px;
  .modal-header {
    padding: 40px 64px;
    border: 1px solid rgba(151, 151, 151, 0.2);
    background: rgba(216, 216, 216, 0.2);
    .modal-title {
      .container {
        padding: 0;
      }
      .header {
        &.bold {
          line-height: 36px;
          color: #000000;
          font-weight: bolder;
          font-size: 24px;
        }
        &.light {
          margin-top: 4px;
          line-height: 28px;
          color: #000000;
          font-weight: normal;
          font-size: 18px;
          opacity: 0.5;
        }
      }
    }
    .close {
      position: absolute;
      top: 27px;
      right: 27px;
      height: 17px;
      line-height: 17px;
      padding: 0;
      margin: 0;
      text-shadow: none;
      outline: none;
      span {
        font-size: 28px;
      }
    }
  }
  .form-row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .col {
    padding: 0px;
  }
  .form-group {
    width: 100%;
    padding: 0px 10px 0px 10px;
  }
  .form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #979797;
    border-radius: 0;
    color: black;
    background: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    padding-bottom: 8px;
    box-sizing: border-box;
    margin-top: 24px;
    font-size: 20px;
    height: 40px;
  }
  .top-empty-space {
    margin-top: 50px;
  }

  .toggle-button {
    margin: 0px;
    padding: 0px;
    color: #439099 !important;
    text-decoration: underline;
  }
  .verical-align-bottom {
    position: relative;
    bottom: -35px;
  }
  .submit-button {
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    margin: 0px 0px 5px 5px;
  }
  .btn.disabled,
  .btn:disabled {
    opacity: 0.1;
  }

  .seperation-line {
    background-color: black;
    opacity: 0.1;
    margin-top: 40px;
    height: 1px;
    margin-bottom: 40px;
  }

  .info-item-title {
    font-size: 16px;
  }
  .info-item-desc {
    font-size: 20px;
    margin-top: 10px;
    &.disable-delegate {
      color: rgba(0, 0, 0, 0.3);
      img {
        display: none !important;
      }
    }
  }
  .delegate-info-message {
    margin-top: 10px;
    font-size: 16px;
    color: #a50034;
  }

  .col-sm-10 {
    padding: 0px;
  }

  .col-sm-2 {
    padding: 0px;
    text-align: right;
  }

  .card {
    border: none;
  }

  .card-header {
    background-color: white;
    border-bottom: none;
    padding: 0px;
  }

  .card-body {
    .element {
      all: revert;
    }
    padding: 0px;
    margin: 0px;
  }

  .card-body:collapsing {
    .element {
      all: revert;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: white;
    border-color: #439099 !important;
    background-color: #439099;
    padding: 0px;
  }
  .custom-control-input:active ~ .custom-control-label::before {
    color: white;
    border-color: #439099 !important;
    background-color: #439099;
    padding: 0px;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(#439099, 0.25);
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #439099 !important;
  }

  .custom-control-input:valid ~ .custom-control-label::before {
    border-color: silver;
  }

  .custom-control-input:valid ~ .custom-control-label {
    color: black;
  }

  .delegateSearch {
    display: inline-block;
  }

  .input-group-prepend {
    display: inline-block;
  }

  .delegateMemberName.visible {
    border-right: 1px solid rgba(#111111, 0.25) !important;
    display: inline-block;
  }
  .delegateMemberPhoneNumber.visible {
    font-size: 18px;
    display: inline-block;
  }

  .empKeyword {
    margin-top: 11px !important;
    display: inline-block;
    border: 1px solid rgba(67, 144, 153, 0.5);
  }

  .delegateMemberName,
  .delegateMemberPhoneNumber {
    margin-top: 20px;
    margin-left: 15px;
    margin-bottom: 10px;
  }

  .delete-ico:hover {
    cursor: pointer;
  }

  .dropdown-item {
    margin: 8px 8px 0px 0px;
    padding: 5px 20px 5px 8px;
    color: #ffffff;
    height: auto;
    width: auto;
    width: fit-content;
    border-radius: 2px;
    background-color: #545557 !important;
    .material-icons {
      margin-right: 8px;
    }
    img {
      margin-right: 8px;
      width: 24px;
      height: 24px;
      padding: 4px;
    }
    p {
      margin: 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: normal;
      overflow: hidden;
      max-width: 300px;
    }
  }
  .dropdown-item:hover {
    background-color: rgb(175, 18, 18) !important;
  }
  .dropdown-item:focus {
    background-color: rgb(160, 19, 19) !important;
  }
  .multiLine.dropdown-item {
    background-color: rgba(0, 0, 0, 0.25);
    margin-top: 0px;
    margin-bottom: 10px;

    .material-icons {
      vertical-align: super;
    }
    p {
      vertical-align: bottom;
    }
  }
  .oneLine.dropdown-item {
    width: 100%;
    margin: 0px;
    margin-bottom: 0px;
    padding: 5px 20px 5px 8px;
    img {
      vertical-align: top;
    }
    .material-icons {
      vertical-align: bottom;
    }
    p {
      vertical-align: top;
    }
  }
  .oneLine.visitTo.dropdown-item:hover {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .oneLine.visitTo.dropdown-item:focus {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .noResult.dropdown-item {
    text-align: center;
    padding: 0px;
    margin: 20px 0px 0px 0px;
    width: 100%;
    img {
      vertical-align: middle;
      padding: 0;
      width: 14px;
      height: 16px;
    }
    p {
      vertical-align: middle;
    }
  }
  .noResult.dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .noResult.dropdown-item:focus {
    background-color: transparent;
  }

  .input-group {
    height: 70px;
    border-radius: 15px;
    background-color: #ffffff;
    margin-top: 83px;
    align-items: center;
    input.form-control {
      border: none;
      background-color: transparent;
      font-size: 18px;
      color: #000000;

      &::placeholder {
        color: rgba(#000000, 0.5);
      }
    }
    input.form-control.hide {
      display: none;
      visibility: hidden;
    }
    input.form-control:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: none;
    }

    .searchIcon {
      width: 24px;
      height: 24px;
      margin-left: 20px;
    }
    .calendarIcon {
      width: 24px;
      height: 24px;
      margin-left: 20px;
    }
  }

  .toast {
    max-width: 800px;
  }
  #changeSuccessInformToast {
    position: fixed;
    bottom: 50px;
    z-index: 999;
    margin: 10px;
    border-radius: 4px;

    text-align: center;

    background-color: #000000;
    color: white;

    .toast-body {
      font-size: 20px;
      padding: 0px;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-left: 60px;
      margin-right: 60px;
      line-height: 30px;
    }

    .material-icons {
      vertical-align: middle;
    }
    .toast-contents {
      padding-left: 60px;
      padding-right: 60px;
    }
    .toast-message {
      margin-left: 12px;

      white-space: nowrap;
    }
  }

  #changeFailInformToast {
    position: fixed;
    bottom: 50px;

    z-index: 999;
    margin: 10px;
    border-radius: 4px;

    text-align: center;

    background-color: #000000;
    color: red;

    .toast-body {
      font-size: 20px;
      padding: 0px;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-left: 60px;
      margin-right: 60px;
      line-height: 30px;
    }

    .material-icons {
      vertical-align: middle;
    }
    .toast-contents {
      padding-left: 60px;
      padding-right: 60px;
    }
    .toast-message {
      margin-left: 12px;

      white-space: nowrap;
    }
  }

  .password-toast {
    left: calc(50% - 192px);
  }
  .phone-toast {
    left: calc(50% - 206px);
  }

  .notebook-toast {
    left: calc(50% - 239px);
  }
  .workplace-toast {
    left: calc(50% - 192px);
  }
  #mainWorkplaceFeedback {
    height: 19px;
    width: 100%;
    margin: 4px;
    display: block;
  }
  .react-select-container {
    width: 100%;
    margin-top: 24px;

    .react-select__control {
      border: none;
      border-radius: none;
      box-shadow: none;
      border-bottom: 1px solid #979797;
      border-radius: 0;

      .react-select__value-container {
        padding: 0px;
        .react-select__placeholder {
          margin: 0px;
          font-size: 20px;
        }
        .react-select__single-value {
          margin: 0px;
          font-size: 16px;
        }
      }
      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }
        .react-select__indicator {
          color: black;
        }
      }
    }
    .react-select__menu {
      border: none;
      border-radius: none;
      margin-top: 0px;

      max-height: 430px;
      .react-select__menu-list {
        .react-select__option {
          color: black;
          min-height: 40px;
          font-size: 20px;
        }
      }
    }
  }
  ::-webkit-input-placeholder {
    /* Edge */
    font-weight: normal;
    color: #7b7b7b;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: normal;
    color: #7b7b7b;
  }
}

@media only screen and (max-width: 850px) {
  #ModifyPasswordModal {
    width: 100%;
    padding-bottom: 45px;
    padding-top: 50px;
    * {
      font-size: 16px;
    }
    .form-row {
      & > .form-group {
        width: 100%;
        margin: 0;
      }
    }
    .form-label {
      margin-bottom: 4px;
      font-size: 16px;
    }
    .form-control {
      height: 40px;
      margin-top: 12px;
    }
    .form-control {
      font-size: 16px;
      & + .form-control {
        margin-top: 10px;
      }
    }
    .formik-field {
      width: 100%;
    }
    .invalid-feedback {
      margin-top: 0.25rem;
      font-size: 80%;
    }
    .submit-button {
      width: 100%;
      height: 64px;
      border-radius: 4px;
      font-size: 18px;
    }
    .card-header {
      .row {
        .col-10 {
          font-size: 16px;
        }
        .col-sm-10 {
          flex: 0 0 83.33333%;
          max-width: 83.33333%;
        }
        .col-sm-2 {
          flex: 0 0 16.66667%;
          max-width: 16.66667%;
        }
      }
    }
    .toast {
      width: calc(100% - 48px);
      max-width: none;
      left: 0px;
    }

    #changeFailInformToast {
      margin-left: 24px;
      .toast-body {
        margin-left: 0px;
        margin-right: 0px;
        .material-icons {
          font-size: 18px;
        }
      }
      .toast-message {
        margin-left: 8px;
        white-space: normal;
      }
    }

    #changeSuccessInformToast {
      margin-left: 24px;
      .toast-body {
        margin-left: 0px;
        margin-right: 0px;
        .material-icons {
          font-size: 18px;
        }
      }
      .toast-message {
        margin-left: 8px;
        font-size: 14px;
        white-space: normal;
      }
    }
    .toggle-button {
      font-size: 14px;
    }
    .info-item-desc {
      font-size: 20px;
      margin-top: 13px;
    }
    .info-item-title {
      font-weight: bold;
    }
    .term-third-change-date {
      font-size: 14px;
    }
    .term-third-desc {
      font-size: 14px;
    }
    .custom-control-label {
      line-height: 1.7;
    }
  }

}
