.notice-modify-modal {
  .modal-dialog {
    max-width: 700px;
    .modal-content {
      .modal-header {
        .close {
          span {
            font-size: 28px;
          }
        }
      }
      .modal-body {
        .form-row {
          margin: 0px;
          padding: 0px;
          margin-top: 10px;
        }
        .col {
          margin: 0px;
          padding: 0px;
        }
        .col-sm-1 {
          padding-left: 30px;
        }

        .start-date {
          position: absolute;
          top: -3px;
          left: 0px;
        }
        .date-splitter {
          position: absolute;
          font-size: 25px;
          top: -4px;
          left: 181px;
        }
        .end-date {
          position: absolute;
          top: -3px;
          left: 200px;
        }

        a {
          position: absolute;
          top: 2px;
          left: 82px;
          background: white;
          width: 473px;
        }
      }
    }
  }
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
}
