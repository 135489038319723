.terms-acception-modal {
  .modal-dialog {
    max-width: 760px;
    .bold {
      font-weight: 900;
    }
    .divTerms {
      height: 100%;
      overflow-y: auto;
      padding: 0;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: none;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #d8d8d8;
      }
    }
    .modal-content {
      width: 760px;
      border-radius: 0;
      border: none;
      .modal-header {
        display: block;
        position: relative;
        padding: 40px 64px;
        font-size: 30px;
        border: 1px solid rgba(151, 151, 151, 0.2);
        border-radius: 0;
        background: rgba(216, 216, 216, 0.2);
        .title {
          line-height: 36px;
          font-weight: bolder;
          font-size: 24px;
          color: #000000;
          text-align: left;
        }
        button {
          position: absolute;
          top: 27px;
          right: 27px;
          height: 18px;
          line-height: 18px;
          opacity: 1;
          margin: 0;
          padding: 0;
          span {
            font-size: 28px;
            color: #000000;
          }
        }
        .modal__header--info {
          margin-top: 6px;
          line-height: 28px;
          font-size: 18px;
          color: #000;
          opacity: 0.5;
        }
      }
      .modal-body {
        height: 496px;
        padding: 0 2px;
        .container {
          padding: 0;
          height: 100%;
          .terms-content {
            height: auto;
            line-height: 22px;
            padding: 50px 62px;
            font-size: 14px;
            color: #000000;
          }
        }
      }
    }
  }

  h4,
  .terms__title {
    font-weight: bolder;
    font-size: 16px;
    line-height: 22px;
  }
  strong,
  .terms__subtitle {
    display: block;
    font-weight: bolder;
    font-size: 14px;
    line-height: 22px;
  }
  p,
  .terms__content {
    font-size: 14px;
    line-height: 22px;
    & + .terms__subtitle {
      margin-top: 20px;
    }
  }
  ul,
  .terms__list--1depth {
    list-style: none;
    padding-inline-start: 0;
    padding: 0;
    & > li {
      font-size: 14px;
      line-height: 22px;
    }
  }
  ul > li > ul,
  .terms__list--2depth {
    list-style: none;
    padding-inline-start: 0;
    padding: 0;
    & > li {
      font-size: 14px;
      line-height: 22px;
    }
  }
  table {
    margin: 20px 0;
    border: 1px solid #666666;
    font-weight: bold;
    thead {
      th {
        height: 34px;
        text-align: center;
        padding: 8px 12px;
        border: 1px solid #666666;
        background: #f2f2f2;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
      }
    }
    tbody {
      td {
        vertical-align: top;
        padding: 8px 12px;
        border: 1px solid #666666;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .terms-acception-modal {
    .modal-dialog {
      max-width: none;
      width: 100%;

      margin: 0;
      &.modal-dialog-centered::before {
        height: auto;
      }
      .modal-content {
        width: 100%;
        min-height: 100vh;
        .modal-header {
          flex: 0 0 auto;
          padding: 40px 30px 20px;
          .title {
            font-size: 20px;
          }
          .modal__header--info {
            font-size: 16px;
          }
        }
        .modal-body {
          height: 100%;
          .container {
            .terms-content {
              padding: 30px;
            }
          }
        }
      }
    }
  }
}
