.privacy-policy-modal {
  .modal-dialog {
    max-width: 760px;
    .bold {
      font-weight: 900;
    }
    .divTerms {
      height: 100%;
      overflow-y: auto;
      padding: 50px 62px;
      display: flex;
      flex-direction: column;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: none;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #d8d8d8;
      }
    }
    .modal-content {
      width: 760px;
      border-radius: 0;
      border: none;
      .modal-header {
        display: block;
        position: relative;
        padding: 40px 64px;
        font-size: 30px;
        border: 1px solid rgba(151, 151, 151, 0.2);
        border-radius: 0;
        background: #f7f7f7;
        z-index: 1;
        .title {
          line-height: 36px;
          font-weight: bolder;
          font-size: 24px;
          color: #000000;
          text-align: left;
        }
        button {
          position: absolute;
          top: 27px;
          right: 27px;
          height: 18px;
          line-height: 18px;
          opacity: 1;
          margin: 0;
          padding: 0;
          span {
            font-size: 28px;
            color: #000000;
          }
        }
        .modal__header--info {
          margin-top: 6px;
          line-height: 28px;
          font-size: 18px;
          color: #000;
          opacity: 0.5;
        }
      }
      .modal-body {
        height: 496px;
        padding: 0 2px;
        .container {
          display: flex;
          flex-flow: column;
          padding: 0;
          height: 100%;
          .modal__subheader {
            height: 59px;
            padding: 5px 20px;
            background: #ffffff;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
            .modal__subheader--dropdown {
              display: inline-flex;
              float: right;
            }
            label {
              margin: 0 18px 0 0;
              font-size: 14px;
              line-height: 48px;
              color: #000000;
              opacity: 0.4;
            }
            .dropdown {
              .dropdown-toggle {
                height: 43px;
                position: relative;
                width: 250px;
                padding: 6px 10px;
                text-align: left;
                background: none;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid #000000;
                color: #000000;
                font-size: 16px;
                line-height: 31px;
                &:after {
                  border-top: 0.4em solid;
                  position: absolute;
                  top: 20px;
                  right: 8px;
                }
                &:focus {
                  box-shadow: none;
                }
              }
              .dropdown-menu {
                &.show {
                  display: block;
                  width: 100%;
                  margin: 0;
                  padding: 0;
                  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
                  border: none;
                  border-radius: 4px;
                  overflow-y: auto;
                }
                &::-webkit-scrollbar {
                  width: 8px;
                  height: 8px;
                }
                &::-webkit-scrollbar-track {
                  background: none;
                }
                &::-webkit-scrollbar-thumb {
                  border-radius: 4px;
                  background: #d8d8d8;
                }
                .dropdown-item {
                  height: 48px;
                  line-height: 48px;
                  padding: 0 1rem;
                  font-size: 16px;
                  &:hover {
                    background-color: rgba(67, 144, 153, 0.1);
                  }
                }
              }
            }
          }

          .terms-content {
            height: auto;
            line-height: 22px;
            padding-top: 16px;
            font-size: 14px;
            color: #000000;

            a {
              text-decoration: none;
              color: #000000;
            }
          }

          .labeling-header {
            padding-bottom: 25px;
          }

          .labeling-contents {
            display: flex;
            justify-content: space-around;          
            height: auto;
            line-height: 22px;
            font-size: 14px;
            color: #000000;

            .labeling-content {
              display: flex;
              flex-flow: column;
              align-items: center;
              .privacy-pics, .privacy-icons {
                margin-bottom: 10px;
              }

              .privacy-pics {
                width: 85px;
                height: 85px;
              }
            }
          }
        }
      }
    }
  }

  h4,
  .terms__title {
    font-weight: bolder;
    font-size: 16px;
    line-height: 22px;
  }
  strong,
  .terms__subtitle {
    display: block;
    font-weight: bolder;
    font-size: 14px;
    line-height: 22px;
  }
  p,
  .terms__content {
    font-size: 14px;
    line-height: 22px;
    & + .terms__subtitle {
      margin-top: 20px;
    }
  }
  ul,
  .terms__list--1depth {
    list-style: none;
    padding-inline-start: 0;
    padding: 0;
    & > li {
      font-size: 14px;
      line-height: 22px;
    }
  }
  ul > li > ul,
  .terms__list--2depth {
    list-style: none;
    padding-inline-start: 0;
    padding: 0;
    & > li {
      font-size: 14px;
      line-height: 22px;
    }
  }
  table {
    margin: 20px 0;
    border: 1px solid #666666;
    font-weight: bold;
    thead {
      th {
        height: 34px;
        text-align: center;
        padding: 8px 12px;
        border: 1px solid #666666;
        background: #f2f2f2;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
      }
    }
    tbody {
      td {
        vertical-align: top;
        padding: 8px 12px;
        border: 1px solid #666666;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .modal.privacy-policy-modal {
    .modal-dialog {
      max-width: none;
      width: 100%;

      margin: 0;
      &.modal-dialog-centered::before {
        height: auto;
      }
      .modal-content {
        width: 100%;
        min-height: 100vh;
        .modal-header {
          flex: 0 0 auto;
          padding: 40px 24px 20px;
          .title {
            font-size: 20px;
          }
          .modal__header--info {
            font-size: 16px;
          }
        }
        .modal-body {
          height: 100%;
          .container {
            .modal__subheader {
              .dropdown {
                .dropdown-toggle {
                  width: 180px;
                  font-size: 14px;
                }
                .dropdown-item {
                  font-size: 14px;
                }
              }
            }

            .labeling-contents {
              display:none;
            }
          }
        }
      }
    }
  }
}
