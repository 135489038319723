#EmailCertificationForm {
  margin-top: 50px;
  .form-row {
    margin-left: 0px;
  }
  .form-label {
    height: 32px;
    color: #000000;
    font-size: 20px;
    line-height: 32px;
  }
  .form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #979797;
    border-radius: 0;
    color: black;
    background: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    box-sizing: border-box;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    font-weight: normal;
    color: #7b7b7b;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: normal;
    color: #7b7b7b;
  }
  ::placeholder {
    font-weight: normal;
    color: #7b7b7b;
  }
  .formik-field {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #979797;
    border-radius: 0;
    color: black;
    background: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    box-sizing: border-box;
  }
  input {
    height: 60px;
    font-size: 18px;
    line-height: 32px;
  }

  .submit-button {
    height: 49px;
    width: 430px;
  }
  .email-input-div {
    width: 430px;
  }
  .certification-timer {
    height: 32px;
    width: 30px;
    opacity: 0.5;
    color: black;
    font-size: 16px;
    line-height: 32px;
    position: relative;
    top: 3px;
    right: 6px;
  }
  .certification-area {
    float: right;
    margin-top: -48px;
  }
  .col-md-8 {
    padding: 0px;
  }
  .no-label-form-control {
    margin-top: -7px;
  }
  .component-display-none {
    display: none;
  }
  .comonent-display {
    display: inline;
  }
  .input-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .checked-area {
    float: right;
    margin-top: -40px;
  }
  .certification-area > i {
    vertical-align: middle;
    margin-top: 5px;
  }
  i {
    margin-right: 5px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
  }
  .certificationReRequest {
    cursor: pointer;
    height: 32px;
    opacity: 0.5;
    color: black;
    font-size: 16px;
    line-height: 32px;
    text-decoration: underline;
  }
  .btn.disabled,
  .btn:disabled {
    opacity: 0.1;
  }
  .btn {
    position: relative;
  }
}

@media only screen and (max-width: 850px) {
  .modal-body {
    #EmailCertificationForm {
      .btn > span {
        font-size: 14px;
      }
      .form-label {
        font-size: 16px;
      }
      input {
        font-size: 14px;
      }
      .certification-timer {
        top: 2px;
        font-size: 14px;
      }
      #certificationCheckButton {
        margin-top: 0px;
      }
      #certificationKeyRow {
        margin-top: 0px;
        .component-display {
          margin-top: 30px !important;
        }
        .component-display-none {
          margin-top: 0px !important;
        }
      }
      .certification-area {
        margin-top: -40px;
      }
      .certification-area > i {
        font-size: 24px !important;
      }
    }
  }
}
