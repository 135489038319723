.invitation-modal {
  .modal-dialog {
    max-width: 450px;
    .bold {
      font-weight: 900;
    }
    .divTerms {
      height: 100%;
      overflow-y: auto;
      padding: 0;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: none;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #d8d8d8;
      }
    }
    .modal-content {
      width: 450px;
      border-radius: 0;
      border: none;
      .modal-header {
        display: block;
        position: relative;
        padding: 30px 34px;
        font-size: 20px;
        height: 115px;
        border: 1px solid rgba(151, 151, 151, 0.2);
        border-radius: 0;
        background: rgba(216, 216, 216, 0.2);
        .title {
          line-height: 36px;
          font-weight: bolder;
          font-size: 20px;
          color: #000000;
          text-align: left;
        }
        button {
          position: absolute;
          top: 27px;
          right: 27px;
          height: 18px;
          line-height: 18px;
          opacity: 1;
          margin: 0;
          padding: 0;
          span {
            font-size: 28px;
            color: #000000;
          }
        }
        .modal__header--info {
          margin-top: 6px;
          line-height: 28px;
          font-size: 15px;
          color: #000;
          opacity: 0.5;
        }
      }
      .modal-body {
        height: 400px;
        padding: 40 2px;
        .container {
          padding: 0;
          height: 100%;
        }
        .form-label{
          font-weight: bold;
          font-size: 14px;
        }
  
        .title {
          font-weight: bold;
          margin-bottom: 6px;
          span {
            height: 24px;
            margin-left: 21px;
            margin-right: 21px;
            color: rgba(#000000, 0.8);
            font-size: 14px;
            line-height: 24px;
          }
        }
        .state {
          background-color: #ffffff;
          box-sizing: border-box;
          border: 1px solid rgba(#439099, 0.5);
          border-radius: 4px;
          padding-top: 4px;
          padding-bottom: 7px;
          margin-bottom: 12px;
          position: relative;
        }
        .bottom-line {
          box-sizing: border-box;
          height: 1px;
          border: 1px solid rgba(#979797, 0.2);
          margin-left: 17px;
          margin-right: 17px;
          margin-bottom: 5px;
        }
        .inviteeAdd {
          position: relative;
          height: 28px;
          .input-group {
            margin: 0px 10px;
            width: auto;
            flex-wrap: nowrap;
            border-radius: 4px;
            padding: 0px 15px;
            color: rgba(#000000, 0.5);

            .form-item {
              position: relative;
              display: flex;
              flex-flow: row;
              & > i {
                position: relative;
                top: 0;
                left: 0;
                width: 4px;
                height: 4px;
                background-color: #ea4c89;
                border-radius: 50%;
                flex: 0 0 auto;
                margin-left: 12px;
                margin-right: -6px;
              }
              & + .form-item {
                border-left: 1px solid rgba(151, 151, 151, 0.3);
              }
            }
  
            input {
              font-family: 'LGSmHa';
              border: none;
              height: 28px;
              color: #000000;
              font-size: 14px;
              line-height: 28px;
              padding-left: 4px;
              margin-left: 10px;
              flex: 1 1 auto;
              padding: 0px;
              &::placeholder {
                opacity: 0.3;
              }
              &::-webkit-input-placeholder {
                /* Edge */
                opacity: 0.3;
              }
              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                opacity: 0.3;
              }
            }
            input:focus {
              box-shadow: none;
            }
            .form-control:disabled,
            .form-control[readonly] {
              background-color: #ffffff;
              opacity: 0.5;
            }
          }
          .form-item {
            position: relative;
            display: flex;
            flex-flow: row;
            & > i {
              position: relative;
              top: 0;
              left: 0;
              width: 4px;
              height: 4px;
              background-color: #ea4c89;
              border-radius: 50%;
              flex: 0 0 auto;
              margin-left: 12px;
              margin-right: -6px;
            }
            & + .form-item {
              border-left: 1px solid rgba(151, 151, 151, 0.3);
            }
          }
        }

        .form-group {
          margin-bottom: 0%;
        }

        #invitationMessage {
          min-height: 190px !important;
          max-height: 190px !important;
          background-color: #f7f7f7;
        }

        .span-group {
          text-align: right;
          .byte-span{
            font-size: 14px;
          }
        }

        .error-message {
          color: #FF6565;
          padding: .5em .2em;
          height: 2em;
          position: absolute; 
          font-size: .8em;
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .modal-header {
    padding: 10px 34px !important;
  }

  span {
    // font-size: 70% !important;
  }
}