
.approval-notice-container{
    width: 100%;
    left: 0px;

    background: #FFFFFF;

    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);
    border-radius: 0px;
    height: 100%;
    .row{
        .col{
            padding: 21px 0px;
                span.notice-title{
                    padding-top: 21px;
                    padding-left: 40px;
                    height: 28px;

                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 28px;

                    letter-spacing: -1px;


                    color: #444444;
                    .lang-disabled{
                      visibility: hidden;
                    }
                }
                .dropDown {
                    margin-top: 10px;
                }
                .dropdown-item:hover {
                  background-color: rgba(0, 0, 0, 0.5);
                }
                .dropdown-item:focus {
                  background-color: rgba(0, 0, 0, 0.5);
                }
          }

        }
        .line-row{
          hr.notice-line {
            margin-top: 0;
            margin-bottom: 0;
            position: absolute;
            width: 85%;
            height: 0px;
            left: 31.3px;

            border: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
        .content-row{
          .content-col{
            height: 100%;
            .notice-content{
              padding: 5px 20px 90px 32.35px;
                white-space: pre-line;
                height: 100%;
                left: 6.74%;
                right: 6.62%;
                top: calc(50% - 736px/2 - 132.5px);

                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 32px;
                /* or 178% */

                letter-spacing: -1px;

                /* main_text */

                color: #444444;
            }
          }
        }

      }
