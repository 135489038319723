.reservation-edu-agreement-modal {
  .modal-dialog {
    max-width: 1110px;
    .bold {
      font-weight: 900;
    }
    .title {
      font-size: 22px;
      color: #000000;
    }
    .RMB15 {
      margin-bottom: 15px;
    }
    .divBgGray {
      background-color: rgba(#d8d8d8, 0.2);
      max-height: 432px;
      overflow: scroll;
    }
    min-width: 844px;
    .modal-content {
      width: 100%;
      min-height: 700px;
      border-radius: 0;
      background-color: #f1f1f1;
      .modal-header {
        border-bottom: 0px;
        padding: 0;
        button {
          opacity: 1;
          span {
            font-size: 28px;
            color: #000000;
          }
        }
      }
      .modal-body {
        display: flex;
        flex-flow: column;
        padding: 0px !important;
        .PDFStepContainer {
          flex: 0 0 auto;
          height: 84px;
          padding: 22px 50px;
          .pdf-step {
            display: inline-block;
            min-width: 59px;
            height: 40px;
            padding: 5px 18px;
            text-align: center;
            background: #fff;
            border: 1px solid #000000;
            border-radius: 20px;
            color: #000000;
            font-weight: bolder;
            font-size: 20px;
            &.active {
              background-color: #000000;
              color: #ffffff;
              span {
                background-color: #000000;
                color: #ffffff;
              }
            }
            span {
              color: #000000;
              font-weight: bolder;
              font-size: 20px;
            }
            &.pdf-step {
              margin-left: 10px;
            }
          }
          .pdf__modal--close {
            position: relative;
            align-self: center;
            cursor: pointer;
          }
        }
        .PDFContainer {
          flex: 1 1 auto;
          padding: 0;
          background-color: rgba(67, 144, 153, 0.2);
          .pdf__pageInfo {
            display: none;
          }
          .pdf__navigation {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            width: 100%;
            height: calc(100% - 309px);
            left: 0;
            z-index: 1;
            .pdf__navigation--prev,
            .pdf__navigation--next {
              position: relative;
              display: inline-block;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              text-indent: -9999px;
              opacity: 0;
              cursor: pointer;
              transition: opacity 0.5s;
            }
            .pdf__navigation--prev {
              left: 50px;
              background: url('../../../icons/reservation/angle_left.png') no-repeat center, #ffffff;
              background-size: 24px 24px;
            }
            .pdf__navigation--next {
              right: 50px;
              background: url('../../../icons/reservation/angle_right.png') no-repeat center, #ffffff;
              background-size: 24px 24px;
            }
          }
          &:hover {
            .pdf__navigation--prev,
            .pdf__navigation--next {
              opacity: 1;
            }
          }
          .react-pdf__Document {
            canvas {
              margin: 0 auto;
              //max-width: stretch; - ie doesn't support stretch
              max-width: 1108px;
            }
            p {
              position: absolute;
              width: 100%;
              bottom: 0;
              margin-bottom: 0;

              font-size: 18px;
              color: #000000;
              text-align: center;
            }
          }
        }
        .PDFButtonContainer {
          flex: 0 0 auto;
          padding: 40px 50px;
          height: 225px;
          .custom-checkbox {
            margin-top: 0;
            padding-left: 30px;
            label {
              font-size: 20px;
              span {
                font-size: inherit;
              }
              &::before,
              &::after {
                left: -28px;
              }
            }
            .custom-control-input:checked ~ .custom-control-label::before {
              color: #fff;
              border-color: #439099;
              background-color: #439099;
            }
            .custom-control-input:active ~ .custom-control-label::before {
              color: #fff;
              border-color: #439099;
              background-color: #439099;
            }
            .custom-control-input:focus ~ .custom-control-label::before {
              box-shadow: 0 0 0 0.2rem rgba(#439099, 0.25);
            }
            .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
              border-color: #439099;
            }
          }
          button {
            position: absolute;
            left: 50px;
            bottom: 40px;
            min-width: 100px;
            height: 50px;
            padding: 0 15px;
            border-radius: 4px;
            span {
              font-weight: bolder;
              font-size: 20px;
            }
          }
          .agreement__cancel {
            position: absolute;
            right: 60px;
            bottom: 54px;
            color: #000000;
            opacity: 0.5;
            font-size: 16px;
            text-decoration: underline;
          }
          :disabled {
            opacity: 0.3;
          }
        }
        .PDFButtonContainerWithoutCheckBox {
          flex: 0 0 auto;
          padding: 40px 50px;
          height: 125px;
          .custom-checkbox {
            margin-top: 0;
            padding-left: 30px;
            label {
              font-size: 20px;
              span {
                font-size: inherit;
              }
              &::before,
              &::after {
                left: -28px;
              }
            }
            .custom-control-input:checked ~ .custom-control-label::before {
              color: #fff;
              border-color: #439099;
              background-color: #439099;
            }
            .custom-control-input:active ~ .custom-control-label::before {
              color: #fff;
              border-color: #439099;
              background-color: #439099;
            }
            .custom-control-input:focus ~ .custom-control-label::before {
              box-shadow: 0 0 0 0.2rem rgba(#439099, 0.25);
            }
            .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
              border-color: #439099;
            }
          }
          button {
            position: absolute;
            left: 50px;
            bottom: 40px;
            min-width: 100px;
            height: 50px;
            padding: 0 15px;
            border-radius: 4px;
            span {
              font-weight: bolder;
              font-size: 20px;
            }
          }
          .agreement__cancel {
            position: absolute;
            right: 60px;
            bottom: 54px;
            color: #000000;
            opacity: 0.5;
            font-size: 16px;
            text-decoration: underline;
          }
          :disabled {
            opacity: 0.3;
          }
        }
        .visitToTargetContainer {
          padding: 0px 63px 50px 63px;
          .wLogo {
            margin-right: 10px;
            opacity: 0.4;
          }
          .top-title-row {
            margin-bottom: 13px;
          }
          .complete-title {
            font-size: 30px;
            font-weight: bold;
            color: #000000;
          }
          .table {
            max-width: 400px;
            float: left;
            margin-bottom: 0px !important;
            .row {
              margin-bottom: 3px;
            }
            .left-label {
              max-width: 78px;
              padding: 0px;
              color: rgba(#000000, 0.6);
            }
            .complete-top-companyName-val {
              margin-right: 5px;
            }
            .complete-top-status-val {
              color: #ffffff;
              font-size: 10px;
              border-radius: 4px;
              padding: 2px 6px;
              background-color: #439099;
            }
          }
        }
        .visitorsContainer {
          padding: 0 63px 10px 63px;
          .visitor-title-row {
            margin: 22px 0 15px 0;
            .col {
              padding-left: 0px;
            }
          }
          .complete-visitor-title {
            margin-right: 5px;
            margin-bottom: 5px;
          }
        }
        .btn-toolbar {
          padding: 25px 63px 25px 63px;
          button {
            margin-right: 10px !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .reservation-edu-agreement-modal {
    .modal-dialog {
      width: 100%;
      margin: 0;
      min-width: 100%;
      .modal-content {
        height: 100%;
        min-height: 100vh;
        max-height: 100%;
        border: none;
        border-radius: 0;

        .modal-header {
          height: auto;
        }
        .modal-title {
          width: 100%;
        }
        .modal-body {
          background-color: #f1f1f1;
          .PDFStepContainer {
            width: 100%;
            height: auto;
            margin: 0;
            padding: 70px 0px 22px 0px;
            .pdf-step-group {
              padding-left: 14px;
            }

            .pdf-step {
              min-width: auto;
              height: 100%;
              padding: 0px 16px;
              margin-bottom: 10px;
              span {
                font-size: 12px;
              }
            }
            .pdf__modal--close {
              position: absolute;
              top: 27px;
              right: 27px;
              cursor: pointer;
            }
          }

          .PDFContainer {
            width: 100%;
            max-width: 100%;
            margin: 0;

            position: relative;
            flex: none;
            .pdf__navigation {
              // top: 50%;
              height: 100%;
            }
            canvas {
              width: 100% !important;
              height: auto !important;
            }
            .react-pdf__Document {
              p {
                font-size: 14px;
              }
            }
            .pdf__navigation--prev {
              opacity: 0.3 !important;
              left: 16px !important;
              height: 70% !important;
              max-height: 150px;
              background-color: transparent !important;
              background-size: 24px 100% !important;
            }
            .pdf__navigation--next {
              opacity: 0.3 !important;
              right: 16px !important;
              height: 70% !important;
              max-height: 150px;
              background-color: transparent !important;
              background-size: 24px 100% !important;
            }
          }

          .PDFButtonContainer {
            padding: 27px 24px;
            height: auto;

            label {
              font-size: 14px !important;
            }
            button {
              width: 100%;
              left: 0px;
              bottom: 0px;
              position: relative;
              min-width: 100px;
              height: 56px;
              padding: 0 15px;
              border-radius: 4px;
              margin: 32px auto;
              font-size: 18px;
              span {
                font-weight: bolder;
                font-size: 18px;
              }
            }
            .agreement__cancel {
              display: block;
              position: relative;
              right: 0px;
              bottom: 0px;
              color: #000000;
              opacity: 0.5;
              font-size: 14px;
              text-decoration: underline;
              text-align: center;
            }
            :disabled {
              opacity: 0.3;
            }
          }
        }
      }
    }
  }
}
