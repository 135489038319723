.kioskApplicationHistoryContainerBodyHistory {
    list-style: none;
    min-height: 0;
    max-height: 870px;
    overflow-y: hidden;
    text-align: center;

    &::-webkit-scrollbar {
      display: none;
    }

    .kioskHistoryTitle {
      font-size: 50px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .visitHistoryCard {
      background: #56606b;
      border-radius: 10px;
      border: solid 2px #bec9d4;;
      height: 200px;
      margin-bottom: 20px;
      padding-left: 30px;
      padding-top: 10px;
      .row {
        flex-flow: row;
        flex-wrap: nowrap;
        margin-left: 0;
        margin-right: 0;
        padding: 17px 10px 5px 10px;
        .col {
          width: auto;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    .card__header {
      margin-bottom: 1px;
      .card__header--place {
        flex: 1 1 auto;
        font-weight: bold;
        font-size: 40px;
        line-height: 28px;
        color: #333333;
        margin-bottom: 10px;
      }
      .card__header--applydate {
        flex: 0 0 auto;
        font-size: 25px;
        line-height: 28px;
        color: #000000;
        opacity: 0.5;
      }
    }
    .card__host {
      margin-top: 10px;
      font-size: 40px;
      color: #ffffff;
      font-weight: bold;
    }
    .card__chip {
      height: 24px;
      margin-bottom: 16px;
      em {
        display: inline-flex;
        align-items: center;
        height: 24px;
        padding: 0 5px;
        border-radius: 4px;
        i {
          margin-right: 2px;
        }
        span {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          margin: 0;
        }
      }
      .card__chip--apply {
        border: none;
        background-color: #439099;
      }
      .card__chip--confirm {
        border: none;
        background-color: rgba(0, 0, 0, 0.5);
      }
      .card__chip--cancel {
        border: 1px solid #a50034;
        background-color: #ffffff;
      }
    }
    .card__msg {
      margin-bottom: 10px;
      .card__msg--default {
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        opacity: 0.6;
      }
    }
    .card__period {
      label {
        font-size: 30px;
      }
      span {
        font-size: 30px;
      }
      div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-right: 10px;
        .visit-no {
          font-weight: bold;
          color: #e0205c;
        }
        .visit-yes {
          font-weight: bold;
          color: #13c6ff;
        }
      }
    }
    .card__member {
      margin-top: 4px;
      label {
        padding-left: 0;
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        opacity: 0.6;
      }
      span {
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        opacity: 0.9;
      }
    }
    .col-3 {
      padding: 0;
    }
    .noHistoryCard {
      display: flex;
      flex-flow: column;
      margin-top: 70px;
      align-items: center;
      justify-content: center;
      img {
        margin-bottom: 35px;
      }
      p {
        font-weight: bolder;
        font-size: 16px;
        color: #000000;
      }
      & + .drawer__list {
        display: none;
      }
      span {
        font-size: 35px;
      }
    }
    .KioskMain-title {
      width: 100%;
      padding: 50px 50px 0px;
      height: 15%;
      h2 {
        font-size: 35px;
        .KioskMain-title-target {
          font-size: 80px;
        }
      }
      .material-icons {
          font-size: 80px;
          float: right;
      }
    }
    .lgLogo {
      padding-bottom: 39.5px;
      width: 30%;
    }
}

.arrow_div {
  text-align: center;
  .up_arrow, .down_arrow {
    font-size: 48px;
    font-weight: bold;
  }
}