#LoginForm {
  width: 400px;
  margin: 0 auto;

  .form-row {
    margin: 0;
    & + .form-row {
      margin-top: 22px;
    }
  }

  a {
    cursor: pointer;
  }

  .form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #bbbbbb;
    border-radius: 0;
    color: #000000;
    background: rgba(241, 241, 241, 0);
    outline: none;
    box-shadow: none;
    padding: 0;
    margin-bottom: 12px;
    width: 400px;
    height: 48px;
    padding-bottom: 8px;
    font-weight: bold;
    font-size: 18px;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    font-weight: normal;
    color: #7b7b7b;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: normal;
    color: #7b7b7b;
  }

  ::placeholder {
    font-weight: normal;
    color: #7b7b7b;
  }

  .form-email {
    position: relative;
  }

  .formik-field {
    padding-right: 80px;
  }

  .check-saveid {
    position: absolute;
    top: 8px;
    right: 0;
    padding-left: 24px;
    .form-check-input {
      position: absolute;
      top: 0;
      left: 20px;
      width: 17px;
      height: 17px;
      cursor: pointer;
      &:checked ~ .form-check-label:before {
        border-color: #a50034;
        background-color: #a50034;
      }
      &:checked ~ label::after {
        content: 'Γ';
        background-image: none;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        color: #fff;
        transform: rotate(-135deg);
      }
    }
    .form-check-label {
      margin: 0;
      vertical-align: middle;
      float: left;
      cursor: pointer;
      font-size: 16px;
      color: #000000;
      &:before {
        position: absolute;
        top: calc(50% - 9px);
        left: 0;
        display: block;
        pointer-events: none;
        content: '';
        background-color: #ffffff;
        border: 1px solid #a50034;
        border-radius: 2px;
        width: 18px;
        height: 18px;
        box-shadow: none !important;
      }
      &:after {
        content: '';
        position: absolute;
        top: 1px;
        left: 7px;
      }
    }
  }

  .link-forgotidpassword {
    margin-top: 18px;
    margin-right: 10px;
    font-size: 16px;
    color: #676767;
    text-decoration: underline;
  }

  .submit-area {
    position: relative;
    width: 400px;
    margin-top: 15px;
    vertical-align: middle;
  }
  .submit-row {
    display: flex;
    margin: 0;
  }
  .submit-button {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    height: 64px;
    font-size: 24px;
    right: 0px;
    border: none;
    max-width: 190px;

    &,
    & *,
    &:disabled {
      transition: 0.5s;
    }

    .submit-button-text {
      white-space: nowrap;
      width: 66px;
      overflow: visible;
      margin: 0 24px 0 20px;
      font-weight: bold;
      font-size: 24px;
      opacity: 1;
    }

    & svg {
      fill: #fff;
      transform: scale(1);
      transition: none;
    }

    &:hover {
      background-color: #6f0023;
      border-color: #6f0023;
    }

    &:disabled {
      padding: 0 20px;
      max-width: 64px;
      background: #f1f1f1;

      .submit-button-text {
        opacity: 0;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }

      & svg {
        fill: #000;
        transform: scale(1.2);
      }
    }
  }

  .submit-feedback {
    color: #dc3545;
    margin-bottom: 20px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
  }

  input:-internal-autofill-selected {
    background-color: #ffffff !important;
  }

  .login-form-toast {
    position: absolute;
    z-index: 999;
    top: 800px;
    left: -200px;
    margin: 10px;
    width: 461px;
    border-radius: 4px;
    background-color: #000000;
    color: #ffffff;
    text-align: center;

    .material-icons {
      vertical-align: middle;
    }
    .toastMessage {
      margin-left: 12px;
    }
  }
}

@media only screen and (max-width: 850px) {
  #LoginForm {
    width: 100%;

    .form-group {
      width: 100%;
      margin-bottom: 8px;
    }

    .form-row {
      & > .form-row {
        margin-top: 12px;
      }
    }

    .form-control {
      width: 100%;
      font-size: 16px;
    }

    .check-saveid {
      .form-check-label {
        font-size: 16px;
        &:after {
          top: 1px;
        }
      }
      .form-check-input {
        top: 0px;
        left: 18px;
      }
    }

    .submit-area {
      width: 100%;
      margin-top: 8px;
      .submit-button {
        &:disabled {
          svg {
            transform: scale(1.8);
          }
        }
      }
    }

    .link-forgotidpassword {
      font-size: 14px;
    }

    .submit-button {
      width: 56px;
      height: 56px;
      border-radius: 28px;
      padding: 0;

      span {
        display: none;
      }

      svg {
        transform: scale(1.2);
      }
    }
  }
}
