#KioskNonMemberLogin {
  width: 100%;
	
	h3 {
		font-size: 40px;
    margin-bottom: 50px;
  }
  
  .form-group {
    width: 100%;
    margin-bottom: 8px;
  }

  .form-row {
    margin-left: 0;
    margin-right: 0;

    & + .form-row {
      margin-top: 30px;
    }

    & > .form-row {
      margin-top: 12px;
    }
    
    & > .col {
      height: 100px;
    }
  }

  a {
    cursor: pointer;
  }

  .form-control {
    border: 2px solid #BEC9D4;
    border-radius: 10px;
    background-color: #56606B;
    background-image: none;
    outline: none;
    box-shadow: none;
    padding-left: 36px;
    width: 100%;
    height: 120px;
    font-size: 40px;
    color: #ffffff;

    &:focus {
      border: solid 3px #a50034 !important;
      background-color: #424952 !important;
    }
  }

  input::placeholder {
    font-weight: normal;
    color: #BEC9D4 !important;
  }

  .invalid-feedback {
    width: 80%;
    float: left;
    font-size: 25px;
  }

  .form-label {
    font-size: 30px;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    font-weight: normal;
    color: #BEC9D4 !important;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: normal;
    color: #BEC9D4 !important;
  }

  ::placeholder {
    font-weight: normal;
    color: #BEC9D4 !important;
  }

  .formik-field {
    padding-right: 80px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
  }

  input:-internal-autofill-selected {
		background-color: #ffffff !important;
  }

  .login-form-toast {
    position: absolute;
    z-index: 999;
    top: 800px;
    left: -200px;
    margin: 10px;
    width: 461px;
    border-radius: 4px;
    background-color: #000000;
    color: #ffffff;
    text-align: center;

    .material-icons {
      vertical-align: middle;
    }
    .toastMessage {
      margin-left: 12px;
    }
  }

  Button {
    border-radius: 10px;
    height: 60px;
    margin-top: -40px;
    background-color: #a50034;
    color: #fff;
    padding: 0;
    justify-content: center;
  }

  #certificationTimer {
    font-size: 28px;
    top: -10px;
  }

  #certificationCheckButton {
    width: 120px;
    margin-bottom: 10px;
    line-height: 40px;
    margin-right: 5px;
  }

  #certificationReRequestButton {
    margin-right: 5px;
  }

  .keyboard {
    width: 100%;
    margin-top: 200px;
    .hg-button {
      height: 120px;
      span {
        font-size: 25px;
      }
    }
  }

  #PhoneCertification {
    margin-top: 0;

    .form-label {
      display: none;
    }

    div, div > span {
      font-size: 40px;
      margin-bottom: 50px;
      letter-spacing: -0.2px;
      max-width: 900px;
      height: 43px;
    }

    div > span {
      margin-right: 200px;
      font-size: 30px;
      color: #ffffff;
      top: -40px;
      font-weight: bold;
      left: 650px;
    }

    #phoneNumber {
      margin-top: 30px;
    }

    #certificationKeyRequestButton {
      width: 900px;
      height: 120px;
      padding: 0;
      line-height: 40px;
      padding-left: 20px;
      top: 50px;
      opacity: 1;
      border-radius: 10px;
      background-color: #E9EFF5;
      color: #424952;
      font-size: 50px;
      font-weight: bold;
      line-height: 1.6;
      justify-content: center;
      align-content: center;
      margin-top: 50px;
    }

    #certificationCheckButton {
      width: 900px;
      height: 120px;
      padding: 0;
      line-height: 40px;
      padding-left: 20px;
      top: 50px;
      opacity: 1;
      border-radius: 10px;
      background-color: #E9EFF5;
      color: #424952;
      font-size: 50px;
      font-weight: bold;
      line-height: 1.6;
      justify-content: center;
      align-content: center;
      margin-top: 50px;
    }

    #certificationReRequestButton {
      top: -190px;
      height: 120px;
      font-size: 30px;
      font-weight: bold;
    }

    .invalid-feedback {
      width: 80%;
      font-size: 30px;
      float: left;
      margin-top: 20px;
      color: #E0205C;
      position: absolute;
    }

    .invalid {
      opacity: 0.2 !important;
    }

    #phoneNumberFeedback {
      margin-top: -40px;
      font-size: 25px;
      position: relative;
    }

    #certificationKey {
      width: 729px;
      height: 120px;
      padding: 42px 31px 39px 36px;
      border-radius: 10px;
      border: solid 2px #bec9d4;
      background-color: #56606b;
      font-size: 36px;
      color: #bec9d4;
    }
  }
}
