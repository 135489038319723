#MemberInfoModifyPage {
  padding: 0px;
  margin: 0px;
  padding-top: 120px;
  background-color: white;

  .col {
    padding: 0px;
  }
  .left-side {
    text-align: right;
    vertical-align: top;

    .back-button {
      .material-icons {
        font-size: 42px;
        font-weight: bold;
      }
      padding: 0px;
      margin: 0px;
      margin-right: 10px;
      color: black;
    }
  }
  #Footer {
    margin-top: 150px;
    margin-left: 150px;
    margin-right: 150px;
    min-width: 1300px;
  }
}

@media only screen and (max-width: 850px) {
  #MemberInfoModifyPage {
    padding: 32px 24px 0px;
    .back-button {
      .material-icons {
        margin-left: -8px;
      }
    }
    & > .row {
      flex-flow: column;
      margin: 0;
    }
    .left-side {
      text-align: left;
    }
    .main-side {
      flex: 0 0 auto;
    }
    .right-side {
      display: none;
    }
    #Footer {
      margin: 0px -24px 0px;
    }
  }
}
