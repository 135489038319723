.requirement-invalid-modal {
  .modal-dialog {
    .bold {
      font-weight: 900;
    }
    .divBgGray {
    }
    min-width: 629px;
    .modal-content {
      height: 385px;
      width: 629px;
      .modal-header {
        box-sizing: border-box;
        height: 141px;
        border: 1px solid rgba(#979797, 0.2);
        background-color: rgba(#d8d8d8, 0.2);
        padding: 44.5px 63.5px 44.5px;

        .visitorInfo {
          .form-group {
            margin-bottom: 0rem !important;
          }
          .badge {
            font-size: 14px;
            border-radius: 4px;
            margin-right: 5px !important;
            line-height: 1.1;
            padding-top: 5px !important;
          }
          .badge.me {
            background-color: rgba(#000000, 0.5);
            color: #ffffff;
            padding: 5px;
          }
          .badge.lg {
            background-color: #ffffff;
            border: 1px solid #000000;
          }
          .badge.member {
            color: #00aed6;
            background-color: #ffffff;
            border: 1px solid #00aed6;
          }
          .badge.none-member {
            color: #ff0000;
            background-color: #ffffff;
            border: 1px solid #ff0000;
          }
          .visitor {
            height: 32px;
            color: #000000;
            font-size: 24px;
            strong {
              font-size: 24px;
            }
            font-weight: bold;
            line-height: 32px;
          }

          .visitorDetail {
            color: #000000;
            font-size: 16px;
            line-height: 28px;
          }
        }
        button {
          opacity: 1;
          span {
            font-size: 28px;
            color: #000000;
          }
          &.close {
            position: absolute;
            top: 22px;
            right: 22px;
            span {
              font-size: 28px;
            }
          }
        }
      }
      .modal-body {
        padding: 63.5px !important;

        .invalidMessage {
          height: 56px;
          width: 401px;
          color: #a50034;
          font-size: 20px;
          font-weight: bold;
          line-height: 28px;
        }

        .btn-toolbar {
          padding: 10px 0px 0px 0px;
          button {
            height: 50px;
            width: 100px;
            margin-right: 10px !important;
            color: #ffffff;
            font-size: 20px;
            font-weight: bold;
            line-height: 28px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .requirement-invalid-modal {
    &.modal.fade {
      .modal-dialog {
        &.modal-dialog-centered {
          min-width: auto;
          max-width: 100%;
          height: 100%;
          margin: 0;
          .modal-content {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 0;
            .modal-header {
              height: auto;
              padding: 92px 24px 24px !important;
              border: none;
              border-bottom: 1px solid rgba(151, 151, 151, 0.2);
              border-radius: 0;
              .visitorInfo {
                label {
                  span.badge {
                    font-size: 12px;
                  }
                  strong {
                    font-size: 20px;
                  }
                }
              }
              .close {
                position: absolute;
                padding: 0;
                margin: 0;
              }
            }
            .modal-body {
              display: flex;
              flex-flow: column;
              justify-content: space-between;
              padding: 24px !important;
              .divBgWhite {
                padding: 25px 0px;
              }
              p {
                margin-bottom: 30px;
                span {
                  font-weight: bold;
                  font-size: 16px;
                  color: #000000;
                  &.invalidMessage {
                    color: #a50034;
                  }
                }
              }
              .divBgGray {
                background-color: rgba(#000000, 0.05);
                padding: 22px 26px;
                margin-top: 60px;
                margin-bottom: 20px;
                ul {
                  list-style: none;
                  padding-inline-start: 0;
                  margin-bottom: 0;
                  li {
                    line-height: 36px;
                    label {
                      width: 98px;
                      margin-bottom: 0;
                      font-weight: normal;
                      font-size: 16px;
                      color: rgba(#000000, 0.5);
                    }
                    span {
                      font-weight: normal;
                      font-size: 16px;
                      color: #000000;
                    }
                  }
                }
              }
              a {
                &.email,
                &.talk {
                  display: block;
                  position: relative;
                  height: 50px;
                  line-height: 50px;
                  padding-left: 50px;
                  font-size: 18px;
                  font-weight: bold;
                  color: #439099;
                  &:before {
                    content: '';
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    left: 0;
                    top: 0;
                  }
                  &:after {
                    content: '';
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    background: url(/static/media/arrow_right_green.c0ff25f9.svg) no-repeat center;
                    position: absolute;
                    right: 0;
                    top: 0;
                  }
                }
                &.email {
                  &:before {
                    background: url('../../../icons/reservation/email.svg') no-repeat center;
                  }
                }
                &.talk {
                  &:before {
                    background: url('../../../icons/reservation/msgTalk.svg') no-repeat center;
                  }
                }
                & + a {
                  border-top: 1px solid rgba(151, 151, 151, 0.2);
                }
              }
              .btn-toolbar {
                justify-content: center;
                button {
                  width: 100%;
                  height: 56px;
                  font-size: 18px;
                  margin: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
