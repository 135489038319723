#agreeChangeModal {
  width: 360px;
  max-height: 160px;

  .row {
    margin: 0px;
  }
  .modal-content {
    border-radius: 0;
    border: none;
    min-height: 140px;
    max-height: 160px;

    .modal-body {
      padding: 24px;

      .container {
        padding: 0;
        height: 100%;
        .term-agree-change-content {
          padding-bottom: 40px;
          .term-change-comment {
            font-size: 16px;
            width: 100%;
          }

          .ok-button {
            width: 73px;
            position: absolute;
            bottom: 24px;
            right: 19px;
            height: 32px;
            opacity: 1;
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: bold;
          }
          .cancel-button {
            width: 57px;
            position: absolute;
            bottom: 24px;
            right: 100px;
            height: 32px;
            opacity: 1;
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: bold;
          }

          .check-icon {
            position: relative;
            left: -7px;
            bottom: -2px;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
