$green: #439099;
$blue: #0057e7;
$red: #a50034;
$yellow: #ffa700;
$white: #ccc;
$size: 4em;
$width: 70px;
html,
body {
  height: 100%;
  margin: 0;
}

._loading_overlay_overlay {
  position: fixed !important;
  z-index: 1060 !important;
}
._loading_overlay_overlay {
  .spinner {
    position: fixed !important;
    margin: 0px;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }
}
