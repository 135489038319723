#LoginContainer {
  height: 100% !important;
  width: 100%;
  background-color: #000000;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 20%;
  background-image: url('/loginbackground.jpg');
  .container-fluid {
    margin-left: 0px;
    background-color: rgba(241, 241, 241, 0.93);
    height: 100%;
    width: 600px;
    min-height: 100vh;
    .login-section-row {
      height: 80%;
    }
    .login-footer-row {
      height: 20%;
    }
    .row-footer {
      margin-left: 13%;
      margin-right: 13%;
      padding-top: 10px;
      border-top: 1px solid #d4d4d4;
    }
  }
}
@media only screen and (max-width: 850px) {
  #LoginContainer {
    display: inherit;
    padding-left: 0px;
    height: 1096px !important;
    min-width: 375px;
    overflow: hidden;
    background-position-x: right;
    background-size: 100% 50%;
    background-position-y: 500px;
    .container-fluid {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      padding: 0;
      .login-section-row {
        height: 80%;
      }
      .login-footer-row {
        height: 20%;
      }
      .row-footer {
        margin-left: 0%;
        padding-left: 32px;
        border-top: none;
        margin-bottom: 0px;
      }
    }
    .loginContainer-bottom-row {
      position: absolute;
      bottom: 0px;
      left: 0;
      border-top-left-radius: 60px;
      width: 100%;
      height: 250px;
      background-color: rgba(241, 241, 241, 0.93);
    }
  }
}
