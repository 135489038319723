.visitorDetailInfoModal {
  .modal-dialog {
    width: 554px;
    max-width: 554px;
    box-sizing: border-box;
    border: none;
    #closeIcon {
      position: absolute;
      top: 27px;
      right: 27px;
      cursor: pointer;
    }
    .modal-content {
      border: none;
      border-radius: 4px;

      .modal-header {
        height: 155px;
        padding: 44.5px 59.5px 40.5px 63.5px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(151, 151, 151, 0.2);
        background-color: rgba(216, 216, 216, 0.2);
        button {
          opacity: 1;
          position: absolute;
          top: 27px;
          right: 27px;
          span {
            font-size: 28px;
            color: #000000;
          }
        }
        .modal-title {
          width: 430px;
          p {
            height: 32px;
            margin-bottom: 10px;
            white-space: nowrap;
            word-wrap: normal;
            overflow: hidden;
            display: flex;

            .badge {
              height: 24px;
              padding: 2px 4px;
              margin: 4px 10px 4px 0px;
              vertical-align: bottom;
              font-size: 14px;
              line-height: 20px;
              border-radius: 4px;
              border: 1px solid;
            }
            .badge.me {
              border-color: #000000;
              color: #000000;
            }
            .badge.lg {
              border-color: #000000;
              color: #000000;
            }
            .badge.member {
              color: #00aed6;
              border-color: #00aed6;
            }
            .badge.none-member {
              color: #ff0000;
              border-color: #ff0000;
            }

            strong {
              display: inline-block;
              color: #000000;
              font-size: 24px;
              line-height: 32px;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            img.editButton {
              width: 15px;
              height: 30px;
              margin-left: 10px;
              cursor: pointer;
            }
            & + p.showInfo {
              display: block;
              text-overflow: ellipsis;
              margin-bottom: 0;
              color: #000000;
              font-size: 20px;
              line-height: 28px;
              opacity: 0.5;
            }
          }

          div {
            height: 32px;
            margin-bottom: 10px;
            white-space: nowrap;
            word-wrap: normal;
            overflow: hidden;
            display: inline-block;
            &.editInfo {
              width: 90%;
              display: flex;
              .input-group {
                display: flex;
                height: 100%;
                .form-control {
                  margin: 0;
                  padding: 0;
                  background-color: inherit;
                  height: 100%;
                  font-size: 20px;
                  line-height: 28px;
                  outline: 0;
                  color: #000000;
                  border-width: 0 0 1px;
                  border-radius: 0;
                  border-color: #c7c7c7;
                  box-shadow: none;
                  &.editName {
                    width: 45%;
                  }
                  &.editCompany {
                    width: 45%;
                  }
                  &.editPhone {
                    width: 60%;
                  }
                }
                .input-group-append {
                  margin-bottom: 0px;
                  margin-right: 10px;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                  padding: 0;
                  height: 100%;
                  width: fit-content;
                  & > .input-group-text {
                    padding: 0;
                    background-color: inherit;
                    border: none;
                    color: #000000;
                    opacity: 0.5;
                    i {
                      font-size: 20px;
                      line-height: 28px;
                      cursor: pointer;
                    }
                    i.invisable {
                      visibility: hidden;
                    }
                  }
                }
              }
              .badge {
                height: 24px;
                padding: 2px 4px;
                margin: 4px 10px 4px 0px;
                vertical-align: bottom;
                font-size: 14px;
                line-height: 20px;
                border-radius: 4px;
                border: 1px solid;
              }
              .badge.none-member {
                color: #ff0000;
                border-color: #ff0000;
              }
            }
          }
        }
      }

      .modal-body {
        padding: 44.5px 59.5px 44.5px 63.5px;
        .form-row {
          margin: 0px;
          margin-bottom: 40px;
          .form-group {
            width: 100%;
            margin-bottom: 0px;
            .form-label {
              margin-bottom: 16px;
            }
            .card {
              margin-bottom: 10px;
            }

            .btn-toolbar {
              .importedGoodsButton {
                margin-right: 10px;
              }
            }
          }
        }
        .form-row:last-child {
          margin-bottom: 0px;
        }
        .input-group {
          height: 39px;
          margin-bottom: 0px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.25);
          padding-bottom: 11px;
          .input-group-prepend {
            .calendarIcon {
              margin-right: 10px;
              width: 16px;
              height: 24px;
            }
          }
          input.form-control,
          input.form-control-plaintext {
            height: 28px;
            border: none;
            padding: 0px;
            background-color: transparent;
            font-size: 20px;
            line-height: 28px;
            color: #000000;

            &:focus {
              -webkit-box-shadow: none;
              box-shadow: none;
              outline: none;
            }
            &::placeholder {
              opacity: 0.5;
            }
          }
          .input-group-append {
            span {
              padding: 0px;
              border: none;
              background: none;
              cursor: pointer;
            }
          }
        }
      }

      .modal-footer {
        justify-content: flex-start;
        padding: 0 62px 50px;
        border-top: none;
        button {
          width: 100px;
          height: 50px;
          margin: 0;
          font-size: 20px;
          font-weight: bolder;
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .modal.visitorDetailInfoModal {
    .modal-dialog {
      width: 100%;
      min-width: 100%;
      height: auto;
      margin: 0;
      .modal-content {
        border: none;
        border-radius: 0;
        height: 100%;
        min-height: 100vh;
        .modal-header {
          height: auto;
          padding: 92px 24px 24px;
          p {
            margin-bottom: 5px;
            strong {
              font-size: 20px;
            }
            span.badge {
              font-size: 12px;
            }
            & + p {
              font-size: 16px;
              line-height: 21px;
              height: 24px;
              margin-bottom: 0px;
            }
          }
        }
        .modal-title {
          width: 100%;
          .editInfo {
            width: 100% !important;
          }
          .form-control {
            font-size: 16px !important;
          }
        }

        .modal-body {
          padding: 40px 24px;
          .input-group {
            input {
              font-size: 16px;
            }
          }
        }
        .modal-footer {
          padding: 15px 15px 20px;
          button {
            width: 100%;
            height: 56px;
            font-size: 18px;
          }
        }
      }
    }
  }
}
