.reservation-complete-modal {
  .modal-dialog {
    .bold {
      font-weight: 900;
    }
    .title {
      font-size: 22px;
      color: #000000;
    }
    .RMB15 {
      margin-bottom: 15px;
    }
    .divBgGray {
      background-color: rgba(#d8d8d8, 0.2);
      max-height: 455px;
      overflow: auto;
    }
    min-width: 844px;
    .modal-content {
      width: 844px;
      .modal-header {
        border-bottom: 0px;
        padding: 14px 30px 19px 30px;
        button {
          opacity: 1;
          span {
            font-size: 28px;
            color: #000000;
          }
        }
      }
      .modal-body {
        padding: 0px !important;
        .visitToTargetContainer {
          padding: 0px 63px 50px 63px;
          .wLogo {
            margin-right: 10px;
            opacity: 0.4;
          }
          .top-title-row {
            margin-bottom: 13px;
          }
          .complete-title {
            font-size: 30px;
            font-weight: bold;
            color: #000000;
            white-space: pre-line;
          }
          .table {
            max-width: 500px;
            float: left;
            margin-bottom: 0px !important;
            .row {
              margin-bottom: 3px;
            }
            .left-label {
              max-width: 78px;
              padding: 0px;
              color: rgba(#000000, 0.6);
            }
            .top-companyName-val {
              margin-right: 5px;
            }
            .top-status-val {
              color: #ffffff;
              font-size: 14px;
              border-radius: 4px;
              padding: 4px 6px;
              margin-right: 20px;
              background-color: #439099;
              .material-icons {
                font-size: 14px;
                vertical-align: middle;
                margin-bottom: 2px;
              }
            }
            .top-status-val.approve-done {
              color: #ffffff;
              font-size: 14px;
              border-radius: 4px;
              padding: 4px 6px;
              margin-right: 20px;
              background-color: #000000;
              opacity: 0.5;
              .material-icons {
                font-size: 14px;
                vertical-align: middle;
                margin-bottom: 2px;
              }
            }
            .top-status-val.reject {
              box-sizing: border-box;
              padding: 4px 6px;
              margin-right: 20px;
              border: 1px solid #a50034;
              color: #a50034;
              border-radius: 4px;
              background-color: #ffffff;
              .material-icons {
                font-size: 14px;
                vertical-align: middle;
                margin-bottom: 2px;
              }
            }
            .top-status-val.temp-save {
              box-sizing: border-box;

              margin-right: 20px;
              border: solid 1px #000000;
              background-color: rgba(0, 0, 0, 0);
              color: #000000;
              border-radius: 4px;
              opacity: 0.5;
            }
          }
        }
        .visitorsContainer {
          padding: 0 63px 10px 63px;
          .visitor-title-row {
            margin: 22px 0 15px 0;
            .col {
              padding-left: 0px;
            }
          }
          .complete-visitor-title {
            margin-right: 5px;
            margin-bottom: 5px;
          }
        }
        .btn-toolbar {
          padding: 25px 63px 25px 63px;
          button {
            margin-right: 10px !important;
            height: 50px;
            width: 100px;
            color: #ffffff;
            span {
              font-size: 20px;
              font-weight: bold;
              line-height: 28px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .modal.reservation-complete-modal {
    .modal-dialog {
      margin: 0;
      width: 100%;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      .modal-content {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        height: 100%;
        min-height: 100vh;
        .modal-header {
          padding: 30px 30px 30px 15px;
          .close {
            padding: 0;
            margin: 0;

            span {
              &:first-child {
                font-size: 40px;
              }
            }
          }
        }
        .modal-body {
          display: flex;
          flex-flow: column;
          flex-wrap: nowrap;
          .visitToTargetContainer {
            padding: 0 24px 20px;
            flex: 1 1 auto;
            .wLogo {
              margin-top: 30px;
              margin-bottom: 5px;
            }
            .complete-title {
              font-size: 24px;
            }
            .title {
              font-size: 26px;
            }
            .table {
              .row {
                margin-bottom: 6px;
              }
              .top-status-val {
                padding: 4px 6px;
              }
            }
          }
          .visitorsContainer {
            padding: 20px 24px 20px;
            .visitor-title-row {
              margin: 0 0 16px;
            }
          }
          .btn-toolbar {
            flex: 0 0 auto;
            padding: 15px 24px 15px;

            .btn {
              width: 100%;
              height: 56px;
              margin-right: 0 !important;
              span {
                font-weight: bold;
                font-size: 18px;
              }
            }
          }
        }
      }
      .divBgGray {
        flex: 1 1 auto;
        background-color: #f5f5f5;
      }
    }
  }
}
