.reset-password-modal {
  .modal-dialog {
    max-width: 553px;
  }
  .modal-content {
    border-radius: 0;
    border: none;
  }
  .modal-header {
    padding: 40px 64px;
    border: 1px solid rgba(151, 151, 151, 0.2);
    background: rgba(216, 216, 216, 0.2);
    .modal-title {
      .container {
        padding: 0;
      }
      .header {
        &.bold {
          line-height: 36px;
          color: #000000;
          font-weight: bolder;
          font-size: 24px;
        }
        &.light {
          margin-top: 4px;
          line-height: 28px;
          color: #000000;
          font-weight: normal;
          font-size: 18px;
          opacity: 0.5;
        }
      }
    }
    .close {
      position: absolute;
      top: 27px;
      right: 27px;
      height: 17px;
      line-height: 17px;
      padding: 0;
      margin: 0;
      text-shadow: none;
      outline: none;
      span {
        font-size: 28px;
      }
    }
  }
  .modal-body {
    padding: 50px 64px;
    .form-row {
      margin: 0;
      & + .form-row {
        margin-top: 50px;
      }
    }
    .form-group {
      width: 100%;
      margin: 0;
    }
    .form-label {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 32px;
      color: #000000;
    }
    .form-control {
      height: 40px !important;
      padding: 0;
      padding-bottom: 8px !important;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #979797;
      font-size: 18px;
      line-height: 32px;
      &:focus {
        box-shadow: none;
      }
      &.is-invalid {
        border-color: #ff0000;
        background: none;
      }
    }
    .invalid-feedback {
      margin-top: 0.25rem;
      font-size: 80% !important;
    }
    #passwordConfirm {
      margin-top: 27px;
      margin-bottom: 1px;
    }
    #resetPasswordButton {
      margin-top: 48px;
    }
    .bottom-empty-space {
      margin-bottom: 50px;
    }
    .type-phone-button {
      width: (100% / 2);
      border-top-left-radius: 4px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 0px;
      span {
        font-weight: bolder;
        font-size: 16px;
        line-height: 28px;
      }
      &:focus,
      &:active {
        box-shadow: None;
      }
    }
    .type-email-button {
      width: (100% / 2);
      border-top-left-radius: 0px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 4px;
      span {
        font-weight: bolder;
        font-size: 16px;
        line-height: 28px;
      }
      &:focus,
      &:active {
        box-shadow: None;
      }
    }
  }
  .submit-button {
    margin-top: 30px;
    width: 76px;
    height: 50px;
    span {
      font-weight: bolder;
      font-size: 20px;
      line-height: 28px;
    }
    &.disabled,
    &:disabled {
      opacity: 0.1;
      cursor: default;
    }
  }
}

@media only screen and (max-width: 850px) {
  .modal.reset-password-modal {
    .modal-content {
      min-height: 100vh;
    }
    .modal-dialog {
      max-width: none;
      width: 100%;
      margin: 0;
      .modal-header {
        padding: 36px 24px;
        .modal-title {
          .header.bold {
            font-size: 20px;
          }
          .header.light {
            font-size: 16px;
          }
        }
      }
      .modal-body {
        * {
          font-size: 14px;
        }
        padding: 34px 24px;
        .submit-button {
          margin-top: 50px;
          width: 100%;
          height: 56px;
          span {
            font-size: 18px;
          }
        }
        .btn > span {
          font-size: 14px;
        }
        .form-label {
          font-size: 16px !important;
          margin-bottom: 4px;
          font-size: 16px;
        }
        .form-control {
          height: 40px !important;
          padding-bottom: 0px !important;
        }
      }
    }
  }
}
