.nonMemberApplicationHistoryModal {
  .modal-dialog {
    max-width: 820px;
  }
  .modal-content {
    border-radius: 0;
    border: none;
  }
  .modal-dialog-centered {
    justify-content: center;
  }
  .modal-header {
    position: relative;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    padding: 64px 188px 30px 40px;
    .container {
      padding: 0;
    }
    p {
      word-break: keep-all;
      font-size: 32px;
      line-height: 45px;
      letter-spacing: -2px;
    }
    strong {
      word-break: keep-all;
      font-size: 32px;
      line-height: 45px;
      letter-spacing: -2px;
    }
    .close {
      position: absolute;
      top: 27px;
      right: 27px;
      height: 17px;
      line-height: 17px;
      padding: 0;
      margin: 0;
      text-shadow: none;
      outline: none;
      span {
        font-size: 28px;
      }
    }
    img {
      position: absolute;
      top: 68px;
      right: 48px;
    }
  }
  .modal-body {
    white-space: pre-line;
    padding: 0;
    min-height: 290px;
    .container {
      padding: 0;
    }
    .nonMemberApplicationHistoryContainerBodyVisitor {
      padding: 32px 48px 0;
    }
    .row {
      & + .row {
        margin-top: 6px;
      }
    }
    p {
      margin-bottom: 0px;
    }
    .form-row {
      margin: 0;
    }
    .form-label {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 32px;
      color: #000000;
    }
    .form-group {
      width: 100%;
    }
    .form-control {
      height: 45px !important;
      padding: 0;
      padding-bottom: 8px !important;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #979797;
      font-size: 18px;
      line-height: 32px;
      &:focus {
        box-shadow: none;
      }
      &.is-invalid {
        border-color: #ff0000;
        background: none;
      }
    }
    .invalid-feedback {
      margin-top: 0.25rem;
      font-size: 80% !important;
    }
    input {
      height: 45px;
      font-size: 18px;
      line-height: 32px;
    }
    .top-empty-space {
      margin-top: 40px !important;
    }
    .col-md-8 {
      padding: 0px;
    }
    .no-label-form-control {
      margin-top: -6px;
    }
    .component-display-none {
      display: none;
    }
    .comonent-display {
      display: inline;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-transition: background-color 9999s ease-out;
    }
    .certificationReRequest {
      display: block;
      cursor: pointer;
      height: 32px;
      opacity: 0.5;
      color: black;
      font-size: 16px;
      line-height: 32px;
      text-decoration: underline;
      margin-top: 5px;
    }
    .nonMemberApplicationHistoryContainerBodyHistory {
      left: 0px;
      top: 133px;
      background: rgba(216, 216, 216, 0.2);
      list-style: none;
      padding-top: 29px;
      padding-bottom: 29px;
      & > li {
        width: 100%;
        padding: 4px 40px;
      }
      .visitHistoryCard {
        background: #ffffff;
        border-radius: 4px;
        width: 100%;
        padding: 24px 28px;
        .row {
          flex-flow: row;
          flex-wrap: nowrap;
          margin-left: 0;
          margin-right: 0;
          .col {
            width: auto;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
      .card__header {
        margin-bottom: 1px;
        .card__header--place {
          flex: 1 1 auto;
          font-weight: bold;
          font-size: 18px;
          line-height: 28px;
          color: #333333;
        }
        .card__header--applydate {
          flex: 0 0 auto;
          font-size: 16px;
          line-height: 28px;
          color: #000000;
          opacity: 0.5;
        }
      }
      .card__host {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 28px;
        color: #000000;
      }
      .card__chip {
        height: 24px;
        margin-bottom: 16px;
        em {
          display: inline-flex;
          align-items: center;
          height: 24px;
          padding: 0 5px;
          border-radius: 4px;
          i {
            margin-right: 2px;
          }
          span {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            margin: 0;
          }
        }
        .card__chip--apply {
          border: none;
          background-color: #439099;
        }
        .card__chip--confirm {
          border: none;
          background-color: rgba(0, 0, 0, 0.5);
        }
        .card__chip--cancel {
          border: 1px solid #a50034;
          background-color: #ffffff;
        }
      }
      .card__msg {
        margin-bottom: 10px;
        .card__msg--default {
          margin: 0;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          opacity: 0.6;
        }
      }
      .card__period {
        label {
          padding-left: 0;
          margin: 0;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          opacity: 0.6;
        }
        span {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          opacity: 0.9;
        }
      }
      .card__member {
        margin-top: 4px;
        label {
          padding-left: 0;
          margin: 0;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          opacity: 0.6;
        }
        span {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          opacity: 0.9;
        }
      }
      .col-3 {
        padding: 0;
      }
    }

    .card__msg--alert {
      margin-top: 16px;
      margin-bottom: 0;
      font-size: 16px;
      color: #ff0000;
    }
    .card__msg--default {
      margin-top: 16px;
      margin-bottom: 0;
      font-size: 16px;
      color: #000000;
      opacity: 0.5;
    }
    .card__chip {
      display: flex;
      flex-flow: row;
    }
    .card__chip--confirm,
    .card__chip--cancel {
      display: flex;
      flex-flow: row;
      align-items: center;
      border-radius: 4px;
      height: 24px;
      padding: 0 5px;
      border: 1px solid;
      i {
        font-size: 16px;
      }
      span {
        font-style: normal;
        font-weight: bolder;
        font-size: 14px;
      }
    }
    .card__chip--apply {
      border-color: #439099;
      background-color: #439099;
      color: #ffffff;
      i {
        & + span {
          margin-left: 3px;
        }
      }
    }
    .card__chip--confirm {
      border-color: #7f7f7f;
      background-color: #7f7f7f;
      color: #ffffff;
      i {
        & + span {
          margin-left: 3px;
        }
      }
    }
    .card__chip--cancel {
      border-color: #a50034;
      background-color: #ffffff;
      color: #a50034;
      i {
        & + span {
          margin-left: 3px;
        }
      }
    }
    .noHistoryCard {
      display: flex;
      flex-flow: column;
      margin-top: 70px;
      align-items: center;
      justify-content: center;
      img {
        margin-bottom: 35px;
      }
      p {
        font-weight: bolder;
        font-size: 16px;
        color: #000000;
      }
      & + .drawer__list {
        display: none;
      }
    }
  }
  .modal-footer {
    border: none;
    margin: 0px;
    padding: 27px 48px 27px;
    justify-content: flex-start;
    .row {
      width: 100%;
      margin: 0;
    }
    button {
      width: 76px;
      height: 50px;
      margin: 0;
      padding: 11px 15px;
      background: #a50034;
      border-radius: 4px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
    }
    .btn.disabled,
    .btn:disabled {
      opacity: 0.1;
    }
    .footer-left {
      padding: 0;
      & > div {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        i {
          font-size: 20px;
          color: #439099;
        }
        span {
          margin-left: 5px;
          font-weight: bolder;
          font-size: 16px;
          color: #439099;
        }
      }
    }
    .footer-right {
      padding: 0;
      & > div {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        i {
          font-size: 20px;
          color: #439099;
        }
        span {
          margin-right: 5px;
          font-weight: bolder;
          font-size: 16px;
          color: #439099;
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .modal.nonMemberApplicationHistoryModal {
    .modal-content {
      width: 100%;
      min-height: 100vh;
    }
    .modal-title {
      * {
        font-size: 24px;
      }
      margin-top: 0px;
    }
    .modal-dialog {
      max-width: none;
      margin: 0;
      .modal-header {
        padding-right: 24px;
        padding-left: 24px;
        img {
          margin-top: 6px;
          right: 30px;
        }
      }
      .modal-body {
        * {
          font-size: 14px;
        }
        .nonMemberApplicationHistoryContainerBodyVisitor {
          padding: 34px 24px !important;
        }
        .nonMemberApplicationHistoryContainerBodyHistory {
          padding: 24px !important;
          min-height: 0;
          & > li {
            padding: 4px;
          }
          .visitHistoryCard {
            padding: 24px;
            width: 100%;
          }
          .card__period {
            label {
              font-size: 14px;
            }
            span {
              font-size: 14px;
            }
          }
          .card__member {
            label {
              font-size: 14px;
            }
            span {
              font-size: 14px;
            }
          }
          .col-3 {
            padding: 0;
          }
        }

        #findIdSubmitButton {
          width: 100%;
          height: 56px;
        }
        &:last-child {
          flex-wrap: wrap;
          #moveToLoginButton {
            width: 100%;
            height: 56px;
            margin-top: 20px;
            margin-right: 0px;
          }
          #resetPasswordButton {
            width: 100%;
            height: 56px;
            margin-top: 20px;
          }
        }
        .btn > span {
          font-size: 14px;
        }
        .form-label {
          font-size: 16px !important;
        }
        input {
          font-size: 14px !important;
        }
        .form-label {
          font-size: 16px !important;
          margin-bottom: 4px;
          font-size: 16px;
        }
        .form-control {
          height: 40px !important;
          padding-bottom: 0px !important;
        }
        .form-group {
          margin: 0;
        }
      }
      .modal-footer {
        padding-right: 24px;
        padding-left: 24px;
        button {
          width: 100%;
          height: 56px;
          font-size: 18px;
        }
      }
      .noHistoryCard {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        img {
          margin-bottom: 35px;
        }
        p {
          font-weight: bolder;
          font-size: 16px;
          color: #000000;
        }
        & + .drawer__list {
          display: none;
        }
      }
    }
  }
}
