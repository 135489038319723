.importedGoodsForm {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  & + .card {
    margin-top: 10px;
  }
  .card-header {
    padding: 12px 20px 10px;
    border: none;
    background: none;
    font-weight: bolder;
    font-size: 16px;
    #closeIcon {
      position: absolute !important;
      top: 12px !important;
      right: 17px !important;
    }
    i {
      position: absolute;
      top: 12px;
      right: 16px;
    }
  }
  .card-body {
    padding: 0 20px 0px;
    .input-group {
      height: auto !important;
      .form-control:focus {
        box-shadow: none;
      }
      min-height: 32px;

      padding-bottom: 5px !important;
      margin-bottom: 5px !important;
      border-bottom: 1px solid rgba(151, 151, 151, 0.3);
      &:last-child {
        border: none !important;
        padding-bottom: 0px !important;
      }
      .minusIcon {
        margin-right: 20px;
        cursor: pointer;
      }
      .plusIcon {
        margin-left: 20px;
        cursor: pointer;
      }
      .input-group-prepend {
        width: 128px;
        height: 32px;
        & + i.required {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          position: absolute;

          left: 120px;
          background-color: #ea4c89;
          z-index: 1;
        }
      }
      .portSealExceptGroup {
        border-bottom: 1px solid rgba(151, 151, 151, 0.3);
      }

      i.required.portSealExceptDescription {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: absolute;
        top: 40px;
        left: 120px;
        background-color: #ea4c89;
        z-index: 1;
      }

      input {
        height: 32px !important;
        font-size: 16px !important;
        &::placeholder {
          opacity: 0.5;
        }
      }
      input.importedGoodsCount {
        width: 50px;
        text-align: center;
        vertical-align: middle;
      }
      select {
        height: 32px !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-indent: 0px;
        padding-left: 0;
        background: url('../../../icons/arrow-select.svg') no-repeat 98% 50%;
        background-size: 8px;
      }

      .contentEdit {
        display: inline-block;
        overflow-y: auto;
        width: 262px;
        padding-top: 6px;
        padding-left: 0px;
        min-height: 32px;
        max-height: 160px;
        box-shadow: none;
        outline: none;
        border: none;
        resize: none;
        -webkit-writing-mode: horizontal-tb !important;
        text-rendering: auto;
        letter-spacing: normal;
        word-spacing: normal;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;

        text-align: left;
        -webkit-appearance: textfield;
        -webkit-rtl-ordering: logical;
        cursor: text;
        &::placeholder {
          opacity: 0.5;
        }

        &:disabled {
          color: inherit;
          background-color: transparent;
        }
      }

      .contentEdit.portSealExceptDescription {
        margin-left: 128px;
      }
      .input-group-text {
        width: 128px;
        padding: 0;
        background: none;
        border: none;
        font-size: 14px;
      }
      .custom-switch {
        .custom-control-label {
          top: 5px;
          &::before {
            background-color: #cccccc;
            border: none;
            box-shadow: none;
          }
          &::after {
            top: 7px;
            left: -33px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #ffffff;
            box-shadow: none;
          }
        }
        .custom-control-input:checked ~ .custom-control-label::before {
          background-color: #439099;
        }

        & + * {
          margin-left: 10px !important;
        }
      }
    }
    .input-group.required.error {
      border-bottom: 1px solid #ff0000 !important;
    }
    .dropdown {
      width: 262px;

      .dropdown-toggle {
        width: 100%;
        border: none;
        color: #000000;
        padding-left: 0;
        text-align: left;
        background: url('../../../icons/arrow-select.svg') no-repeat 98% 50%;
        background-size: 8px;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: normal;
        overflow: hidden;
        &:focus {
          box-shadow: none;
        }
        &:after {
          border: none;
        }
        &:active {
          color: #000000;
          background-color: transparent;
          border: none;
          box-shadow: none !important;
        }
        &::disabled {
          opacity: 0.5;
        }
      }
      .dropdown-item {
        padding: 7px 14px;
      }
      .dropdown-item:focus,
      .dropdown-item:hover {
        color: #16181b;
        text-decoration: none;
        background-color: rgba(#000000, 0.05);
      }
      & + .form-row {
        margin-bottom: 0;
      }
    }
    .importedGoodsPortSealExceptYesOrNo {
      .dropdown {
        width: 216px;
        .dropdown-toggle {
          width: 100%;
        }
      }
    }
    .dropdown-menu[x-placement^='bottom'] {
      padding: 0px;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.03), 0 6px 10px 2px rgba(0, 0, 0, 0.08);
      .custom-checkbox:focus,
      .custom-checkbox:hover {
        color: #16181b;
        text-decoration: none;
        background-color: rgba(#000000, 0.05);
      }
      .custom-checkbox {
        width: 100%;
        padding: 3.5px 14px 3.5px 38px;

        label {
          padding-left: 10px;
          height: 24px;
          color: rgba(#000000, 0.5);
          font-size: 14px;
          line-height: 24px;
          margin: 6px 0px;
        }

        .custom-control-input:checked ~ .custom-control-label::before {
          color: #fff;
          border-color: #439099;
          background-color: #439099;
        }
        .custom-control-input:active ~ .custom-control-label::before {
          color: #fff;
          border-color: #439099;
          background-color: #439099;
        }
        .custom-control-input:focus ~ .custom-control-label::before {
          box-shadow: 0 0 0 0.2rem rgba(#439099, 0.25);
        }
        .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
          border-color: #439099;
        }
      }
      .custom-checkbox.checked {
        label {
          color: #439099;
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  #importedGoodsForm {
    margin: 0 -5px 10px;
    .card-header {
      font-size: 14px;
    }
    .card-body {
      .input-group {
        margin-bottom: 8px !important;
        flex-wrap: nowrap;
        .input-group-prepend {
          & + i.required {
            left: 120px;
          }
        }
        input {
          font-size: 12px !important;
        }
        .dropdown {
          width: 60%;

          .dropdown-toggle {
            font-size: 12px;
            height: 32px;
          }
        }
        .contentEdit {
          width: 100%;
          font-size: 12px;
          padding-top: 7px;
        }
      }
    }
  }
}
