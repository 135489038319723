/************************************************************************
 * filename : quick-style.css
 * description : 퀵가이드 템플릿 css 
 * font : Notosans  
 * date : 2021.07.14
************************************************************************/
@charset "utf-8";

/* reset - reset css 있다면, 삭제 해도 됩니다. */
.quick-wrapper div, .quick-wrapper p, .quick-wrapper span,
.quick-wrapper button, .quick-wrapper a, 
.quick-wrapper h1, .quick-wrapper h2, .quick-wrapper h3,
.quick-wrapper ul, .quick-wrapper li,
.quick-wrapper dl, .quick-wrapper dt, .quick-wrapper dd,
.quick-wrapper ol,
.quick-wrapper strong,
.quick-wrapper em,
.quick-wrapper img{margin:0; padding: 0; border: 0; font-weight: normal;}
.quick-wrapper ol, .quick-wrapper ul, .quick-wrapper li {list-style: none;}
.quick-wrapper table {border-collapse: collapse; border-spacing: 0;}

/* quick-guide contents style : 220719 - 수정 */
.quick-pos {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
}

.quick-wrapper div, .quick-wrapper p, .quick-wrapper span,
.quick-wrapper button, .quick-wrapper a , 
.quick-wrapper h1, .quick-wrapper h2, .quick-wrapper h3,
.quick-wrapper ul, .quick-wrapper li{
	box-sizing: border-box;
}

.quick-wrapper {
	background-color: #EEFAFD;
	border-radius: 10px;
	width: 662px;
	height: 656px;
	box-sizing: border-box;
	color: #2C3238;
	line-height: 1.5;

	* {
		font-family: Noto Sans KR,맑은 고딕,Malgun Gothic,Dotum,굴림,arial,sans-serif;
	}
}
.quick-wrapper > .inner {position: relative; height: 100%; overflow: hidden;}
.quick-wrapper a {text-decoration: none;}
.quick-wrapper button {cursor: pointer; background: inherit; border:none ;padding:0; margin:0}
.quick-wrapper .blind {position: absolute;width: 1px;height: 1px;clip: rect(0 0 0 0);overflow: hidden;}
.quick-wrapper .btn-round {display:inline-block; width: 260px; height: 58px; line-height: 58px; font-weight: 500;
	font-size: 20px; background-color: #424952; border-radius: 29px; color: #fff; text-align: center; letter-spacing: -0.2px;}
.quick-wrapper .quick-today{margin-top: 16px;}
.quick-wrapper .btn-today {font-weight: 500; font-size: 18px; color:#ffffff;}
.quick-wrapper .btn-today::before {
	content: ' ';
	display: inline-block;
	width: 24px;
	height: 24px;
	background: url(../icons/icon-close.png) no-repeat 0 0;
	background-size: 100% 100%;
	vertical-align: middle;
	margin-top: -2px;
	margin-right: 8px;
}
.quick-wrapper .quick-close{position:absolute; top:20px; right:30px; height:30px;}
.quick-wrapper .btn-close {background: url(../icons/btn-close.png) no-repeat 0 0; width: 30px; height: 30px;}
.quick-wrapper .quick-header {height: 50px; padding: 20px 30px 0px;}
.quick-wrapper .quick-header > h2 {font-weight: 700; font-size: 20px; color: #333;}
.quick-wrapper .quick-conts {padding-top: 30px;}
.quick-wrapper .block {display: block;}
.quick-wrapper .title {
	font-weight: 400; 
	font-size: 22px; 
	line-height: 1.4; 
	text-align: center; 
	* {
		font-size: 22px;
	}
}
.quick-wrapper .title > strong {font-weight: 700;}
.quick-wrapper .rolling-wrap {width: 604px; margin: 0 auto; padding: 16px 0 32px 0;text-align: center;}
.quick-wrapper .rolling-item {width: 604px; margin: 0 auto; overflow: hidden;}
.quick-wrapper .item-img > img { margin: 0 auto; max-width: 100%;}
.quick-wrapper .item-info{margin-top:16px;}
.quick-wrapper .item-info dt {font-weight: 700;	font-size: 18px; line-height: 1.44;}
.quick-wrapper .item-info dd {margin-top:10px; font-size: 16px; color: #6B7682;}
.quick-wrapper .btn-action {text-align: center;}
/* slick  custom style */
.quick-wrapper .slick-slider {position: relative;}
.quick-wrapper .slick-slider .slick-prev {z-index:2; position: absolute; left: 0; top: 175px;}
.quick-wrapper .slick-slider .slick-next {z-index:2; position: absolute; right: 0; top: 175px;}
.quick-wrapper .slick-arrow {width: 50px; height: 50px; border-radius: 25px; background: #fff; border: 1px solid #DAE3ED;}
.quick-wrapper .slick-next::before{
	content: '';
	display: block;
	width: 11px;
	height: 18px;
	background: url(../icons/img-chevron-right.png) no-repeat 0 0;
	background-size: 100%;
	margin: 0 auto;
	vertical-align: middle;
}
.quick-wrapper .slick-prev::before{
	content: '';
	display: block;
	width: 11px;
	height: 18px;
	background: url(../icons/img-chevron-left.png) no-repeat 0 0;
	background-size: 100%;
	margin: 0 auto;
	vertical-align: middle;
}
.quick-wrapper .slick-dots {text-align:center; margin-top:0;}
.quick-wrapper .slick-dots li {display:inline-block; margin: 0 5px;}
// .quick-wrapper .slick-dots li > button {font-size:0; color:transparent; width:36px; height:5px; border-radius: 2.5px; background-color:#BEC9D4;}
// .quick-wrapper .slick-dots li.slick-active > button {background-color:#2C3238;}
