.ReservationBottomForNoneMember {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 60px;

  .bgGray {
    background-color: rgba(#000000, 0.05);
  }
  .bgPink {
    background-color: rgba(#f87077, 0.25);
  }
  .bottom-line {
    box-sizing: border-box;
    height: 1px;
    border: 1px solid hsla(0, 0%, 59.2%, 0.2);
    margin-left: 17px;
    margin-right: 17px;
    margin-bottom: 12px;
  }
  .mobile {
    min-width: 214px;
    display: none;
  }
  .noneMember-visit-info-header {
    cursor: pointer;
    .visitorInfo {
      .inputInfo {
        padding: 5px;
        border-radius: 3px;
        margin-right: 20px;
        border: solid 1px #a50034;
        color: white;
        background-color: #a50034;
        font-weight: bold;
      }
      .form-group {
        margin: 0 !important;
        padding: 0px 17px 10px;
        .badge {
          margin-right: 5px;
        }
      }
    }
    .visitorInfo.disabled {
      span {
        opacity: 0.5;
      }
    }
  }
  .noneMember-visit-info-body {
    width: auto;
    position: relative;
    flex-wrap: nowrap;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.5);

    .input-group {
      margin: 0 5px;
      width: auto;
      flex-wrap: nowrap;
      border-radius: 4px;
      padding: 8px 15px;
      color: rgba(0, 0, 0, 0.5);
    }
    .input-group.disabled {
      opacity: 0.5;
      input {
        background-color: transparent;
      }
    }
    .form-label {
      height: 24px;
      margin-left: 21px;
      margin-right: 21px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
      line-height: 24px;
    }
    .form-item {
      position: relative;
      display: flex;
      flex-flow: row;
      border-right: 1px solid rgba(151, 151, 151, 0.3);
      padding-right: 15px;
      margin-right: 15px;
      & + .form-item {
        & + .bottom-line {
          & + .form-item {
            & + .form-item {
              border-right: none;
            }
          }
        }
      }
      i {
        position: relative;
        top: 0;
        left: 0;
        width: 4px;
        height: 4px;
        background-color: #ea4c89;
        border-radius: 50%;
        flex: 0 0 auto;
        margin-right: 4px;
      }
      input {
        border: none;
        height: 28px;
        color: #000000;
        font-size: 20px;
        line-height: 28px;
        padding-left: 4px;
        // margin-left: 10px;
        flex: 1 1 auto;
        padding: 0px;
        &::placeholder {
          opacity: 0.3;
        }
        &::-webkit-input-placeholder {
          /* Edge */
          opacity: 0.3;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          opacity: 0.3;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      #userMobileInput {
        width: 160px;
      }
    }
    .clear-visitor-info {
      position: absolute;
      top: 5px;
      opacity: 0.5;
      right: 12px;
      width: 30px;
      height: 30px;
      padding: 3px;
      cursor: pointer;
    }
  }

  #internationalMobileInput {
    //border: none;
    width: 210px;
  }

  .PhoneInput {
    width: 100%;
    border: none !important;
  }

  .PhoneInputInput {
    width: 100%;
    outline: none;
    // margin-right: 24px !important;
  }

  .PhoneInputCountrySelect {
    width: 100% !important;
  }
}

@media only screen and (max-width: 850px) {
  .ReservationBottomForNoneMember {
    height: auto;
    padding: 16px 10px 16px 14px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 0 3px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    position: relative;
    .mobile {
      display: block;
    }
    div.inline.float-right {
      float: none !important;
      display: block;
      &:after {
        content: '';
        display: block;
        clear: both;
      }
      .inputInfo {
        margin-top: 7px;
        margin-left: 4px;
        line-height: 24px;
        display: inline-block;
        font-size: 12px;
      }
      .badge {
        float: left;
        margin-top: 12px;
        margin-right: 5px;
      }
      i:not(.addUser) {
        position: absolute;
        top: 15px;
        right: 0px;
        width: 24px;
        height: 24px;
        margin: 0;
        background: url(../../../icons/close-24px.svg) no-repeat center;
        opacity: 0.5;
        text-indent: -9999px;
      }
    }
    .bottom-line {
      border: none;
      background-color: #979797;
      opacity: 0.2;
    }
    .noneMember-visit-info-body {
      .form-item {
        width: 50%;
        flex: 1 1 auto;
        input {
          font-size: 16px;
          padding: 0 4px;
        }
      }
      .input-group {
        flex-flow: wrap;
        padding: 8px 0;
        .title.bottom-line.mobile {
          flex: 1 1 850px;
          width: 100%;
          padding: 0;
          margin: 15px 9px 5px 11px;
        }
        .form-item {
          border-right: 1px solid rgba(151, 151, 151, 0.3);
          flex: 0 0 122px;
          margin-left: 12px;
          margin-right: 5px;
          padding-right: 5px;
          i {
            margin-left: 9px;
          }
          input {
            margin-left: 0;
            min-width: 70px;
          }
          & + .form-item {
            border-right: none;
            margin-left: 0;
            flex: 1 1 auto;
            i {
              margin-left: 9px;
            }
            & + .bottom-line {
              & + .form-item {
                margin-top: 10px;
                margin-left: 14px;
                padding-left: 11px;
                flex: 1 1 auto;
                input {
                  margin-left: 5px;
                  // min-width: 125px;
                }
                & + .form-item {
                  border-right: none;
                  padding-top: 10px;
                  padding-left: 10px;
                  flex: 0 0 122px;
                  input {
                    margin-left: 8px;
                    max-width: 200px;
                  }
                }
              }
            }
          }
        }
        .clear-visitor-info {
          top: -80px;
          right: 0px;
        }
      }

      .mobile {
        min-width: 150px;
      }

      #internationalMobileInput {
        //border: none;
        width: 160px;
      }
    }
  }
}
