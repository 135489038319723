.popup-notice-modal {
  .modal-dialog {
    min-width: 600px;

    .modal-header {
      height: 100px;

      background-color: rgba(216, 216, 216, 0.2);
      border: none;
      .title {
        font-weight: bold;
        font-size: 24px;
        padding-top: 18px;
        padding-left: 25px;
        .registerDate {
          padding-left: 10px;
          color: #439099;
        }
      }
      .close {
        padding: 0px;
        margin: 0px;

        span {
          font-size: 28px;
        }
      }
      button {
        position: absolute;
        top: 27px;
        right: 27px;
        height: 18px;
        line-height: 18px;
        opacity: 1;
        margin: 0;
        padding: 0;
        span {
          font-size: 28px;
          color: #000000;
        }
      }
    }
    .modal-body {
      padding: 50px;
      padding-top: 10px;
      padding-bottom: 40px;
      .new-item-button {
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .col-md-auto {
        padding-right: 0px;
      }
      .row {
        margin-top: 20px;
        span {
          padding: 10px;
          padding-top: 0px;
          margin-bottom: 10px;
        }
        .form-check {
          position: relative;
          top: 18px;
          left: 30px;
        }
      }
    }

    .btn-ok {
      font-weight: bold;
      width: 70px;
      height: 40px;
      margin-top: 10px;
    }
  }
}
@media only screen and (max-width: 850px) {
  .popup-notice-modal {
    .modal-dialog {
      width: 100%;
      margin: 0px;
      min-width: 100% !important;
      .modal-content {
        height: 100%;
        border: none;
        border-radius: 0;
        height: 100%;

        .modal-header {
          border-bottom: 0px;
          height: 90px;

          .title {
            font-size: 20px !important;
            line-height: 24px !important;
            padding-left: 0px;
          }
        }
        .modal-body {
          padding: 15px 24px 20px !important;
          width: 100%;
          min-height: auto;
          .row {
            margin-top: 20px;
          }
          div {
            font-size: 14px;
            line-height: 28px;
            .registerDate {
              display: none;
            }
          }
          .col-md-auto {
            visibility: hidden !important;
            height: 0px;
          }
          .form-check {
            position: relative;
            top: 10px;
            left: 3px;
          }
        }
        .modal-footer {
          height: auto;
          padding: 16px 24px;
          button {
            padding: 0px;
            margin: 0px;
            width: 100%;
            height: 56px;
            font-size: 18px;
          }
        }
      }
    }
    .btn-ok {
      width: 100% !important;
      height: 56px !important;
      font-size: 18px;
    }
  }
}
