#reservationDrawer {
  width: 0;
  flex: 0 0 auto;
  transition: all 0.5s;
  .btnSearch {
    .btnSearchCondition {
      margin-left: 25px;
      border-radius: 0.25rem;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      line-height: 20px;
      background-color: transparent;
      color: #000000 !important;
      opacity: 0.5;
      border-color: #808080;
      border-width: 0.1em;
      box-shadow: none;
      &.on {
        margin-bottom: 30px;
      }
      img {
        text-align: center;
        margin-right: 5px;
      }
    }
  }
}

.drawer__wrapper {
  position: fixed;
  top: 0;
  left: auto;
  right: -400px;
  width: 435px;
  max-width: 435px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.02), 0 4px 6px 1px rgba(0, 0, 0, 0.06);
  overflow-y: auto;
  transition: right 0.5s;
  z-index: 11;
  overflow-x: hidden;
  .react-overlay-loader-spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-top: -30px;
    margin-left: -20px;
    border-radius: 50%;
    border: 3px solid #eee;
    border-top-color: #a50034;
    animation: react-overlay-loader-spinner 0.8s linear infinite;
  }
  .infiniteScrollLoader {
    margin-top: 10px;
    margin-left: 180px;
    border-radius: 50%;
    border: 3px solid #eee;
    border-top-color: #a50034;
  }
}

.drawer__header {
  position: relative;
  padding: 30px 25px 20px;
  h3 {
    font-weight: bolder;
    font-size: 26px;
  }
  .drawer__button--close {
    position: absolute;
    top: 35px;
    right: 28px;
    cursor: pointer;
  }
}

#rightSideLoading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  width: 400px;
  background: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 850px) {
  #reservationDrawer {
    width: 100%;
    height: 100%;
    position: absolute;

    .drawer__wrapper {
      width: 100%;
      padding-top: 95px;
      min-width: 100%;
      &.onSearchCondition {
        overflow: hidden;
      }
      .drawer__header {
        position: fixed;
        width: 100%;
        height: auto;
        top: 0;
        padding: 32px 80px;
        background-color: #ffffff;
        // border-bottom: 1px solid rgba(151, 151, 151, 0.4);
        -webkit-transform: translateZ(0);
        z-index: 10;
        h3 {
          font-size: 20px;
        }
        .drawer__button--close {
          display: inline-block;
          width: 24px;
          height: 24px;
          border: none;
          background-size: unset;
          position: absolute;
          top: 32px;
          left: 25px;
          padding: 0;
          background: url(../../icons/reservation/arrow_left.svg) no-repeat center;
          background-size: 24px;
          i {
            width: 24px;
            height: 24px;
            opacity: 0;
          }
        }

        .drawer__filter--wrapper {
          position: absolute;
          left: 32px;
          bottom: 32px;
          width: 200px;
          height: 32px;
          background: url('../../icons/reservation/filter.svg') no-repeat left 10px center, #ffffff;
          .drawer__filter--input {
            height: 100%;
            padding: 0 15px 0 30px;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.25);
            background: transparent;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
      .btnSearch {
        .btnSearchCondition {
          margin-bottom: 25px;
        }
      }
    }
  }
}
