.goodsDiv {
  border-radius: 4px;
  .table {
    table-layout: fixed;
  }
  th {
    border: none !important;
    width: 100%;
  }

  td {
    width: 100px;
  }
  .subtitle {
    opacity: 0.7;
  }

  tr td:nth-child(2) {
    font-weight: bolder;
  }

  td span {
    font-weight: bolder;
  }
  .product1stRow {
    border-top: none;
  }

  .card-body {
    padding: 10px 20px 0px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: rgba(196, 196, 196, 0.1);
  }
}

#parkingInfo {
  border-radius: 4px;
  .card-body {
    padding: 10px 20px 10px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: rgba(196, 196, 196, 0.1);
  }

  .disabled {
    opacity: 0.7;
  }
}

#importedGoodsDiv {
  border-radius: 4px;
  padding-bottom: 30px;
  .card-body {
    padding: 10px 20px 10px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: rgba(196, 196, 196, 0.1);
  }

  .disabled {
    opacity: 0.7;
  }
}

.carInfo {
  font-weight: bolder;
}

.qrHistoryInfo {
  .card-body {
    padding: 10px 20px 10px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: rgba(196, 196, 196, 0.1);
  }
}
