.qrcode-container {
    background-color: #f1f1f1;
    margin: 0;
    height: 100%;
    overflow: auto;
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .kiosk-col {
      display: flex;
      justify-content: center;
    }
    .visit-image-area {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #a50034;
      border-radius: 100px;
      width: 70px;
      height: 70px;
    }
    .reservation-image-area {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #000000;
      border-radius: 100px;
      width: 70px;
      height: 70px;
    }
    .visit-qr {
      width: 39px;
      height: 40px;
    }
    .reservation-qr {
      width: 55px;
      height: 55px;
    }
    .visit-qr-title {
      color: #a50034;
    }
    .count{
      padding-top: 35px;
      text-align: center;
      p {
        font-size: 15px;
        line-height: normal;
        color: #2c3238;
      }
      strong {
        font-size: 18px;
        color: #a50034;
      }
    }
    .header-row {
      width: 100%;
      height: 80px;
      padding-top: 30px;
      text-align: center;
      .col {
        font-weight: 600;
        font-size: 25px;
      }
    }
    .middle-row {
      width: 100%;
      margin: 0;
      padding: 0;
    
      .middle-col {
        margin: 0;
        padding: 0;
        height: fit-content;
        text-align: center;
        img {
          margin-top: 30px;
          width: 180px;
          height: 180px;
        }
        .kiosk-contents {
          font-weight: normal;
          color: #2c3238;
          letter-spacing: -0.07px;
        }
        .qr-expire {
          position: absolute;
          display: inline-block;
          text-align: center;
          cursor: pointer;
          margin-top: 30px;
          width: 180px;
          height: 180px;
          background-color: rgba($color: #fff, $alpha: 0.96);
          border-style: solid;
          border-color:#a50034;
          color: #56606b;
          opacity: 0.9;
          font-size: 22px;
          font-weight: bold;
          padding-top: 10%;

          .send-kko {
            display:flex;
            justify-content: center;
            font-size: 15px;
            font-weight: normal;
            padding-top: 5%;
            padding-bottom: 5%;
            margin-top: 5%;
            width: 60%;
            margin-left: 20%;
            background-color: #56606b;
            color: white;

            div {
              font-size: 18px;
              font-weight: bold;
            }
          }

          .send-kko-limit {
            padding-top: 10%;
            color: #a50034;
          }
        }
      }
      .content-row {
        .content-col {
          margin: 21px 32px 90px 32px !important;
        }
        .notice-content {
          padding: 0px;
        }
      }
    }
    .bottom-row {
      width: 100%;
      margin: 0;
      padding: 0;
      .bottom-col {
        width: 100%;
        padding: 0;
        div.bottom {
          text-align: center;
          height: fit-content;
          padding-top: 30px;
          div {
            font-style: normal;
            font-size: 12px;
            line-height: 28px;
            white-space: pre-line;
            color: #000000;
            mix-blend-mode: normal;
            opacity: 0.6;
            p {
              display: inline-block;
              position: relative;
              margin: 0;
            }
            strong {
              font-size: 12px;
            }
          }
          strong.name-strong {
            font-size: 12px;
            color: #000000;
            opacity: 1;
          }
        }
      }
    }
  }
  