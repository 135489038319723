.workplaceAlertModal {
  &.modal {
    background-color: rgba(#000000, 0.7);
  }
  .modal-dialog {
    max-width: 550px !important;
    max-height: 316px;
  }
  .modal-content {
    padding: 40px;
    background-color: #ffffff !important;
    border: none;
    .modal-header {
      font-size: 20px;
      line-height: 1.2;
      color: #000000;
      padding: 0px;
      border: none;
      p {
        * {
          font-size: 20px;
        }
      }
      b {
        margin-right: 5px;
      }
    }
    #nonEmpUrlModalContentContainer {
      height: 112px;
      background-color: rgba(0, 0, 0, 0.05);
      font-size: 16px;
      line-height: 1.5;
      padding: 20px;
      color: #000000;
      #out-link {
        text-decoration: underline;
      }
    }

    #nonEmpUrlModalButtonContainer {
      padding: 30px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      border: none;

      button {
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        margin: 0px;
        margin-left: 8px;
        height: 50px;
        padding: 11px 15px;
        .material-icons {
          font-size: 24px;
          line-height: 1;
          margin-right: 11px;
          vertical-align: sub;
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .workplaceAlertModal {
    #nonEmpUrlModalContentContainer {
      height: auto !important;
    }
    .modal-footer {
      button {
        font-size: 18px !important;

        .material-icons.md-24 {
          font-size: 24px;
        }
      }
    }
  }
}
