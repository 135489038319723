#HistorySearchConditions {
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 4px 6px 1px rgba(0, 0, 0, 0.1);
  margin: 5px 25px;
  width: auto;
  div.searchCond {
    padding-left: 24px;
    .row {
      width: 100%;
      & > span {
        padding-top: 30px;
        padding-right: 10px;
        font-size: 16px;
        line-height: 20px;
        &.applyDate {
          font-size: 14px;
          color: #757575;
        }
      }
    }
    .closeRow {
      display: none;
    }
  }
  .statusCode {
    margin-top: 8px;
    border: none;
    box-shadow: none;
    position: relative;
    max-width: 100%;
    .btn-group.btn-group-toggle {
      display: inline-block;
      label {
        border-radius: 0.25rem;
        font-size: 14px;
        line-height: 20px;
        margin: 6px 5px 0 0;
        padding: 6px 16px;
        background-color: transparent;
      }
      label.focus {
        box-shadow: none;
      }
      label.active {
        box-shadow: none;
      }
    }
    .btn-group.btn-group-toggle.outline-gray {
      label {
        color: #808080 !important;
        border-color: #808080;
      }
      label.active {
        color: #439099 !important;
        border-color: #439099;
      }
    }
  }
  .form-item {
    width: 100%;
    .userNameInput, .visitorNameInput {
      padding: 0.1rem;
      outline: 0;
      border-width: 0 0 1px;
      border-radius: 0;
      border-color: #000000;
      min-width: inherit;
      font-size: 14px;
      line-height: 20px;
      box-shadow: none;
    }
  }
  .btnReset {
    margin: 100px 7px 36px 0;
    border-radius: 0.25rem;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff !important;
    font-weight: bold;
    width: 76px;
    height: 40px;
    box-shadow: none;
  }
  .btnSearch {
    margin-top: 100px;
    margin-bottom: 36px;
    border-radius: 0.25rem;
    line-height: 24px;
    width: 104px;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    box-shadow: none;
    & > img {
      margin-right: 12px;
    }
  }
}

@media only screen and (max-width: 850px) {
  #HistorySearchConditions {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 11;
    top: 0;
    left: 0;
    margin: 0;
    padding-left: 0px;
    padding-right: 0px;
    min-width: 100%;
    .VisitPeriodCalendar {
      position: relative;
      .btn-group {
        margin-left: 0px !important;
      }
    }
    &.showCalendar {
      height: 0px;
      .calendar {
        // display: block !important;
        background-color: rgba(0, 0, 0, 0.2);
      }
      .calendarType {
        position: fixed;
        bottom: 360px;
        left: 0;
        width: 100%;
        height: 70px;
        margin: 0;
        background-color: #ffffff;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        z-index: 12;
        .btn {
          display: none;
        }
      }
    }
    div.searchCond {
      position: fixed;
      background-color: #ffffff;
      padding-left: 24px;
      padding-right: 24px;
      bottom: 0px;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      min-width: 100%;
      .row {
        width: 100%;
        margin: 0;
        & > span {
          padding-top: 30px;
          padding-right: 10px;
          font-size: 16px;
          line-height: 20px;
          &.applyDate {
            font-size: 14px;
            color: #757575;
          }
        }
      }
      .closeRow {
        display: flex;
        justify-content: center;
        span {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.4);
          padding: 0px;
          margin-bottom: 18px;
        }
      }
    }
    .calendar {
      display: none;
      background-color: transparent;
      &.open {
        display: block;
      }
    }
    .calendarType {
      position: relative;
      border: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-color: transparent;
      bottom: 0px;
      margin-top: 8px;
      height: auto;
      .btn-group {
        position: relative;
        top: 0;
        left: 0;
      }
    }
    .statusCode {
      margin-top: 8px;
      border: none;
      box-shadow: none;
      position: relative;
      max-width: 100%;
      .btn-group.btn-group-toggle {
        display: inline-block;
        label {
          border-radius: 0.25rem;
          font-size: 14px;
          line-height: 20px;
          margin: 6px 5px 0 0;
          padding: 6px 16px;
          background-color: transparent;
        }
        label.focus {
          box-shadow: none;
        }
        label.active {
          box-shadow: none;
        }
      }
      .btn-group.btn-group-toggle.outline-gray {
        label {
          color: #808080 !important;
          border-color: #808080;
        }
        label.active {
          color: #439099 !important;
          border-color: #439099;
        }
      }
    }
    .form-item {
      width: 100%;
      .userNameInput, .visitorNameInput {
        padding: 0.1rem;
        outline: 0;
        border-width: 0 0 1px;
        border-radius: 0;
        border-color: #000000;
        min-width: inherit;
        font-size: 14px;
        line-height: 20px;
        box-shadow: none;
      }
    }
    .btnReset {
      margin: 32px 7px 18px 0;
      border-radius: 0.25rem;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff !important;
      font-weight: bold;
      width: 76px;
      height: 56px;
      box-shadow: none;
      flex: 1 1 115px;
    }
    .btnSearch {
      margin-top: 32px;
      margin-bottom: 18px;
      border-radius: 0.25rem;
      line-height: 24px;
      width: 84px;
      height: 56px;
      font-size: 18px;
      font-weight: bold;
      box-shadow: none;
      flex: 1 1 188px;
      & > img {
        margin-right: 12px;
      }
    }
  }
}
