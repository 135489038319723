.reservation-modal {
  .modal-dialog {
    .bold {
      font-weight: 900;
    }
    .title {
      font-size: 22px;
      color: #000000;
    }
    .RMB15 {
      margin-bottom: 15px;
    }
    min-width: 844px;
    .modal-content {
      width: 844px;
      .modal-header {
        border-bottom: 0px;
        padding: 14px 30px 19px 30px;
        button {
          opacity: 1;
          span {
            font-size: 28px;
            color: #000000;
          }
        }
      }
      .modal-body {
        padding: 0px !important;
        .visitToTargetContainer {
          padding: 0px 63px 50px 63px;
          .wLogo {
            margin-right: 10px;
            opacity: 0.4;
          }
          .top-title-row {
            margin-bottom: 13px;
          }
          .modal-title {
            font-size: 30px;
            font-weight: bold;
            color: #000000;
            white-space: pre-line;
          }
          .table {
            max-width: 500px;
            float: left;
            margin-bottom: 0px !important;
            .row {
              margin-bottom: 3px;
            }
            .left-label {
              max-width: 78px;
              padding: 0px;
              color: rgba(#000000, 0.6);
            }
            .top-companyName-val {
              margin-right: 5px;
            }
            .top-status-val {
              color: #ffffff;
              font-size: 14px;
              border-radius: 4px;
              padding: 4px 6px;
              margin-right: 20px;
              background-color: #439099;
              .material-icons {
                font-size: 14px;
                vertical-align: middle;
                margin-bottom: 2px;
              }
            }
            .top-status-val.approve-done {
              color: #ffffff;
              font-size: 14px;
              border-radius: 4px;

              margin-right: 20px;
              background-color: #000000;
              opacity: 0.5;
              .material-icons {
                font-size: 14px;
                vertical-align: middle;
                margin-bottom: 2px;
              }
            }
            .top-status-val.reject {
              box-sizing: border-box;

              margin-right: 20px;
              border: 1px solid #a50034;
              color: #a50034;
              border-radius: 4px;
              background-color: #ffffff;
              .material-icons {
                font-size: 14px;
                vertical-align: middle;
                margin-bottom: 2px;
              }
            }
            .top-status-val.cancel {
              box-sizing: border-box;

              margin-right: 20px;
              border: 1px solid #7f7f7f;
              color: #7f7f7f;
              border-radius: 4px;
              background-color: #ffffff;
              .material-icons {
                font-size: 14px;
                vertical-align: middle;
                margin-bottom: 2px;
              }
            }
            .top-status-val.temp-save {
              box-sizing: border-box;

              margin-right: 20px;
              border: solid 1px #000000;
              background-color: rgba(0, 0, 0, 0);
              color: #000000;
              border-radius: 4px;
              opacity: 0.5;
            }
            .opinion-col {
              margin-right: 5px;
              max-width: 500px;
              display: inline-block;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              word-break: break-all;
            }
          }
          .load {
            position: absolute;
            right: 25px;
            bottom: 0px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .modal-dialog {
    .title {
      font-size: 16px !important;
    }
    .modal-content {
      .modal-header {
        button {
          span {
            font-size: 24px;
          }
        }
      }
      .modal-body {
        .visitToTargetContainer {
          .modal-title {
            font-size: 24px !important;
          }
          .table {
            .top-status-val {
              font-size: 12px !important;

              .material-icons {
                font-size: 12px !important;
              }
            }
            .top-status-val.approve-done {
              font-size: 12px !important;
              .material-icons {
                font-size: 12px !important;
              }
            }
            .top-status-val.reject {
              .material-icons {
                font-size: 12px !important;
              }
            }
          }
          .copyIcon {
            width: 22px;
            height: 26px;
            bottom: 10px !important;
          }
        }
      }
    }
  }
}
