.reservation-modal {
  .modal-dialog {
    .bold {
      font-weight: 900;
    }
    .title {
      font-size: 22px;
      color: #000000;
    }
    .RMB15 {
      margin-bottom: 15px;
    }
    .divBgGray {
      background-color: rgba(#d8d8d8, 0.2);
      max-height: 455px;
      overflow: auto;
    }
    min-width: 844px;
    .modal-content {
      width: 844px;
      .modal-header {
        border-bottom: 0px;
        padding: 14px 30px 19px 30px;
        button {
          opacity: 1;
          span {
            font-size: 28px;
            color: #000000;
          }
        }
      }
      .modal-body {
        padding: 0px !important;
        .visitorsContainer {
          padding: 0 63px 10px 63px;
          .visitor-title-row {
            margin: 22px 0 15px 0;
            .col {
              padding-left: 0px;
            }
          }
          .visitor-title {
            margin-right: 5px;
            margin-bottom: 5px;
          }
        }
        .btn-toolbar {
          padding: 25px 63px 25px 63px;
          button {
            margin-right: 10px !important;
          }
        }
      }
    }
  }
}
.detailsDiv {
  .qrcodeHistory {
    font-weight: bold;
    border-top: none;
    border-bottom: 1px solid #dee2e6;
  }
  .subtitle {
    opacity: 0.7;
    font-weight: normal;
  }
}

