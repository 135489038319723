.ReservationBottomVisitor {
  height: 63px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 12px;

  .bgGray {
    background-color: rgba(#000000, 0.05);
  }
  .bgPink {
    background-color: rgba(#f87077, 0.25);
  }
  .pointer {
    cursor: pointer;
  }
  .visitorInfo.disabled {
    .form-group {
      :not(.float-right):not(.material-icons) {
        opacity: 0.5;
      }
    }
  }
  .inputInfo {
    color: rgba(#000000, 0.5);
    font-size: 14px;
    line-height: 20px;
    margin-right: 12px;
  }
  .form-group {
    margin: 0px !important;
    padding: 10px 17px 10px 10px;
    label {
      margin: 0px;
      vertical-align: top;
    }
    label.visitor {
      font-weight: bold;
      margin-left: 10px;
    }
    label.position {
      font-weight: bold;
    }
    .visitorBaseInfo {
      margin-left: 0px !important;

      vertical-align: top;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: normal;
      overflow: hidden;
      max-width: 380px;
    }
    .badge {
      font-size: 14px;
      border-radius: 4px;
      margin-right: 5px !important;
      line-height: 1.1;
      padding-top: 5px !important;
    }
    .badge.me {
      background-color: #ffffff;
      border: 1px solid #000000;
      color: #000000;
      padding: 5px;
    }
    .badge.lg {
      background-color: #ffffff;
      border: 1px solid #000000;
    }
    .badge.member {
      color: #00aed6;
      background-color: #ffffff;
      border: 1px solid #00aed6;
    }
    .badge.none-member {
      color: #ff0000;
      background-color: #ffffff;
      border: 1px solid #ff0000;
    }
    .material-icons {
      margin-left: 15px;
      vertical-align: middle;
    }
  }
}
.ReservationBottomVisitor.disabled {
  opacity: 0.3;
  cursor: not-allowed !important;
  * {
    cursor: not-allowed !important;
  }
}

@media only screen and (max-width: 850px) {
  .ReservationBottomVisitor {
    height: auto;
    padding: 16px 10px 16px 14px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 0 3px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    .visitorInfo {
      position: relative;

      & > .form-group {
        padding: 0;
        .badge {
          width: auto;
          height: 24px;
          line-height: 24px;
          padding: 0 3px !important;
          font-size: 12px;
        }
        label {
          &.name {
            margin: 0;
          }
        }
        .material-icons {
          &.addUser {
            margin-left: 0;
            position: absolute;
            top: 0px;
            right: 0;
          }
        }
        div.inline.float-right {
          float: none !important;
          display: block;
          &:after {
            content: '';
            display: block;
            clear: both;
          }
          .inputInfo {
            margin-top: 7px;
            margin-left: 4px;
            line-height: 24px;
            display: inline-block;
            font-size: 12px;
          }
          .badge {
            float: left;
            margin-top: 7px;
          }
          i:not(.addUser) {
            position: absolute;
            top: 15px;
            right: 0px;
            width: 24px;
            height: 24px;
            margin: 0;
            background: url(../../../icons/close-24px.svg) no-repeat center;
            opacity: 0.5;
            text-indent: -9999px;
          }
        }
      }
      & {
        .visitorBaseInfo {
          width: 100%;
        }
        label + label.visitorBaseInfo {
          width: calc(100% - 55px);
          font-size: 16px;

          strong {
            font-size: 16px;
          }
        }
      }
      .arrow_button {
        position: absolute;
        top: 20px;
        right: 0px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.arrow_button {
  border: none;
  background: none;
}
.arrow_button:focus {
  outline: none;
}
