.layouts {
  .navbarTop {
    height: 64px;
    background-color: transparent;
    transition: background-color 0.5s;
    z-index: 11;
    .navbar-nav {
      margin-right: 62px;
    }
    .notifierBadge {
      position: absolute;
      padding: 0px;
      margin-left: 16px;
      width: 16px;
      height: 16px;
      font-size: 12px;
      font-weight: bold;
      line-height: 18px;
      text-align: center;
    }
    .navbar-nav {
      a {
        color: #000000;
        margin-left: 20px;
        padding: 0;
        width: 40px;
        height: 40px;
        & > div {
          text-align: center;
          i {
            line-height: 40px;
          }
              i.managementPage{
                color: #439099;
              }
        }
      }
      a:first-child {
        margin-left: 0px;
      }
      a.userInfo {
        padding: 0px;
      }
    }
    .profile-dropdown {
      height: 40px;
      width: 68px;
      margin-left: 20px;
      border-radius: 20px;
      cursor: pointer;
      & > div {
        display: flex;
        flex-flow: row;
        align-items: center;
        height: 40px;
        padding: 0 4px;
      }
      &:hover {
        background-color: #ffffff;
      }
      .employee-member {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 38px;
        text-indent: -9999px;
        border-radius: 50%;
        background: url('../icons/reservation/user-normal.svg') no-repeat center, #a50034;
        & + i {
          margin-left: 1px;
          font-size: 28px;
        }
      }
      .default-member {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 38px;
        text-indent: -3px;
        color: #439099;
        & + i {
          margin-left: 1px;
          font-size: 28px;
        }
      }
    }
    .nonmemberLogout {
      button {
        background-color: rgba(0,0,0,0);
        border: none;
        display: flex;
        align-items: center;
        height: 40px;
        & + button {
          margin-left: 8px;
        }
        span {
          margin-left: 3px;
        }
        span:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .navbarTopSinglex {
    .d-flex {
      margin-top: 40px;
    }
  }
}
.employee-member {
  color: #a50034 !important;
}
.default-member {
  color: #439099 !important;
}
.profile-popover {
  &.popover {
    width: auto;
    border: none;
    margin-top: 5px;
  }
  .arrow {
    display: none !important;
  }
  .popover-body {
    padding: 0 0 0 0 !important;
  }
}

.profile-card.card {
  min-height: 255px;
  min-width: 240px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.03), 0 6px 10px 2px rgba(0, 0, 0, 0.08);
  padding: 20px 0 15px;
  .card-header {
    border: none;
    background-color: #ffffff;
    .employee-avatar-icon {
      width: 56px;
      height: 56px;
      line-height: 56px;
      text-indent: -9999px;
      border-radius: 50%;
      background: url('../icons/reservation/user.png') no-repeat center, #a50034;
    }
    .default-avatar-icon {
      width: 56px;
      height: 56px;
      line-height: 56px;
      text-indent: -9999px;
      border-radius: 50%;
      background: url('../icons/reservation/user.png') no-repeat center, #439099;
    }
  }
  .card-body {
    padding: 0;
    padding-bottom: 20px;
    border: none;
    background-color: #ffffff;
    .card-title {
      height: 24px;
      margin-bottom: 6px;
      color: #000000;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
    }
    .card-text {
      height: 20px;
      color: rgba(#000000, 0.5);
      font-size: 14px;
      line-height: 20px;
    }
  }
  .card-footer {
    padding-bottom: 6px;
    border: none;
    background-color: #ffffff;
    .profileEdit {
      width: 109px;
      height: 32px;
      border: none;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.05);
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 14px;
    }

    .row {
      justify-content: center;
    }
    .logout {
      min-width: 46px;
      height: 16px;
      padding: 0;
      background-color: #ffffff;
      border-color: #ffffff;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      line-height: 16px;
    }
  }
}
.layouts.background {
  .navbarTop {
    background-color: #f1f1f1;
    transition: background-color 0.5s;
  }
}

@media only screen and (max-width: 850px) {
  body > .tooltip {
    display: none;
  }
  .layouts {
    .navbarTop {
      padding-top: 15px;
      // background: url('../icons/reservation/logo--mobile.svg') no-repeat left 34px top 22px, #ffffff;
      .navbar-nav {
        margin-right: 0;
        a {
          margin-left: 2px;
        }
      }
      .profile-dropdown {
        margin-left: 6px;
        .employee-member {
          text-indent: -9999px;
          border-radius: 50%;
          background: url('../icons/reservation/user-normal.svg') no-repeat center, #a50034;
        }
      }
    }
    .navbarTopLG {
      background: url('../icons/reservation/logo--mobile.svg') no-repeat left 34px top 22px, #ffffff;
    }
    .navbarTopSinglex {
      .d-flex {
        margin-top: 40px;
      }
    }
    &.background {
      .navbarTopLG {
        background: url('../icons/reservation/logo--mobile.svg') no-repeat left 34px top 22px, #ffffff;
      }
    }
  }
  .profile-card {
    &.card {
      .card-header {
        .employee-avatar-icon {
          text-indent: -9999px;
          border-radius: 50%;
          background: url('../icons/reservation/user.png') no-repeat center, #a50034;
        }
        .default-avatar-icon {
          text-indent: -9999px;
          border-radius: 50%;
          background: url('../icons/reservation/user.png') no-repeat center, #439099;
        }
      }
    }
  }
}
