.sendMobileAuth {
  padding: 15% 10% 0 10%;  
  .row {
    border-width: 1px;
    border-style: solid;
    border-color: #757575;
    border-radius: 4px;
  }
  #PhoneCertification {
    margin-top: 10px;
    .form-row .form-group {
      font-size: 20px;
    }
    @media only screen and (max-width: 850px) {
      .form-row .form-group {
        font-size: 20px;
      }
    }
    #certificationKeyRequestButton {
      display: none;
    }
  }
}
.reservationInfoByQrcodeContainer {
  display: flex;
  justify-content: center;
  padding: 0;
  width: 900px;
  .reservationInfoCard {
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    .card__header {
      display: flex;
      .card__header--place {
        flex: 1 1 auto;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: #333333;
      }
    }
    .row {
      flex-flow: row;
      flex-wrap: nowrap;
      margin-left: 0;
      margin-right: 0;
      .col {
        width: auto;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .card__host {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 28px;
      color: #000000;
    }
    .card__msg {
      margin-bottom: 10px;
      .card__msg--default {
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        opacity: 0.6;
      }
    }
    .card__period {
      label {
        padding-left: 0;
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        opacity: 0.6;
      }
      span {
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        opacity: 0.9;
      }
    }
    .card__member {
      margin-top: 4px;
      label {
          padding-left: 0;
          margin: 0;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          opacity: 0.6;
      }
      span {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          opacity: 0.9;
      }
    }
    .card__chip {
      height: 24px;
      margin-bottom: 16px;
      color: #ffffff;
      em {
        display: inline-flex;
        align-items: center;
        height: 24px;
        padding: 0 5px;
        border-radius: 4px;
        i {
          margin-right: 2px;
        }
        span {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          margin: 0;
        }
      }
      .card__chip--apply {
        border: none;
        background-color: #439099;
      }
      .card__chip--confirm {
        border: none;
        background-color: rgba(0, 0, 0, 0.5);
      }
      .card__chip--cancel {
        border: 1px solid #a50034;
        background-color: #ffffff;
      }
    }
  }


  .infoTitle {
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-bottom: 15px;
  }
  .tableArea {
    width: 100%;
    .table {
      height: 392px;
      padding: 10px 10px 10px 46px;
      border-radius: 10px;
      border: solid 2px #bec9d4;
      background-color: #56606b;
      margin-bottom: 55px;
      border-collapse: separate;

      tr {
        border: none;
      }
      td {
        border-radius: 10px;
        background-color: #ffffff;
        vertical-align: initial;
        color: #2c3238;
        font-size: 30px;
      }

      .title {
        color: white;
        font-weight: bold;
        background-color: #56606b;
      }
    }
    .title {
      background-color:rgba(38, 50, 56, 0.05);
      font-size: 25px;
    }
    .value {
      margin: 10px 0 10px 0;
    }
  }

  .react-bootstrap-table table {
    table-layout: auto;
    height: 10%;
  }

  .table-bordered tr {
    border-left: 0;
    border-right: 0;
  }
  .table-bordered th {
    background-color: rgba(38, 50, 56, 0.05);
    border-left: none;
    border-right: none;
  }

  .table-bordered td {
    font-size: 14px;
    border-left: none;
    border-right: none;
  }

  .host-name {
    padding-bottom: 0px !important;
  }

  .small-font {
    font-size: 30px;
    font-weight: bold;
    padding-left: 46px;
    padding-bottom: 36px;
  }

  .grey-font {
    color: #757575;
  }

  .pagination {
    float: right !important;
  }

  .page-item .page-link {
    z-index: 3;
    color: #757575;
    background-color: #fff;
    border-color: #757575;
  }
  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #757575;
    border-color: #757575;
  }
  .badge {
    font-size: 14px;
    color: white;
    &.substituteYes {
      background-color: #439099;
    }
    &.substituteNo {
      background-color: #a50034;
    }
  }
  .name-input-group-row {
    margin: 0px;
    margin-top: 15px;
    .name-input-group {
      background-color: rgba(38, 50, 56, 0.05);
      margin-right: 20px;
      max-width: 200px;
      border-radius: 0.25rem;
      height: 32px;
      padding: 12px;
      padding-top: 6px;
      padding-bottom: 6px;
      .form-row {
        margin: 0px;
        .user-type-label {
          height: 32px;
          max-width: 67px;
        }
      }
      .name-input-control {
        height: 24px;
        padding: 0px !important;
        margin: 0px !important;
        border: none;

        background-color: transparent;
        border-radius: 0px !important;
        border-bottom: 1px solid;
        max-width: 150px;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .no-search-result {
    text-align: center;
    padding-top: 20px;

    .table-bordered {
      margin-bottom: 0px;
    }
    p {
      background-color: rgba(38, 50, 56, 0.03);
      padding: 50px;
      img {
        margin-right: 5px;
        margin-left: -20px;
      }
    }
  }
  .drawer__chip--confirm,
  .drawer__chip--cancel,
  .drawer__chip--request,
  .drawer__chip--selfcancel {
    display: flex;
    flex-flow: row;
    align-items: center;
    border-radius: 10px;
    height: 60px;
    width: fit-content;
    padding: 0 5px;
    background-color: #e0205c;
    color: #ffffff;
    margin: 23px 30px 4px 46px;
    i {
      font-size: 30px;

      & + span {
        margin-left: 3px;
      }
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      margin-right: 30px;
    }
  }

  .drawer__chip--confirm {
    background-color: #439099 !important;
  }

  .approvalConfirm {
    margin-top: 36px;
    margin-bottom: 12px;
    span {
      font-size: 30px;
      color: #e0205c;
      font-weight: bold;
      margin-left: 46px;
    }
  }

  .forbidIcon, .confirmIcon {
    margin: 0 13px 0 23px;
  }
  // .drawer__chip--request {
  //   border-color: #439099;
  //   background-color: #439099;
  //   color: #ffffff;
  // }
  // .drawer__chip--confirm {
  //   border-color: #7f7f7f;
  //   background-color: #7f7f7f;
  //   color: #ffffff;
  // }
  // .drawer__chip--cancel {
  //   border-color: #a50034;
  //   background-color: #ffffff;
  //   color: #a50034;
  // }
  // .drawer__chip--selfcancel {
  //   border-color: #7f7f7f;
  //   background-color: #ffffff;
  //   color: #7f7f7f;
  // }
  .drawer__qrcode--request {
    display: flex;
    flex-flow: row;
    align-items: center;
    border-radius: 4px;
    height: 30px;
    width: fit-content;
    padding: 0 5px;
    border: 1px solid;
    border-color: #a50034;
    background-color: #ffffff;
    color: #a50034;
    i {
      font-size: 14px;
    }
    span {
      font-style: normal;
      font-weight: bolder;
      font-size: 14px;
    }
  }
  .qr-request {
    text-align: center;
    Button:not(#KioskMain) {
      width: 100%;
      height: 120px;
      padding: 0;
      justify-content: center;  
      border-radius: 10px;
      font-size: 50px;
      color: #424952;
      font-weight: bold;
      background-color: #e9eff5;
      opacity: 1;

      &.invalid {
        opacity: 0.2;
      }
    }
  }
  .qr-limit-exceed, .qr-limit-ok {
    color: #bec9d4;
    text-align: center;
    margin-top: 35px;
    font-size: 30px;
    span {
      font-size: 30px;
    }
    .exceed-highlight {
      color: #e0205c;
      font-weight: bold;
    }
    .ok-highlight {
      color: #ffffff;
      font-weight: bold;
    }
  }
}