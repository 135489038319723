#EmailCertification {
  width: 100%;
  .container {
    margin-top: 52px;
    margin-left: 5px;
    .emailAddressLabel {
      opacity: 0.6;
      color: black;
      font-size: 18px;
      line-height: 21px;
      height: 21px;
    }
    .emailAddress {
      height: 27px;
      color: black;
      font-size: 22px;
      font-weight: bold;
      line-height: 27px;
      margin-top: 10px;
      margin-bottom: 58px;
    }

    .row {
      .col-sm-1 {
        padding: 0px;
      }
      .col-sm-11 {
        padding: 0px;
        p {
          height: 42px;
          color: black;
          font-size: 18px;
          line-height: 21px;
        }
      }
      button {
        margin-top: 57px;
        height: 49px;
        margin-right: 10px;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  #EmailCertification {
    .container {
      padding-right: 15px;
      padding-left: 15px;
      margin: 0;
      margin-top: 48px;
      * {
        font-size: 14px;
      }
      .emailAddress {
        margin-bottom: 46px;
        font-size: 16px;
      }
      .emailAddressLabel {
        font-size: 16px;
      }
      .row {
        flex-wrap: nowrap;
        .col-sm-1 {
          flex: 0 0 auto;
          width: auto;
          .material-icons {
            position: relative;
            left: -4px;
          }
        }
        .col-sm-11 {
          flex: 1 1 auto;
          width: auto;
          p {
            height: auto;
            margin-left: 7px;
            margin-bottom: 20px;
            font-size: 16px;
          }
        }
        &:last-child {
          flex-wrap: wrap;
          a {
            width: 100%;
            button {
              width: 100%;
              height: 64px;
              margin: 0;
              margin-top: 30px;
              border-radius: 4px;
              font-weight: bold;
              font-size: 18px;
            }
          }
          button {
            width: 100%;
            height: 64px;
            margin: 0;
            margin-top: 16px;
            border-radius: 4px;
            font-weight: bold;
            font-size: 18px;
          }
        }
      }
    }
  }
}
